import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { InputsService } from "src/app/_services/inputs.service";

@Component({
  selector: "app-input-solicitud-vendor",
  templateUrl: "./input-solicitud-vendor.component.html",
  styleUrls: ["./input-solicitud-vendor.component.scss"],
})
export class InputSolicitudVendorComponent implements OnInit {
  @Input() id_vendor = 0;
  @Input() inputs = {
    camposReferenciasPf: [],
    camposReferenciasPm: [],
    camposDatosgeneralesPf: [],
    camposDatosgeneralesPm: [],
    camposAvalPf: [],
    camposAvalPm: [],
  };
  loading = false

  constructor(
    public inputService: InputsService,
    private dialog: MatDialog,
    private location: Location

  ) {}

  ngOnInit(): void {
    this.inputService.ObtenerInputs();

  }
  ActualizarInput(input, section) {
    this.inputs[section] = [...input]
  }
  async GuardarInputs() {
    this.loading = true
    const data = {
      id_vendor: this.id_vendor,
      campos: this.inputs
    }

    await this.inputService.ActualizarInputs(data)
    this.dialog.open(MessageBoxComponent, {
      width: '500px',
      data: {
        type: 'success',
        icon: 'success',
        text: "Documentos actualizados",
				text2: "Se han actualizado los documentos.",
      }
    })
    this.loading = false
    // this.location.back();

  }
}
