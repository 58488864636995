
      <app-sidenav-v2
        [shortMenu]="true"
        [filtersAvailable]="true"
        [filterType]="'cartera'"
        (onFiltrar)="ObtenerCartera($event)"
        (buscar)="BuscarAnexo($event)"
        placeholderSearch="Buscar anexo"
        [filtros]="filtroCartera"
        [limpiarFiltro]="limpiarFiltro"
      >
        <div class="main">
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px;">
                <div></div>
                <button *ngIf="!loading && (filtroAnexos.length || anexos.length) && !descargandoReporte" class="btn-primary-new" (click)="exportToExcel()">Descargar reporte</button>
                <mat-progress-spinner *ngIf="descargandoReporte" diameter="20" mode="indeterminate"></mat-progress-spinner>
                <div>
                    <button class="quitar-filtro" (click)="QuitarFiltro()">Quitar filtro</button>
                </div>
            </div>
            <mat-progress-spinner
                    *ngIf="loading"
                    mode="indeterminate"
                    diameter="30"
                ></mat-progress-spinner>
            <div *ngIf="!loading && filtroResumen.length" class="table-cartera">
                <table class="table-cartera-resumen" [cellSpacing]="0" [cellPadding]="0">
                    <thead>
                        <tr>
                            <th style="min-width: 180px;">Resumen</th>
                            <th *ngFor="let periodo of filtroResumen">{{periodo.periodo | date:'MMM yy'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="width: 200px;">Saldo inicial</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.monto | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Amortización / Capital</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.capital | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Interés</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.interes | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Residual</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.residual | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Saldo Final / Insoluto</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.insoluto | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Seguro</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.seguroSinIva | currency:'MXN'}}</td>
                        </tr>
                        <tr>
                            <td>Renta mensual</td>
                            <td *ngFor="let periodo of filtroResumen" (click)="MostrarDetalleResumen(periodo)">{{periodo.pago | currency:'MXN'}}</td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <div *ngIf="!loading && anexos.length && anexosCompletos.length">Anexos <span>{{anexos.length}} de</span> {{anexosCompletos.length}}</div>
            <mat-error *ngIf="!loading && !anexos.length && anexosCompletos.length">No se encontraron coincidencias</mat-error>
            <mat-error *ngIf="!loading && !anexos.length && !anexosCompletos.length">No se encontraron anexos</mat-error>
            <div *ngIf="!loading && anexos.length" class="table-cartera">
                <cdk-virtual-scroll-viewport itemSize="50" class="virtual-scroll-viewport" appCdkVirtualScrollViewportResizeAware>
                    <table class="table-cartera" [cellSpacing]="0" [cellPadding]="0">
                        <thead>
                            <tr>
                                <th style="max-width: 15px;">N.</th>
                                <th style="min-width: 200px!important;">Folio</th>
                                <th style="min-width: 100px!important;">Periodo</th>
                                <th style="min-width: 200px!important;">Fecha Contrato Marco</th>
                                <th style="min-width: 200px!important;">Fecha Activación</th>
                                <th style="min-width: 400px!important;">Cliente</th>
                                <th style="min-width: 200px!important;">Monto a Financiar</th>
                                <th style="min-width: 10px!important;">Tasa interés</th>
                                <th style="min-width: 10px!important;">Tasa implícita</th>
                                <th style="min-width: 100px!important;">Valor Residual</th>
                                <th style="min-width: 200px!important;">Renta sin IVA</th>
                                <th style="min-width: 200px!important;">Seguro sin IVA</th>
                                <th style="min-width: 200px!important;">Renta total sin IVA</th>
                                <th style="min-width: 10px!important;">Plazo</th>
                                <th style="min-width: 200px!important;">Fecha 1er Renta</th>
                                <th style="min-width: 200px!important;">Fecha Residual</th>
                                <th style="min-width: 200px!important;">Días de atraso</th>
                                <th style="min-width: 200px!important;">Reestructura</th>
                                <th style="min-width: 200px!important;">Fecha Reestructura</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *cdkVirtualFor="let anexo of anexos; let i = index" (click)="MostrarDetalle(anexo)"
                                [ngClass]="{anexoSeleccionado: AnexoSeleccionado(anexo.folio)}"
                            >
                                <td>{{ i +1 }}</td>
                                <td style="text-align: left;">{{anexo.folio}}</td>
                                <td style="text-align: left;">{{anexo.periodo | date:'MMMM yy'}}</td>
                                <td style="text-align: center;">{{anexo.fechaContratoMarco | date:'dd/MM/yyyy'}}</td>
                                <td style="text-align: center;">{{anexo.fechaActivacion | date:'dd/MM/yyyy'}}</td>
                                <td style="text-align: left;">{{anexo.cliente}}</td>
                                <td style="text-align: right;">{{anexo.montoFinanciar | currency:'MXN'}}</td>
                                <td style="text-align: center;">{{(anexo.tasa || 0.0) * 100 | number:'1.2-2'}}%</td>
                                <td style="text-align: center;">{{(anexo.tasaActiva || 0.0) * 100 | number:'1.2-2'}}%</td>
                                <td style="text-align: right;">{{anexo.valorResidual | currency:'MXN'}}</td>
                                <td style="text-align: right;">{{anexo.rentaSinIva | currency:'MXN'}}</td>
                                <td style="text-align: right;">{{anexo.seguroSinIva | currency:'MXN'}}</td>
                                <td style="text-align: right;">{{anexo.rentaTotal | currency:'MXN'}}</td>
                                <td style="text-align: center;">{{anexo.plazo}}</td>
                                <td style="text-align: center;">{{anexo.fechaPrimerRenta | date:'dd/MM/yyyy'}}</td>
                                <td style="text-align: center;">{{anexo.fechaResidual | date:'dd/MM/yyyy'}}</td>
                                <td style="text-align: center;">{{anexo.diasAtraso}}</td>
                                <td style="text-align: center;">{{anexo.reestructurado | titlecase}}</td>
                                <td style="text-align: center;">{{anexo.fechaReestructura | titlecase}}</td>
                            </tr>
                        </tbody>
                    </table>
                </cdk-virtual-scroll-viewport>
            </div>
            <div *ngIf="!endProcess" class="keep_receiving_data">
                <mat-progress-spinner
                    mode="indeterminate"
                    diameter="30"
                    color="white"></mat-progress-spinner>
                Cargando data
            </div>
        <app-socials-footer style="width: 100%;"></app-socials-footer>
        </div>
    </app-sidenav-v2>