<div class="title-details" [ngClass]="{'confirmada': confirmada}" (click)="show_details = !show_details">Plazo {{periodo.period}} {{periodo.type}} <mat-icon>plus</mat-icon></div>

<div *ngIf="show_details">
    <table>
        <tr><th [align]="'right'">TIR: </th><td>{{periodo.tir}} %</td></tr>
        <tr><th [align]="'right'">Tasa Activa: </th><td>{{periodo.active_rate}} %</td></tr>
        <tr *ngIf="periodo.type == 'meses + MSI'"><th [align]="'right'">Costo financiero: </th><td>{{periodo.costo_financiero_porcentaje || 0.0}} %</td></tr>
        <tr *ngIf="periodo.type == 'meses + MSI'"><th [align]="'right'">Enganche: </th><td>{{periodo.initial_payment}}</td></tr>
    </table>
    <table>
        <thead>
            <tr>
                <th>No. Pago</th>
                <th [align]="'right'">Fecha</th>
                <th [align]="'right'">Monto</th>
                <th [align]="'right'" *ngIf="country == 'PE'" >Saldo inicial</th>
                <th [align]="'right'" *ngIf="country == 'PE'" >Amortizacion</th>
                <th [align]="'right'" *ngIf="country == 'PE'" >Interés</th>
                <th [align]="'right'" *ngIf="country == 'PE'" >Saldo insoluto</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let payment_data of periodo.payments_data; let i = index">
                <td align="center">
                    {{ payment_data.label ? payment_data.label : i == 0 ? '0' : i == 1 ? 'Inicial' : i -1}}
                </td>
    
                <td style="width: 250px;" [align]="'right'" *ngIf="!payment_data.label && i >= 2 || payment_data.label && i >= 3">{{payment_data.when | date:'dd/MM/yyyy':'+6'}}</td>
                <td style="width: 250px;" [align]="'right'" *ngIf="!payment_data.label && i < 2 || payment_data.label && i < 3">{{payment_data.when | date:'dd/MM/yyyy'}}</td>
                <td style="width: 200px;" [align]="'right'">{{payment_data.amount | currency:'MXN'}}</td>
                <td style="width: 170px;" [align]="'right'"*ngIf="country == 'PE'">{{payment_data.saldo_inicial | currency:'MXN'}}</td>
                <td style="width: 170px;" [align]="'right'"*ngIf="country == 'PE'">{{payment_data.amortizacion_cliente | currency:'MXN'}}</td>
                <td style="width: 170px;" [align]="'right'"*ngIf="country == 'PE'">{{payment_data.interes_mensual_cliente | currency:'MXN'}}</td>
                <td style="width: 170px;" [align]="'right'"*ngIf="country == 'PE'">{{payment_data.saldo_insoluto | currency:'MXN'}}</td>
            </tr>
        </tbody>
    </table>
</div>