import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDetaleCarteraAnexoComponent } from 'src/app/_dialogs/dialog-detale-cartera-anexo/dialog-detale-cartera-anexo.component';
import * as ExcelJS from 'exceljs';
import { CarteraService } from 'src/app/_services/cartera.service';
import { MESES } from '../valuacion/analisis/Analisis';
import { WsService } from 'src/app/_services/ws.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-cartera',
  templateUrl: './cartera.component.html',
  styleUrls: ['./cartera.component.scss']
})
export class CarteraComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  limpiarFiltro = false

  anexosCompletos: any = []
  anexos: any = []
  resumen: any = []

  filtroResumen: any = []
  filtroAnexos: any = {}

  filtroCartera: any = {}

  loading = false
  endProcess = true
  descargandoReporte = false
  constructor(
    private carteraService: CarteraService,
    private dialog: MatDialog,
    private wsServices: WsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // this.wsServices.messages$.subscribe(message => {
    //   console.log('Mensaje recibido del WebSocket:', message);
    //   this.ProcesarMensaje(JSON.parse(message))
    // });
    this._defaultFiltro()
  }

  ngAfterViewInit() {
    if (this.viewport) {
      this.viewport.checkViewportSize();
      this.cdr.detectChanges();
      this.viewport.scrollToIndex(0); 
    }
  }

  async ObtenerCartera(filtro) {
    // this.loading = true
    // console.log({filtro});
    // this.wsServices.sendMessage({
    //   "event": "reporte_cartera",
    //   "body": {
    //       "fechaInicio": "2024-01-01T00:00:00.000Z",
    //       "fechaFin": "2024-01-31T23:59:59.000Z",
    //       "pageSize": 15
    //   }
    // })
    // return
    this.loading = true
    
    const res = await this.carteraService.ObtenerCartera(filtro).catch(() => {
      this.loading = false
    })
    if (res.statusCode == 200) {
      this.anexosCompletos = [...res.data.anexos]
      this.resumen = [...res.data.summary]
      this.filtroResumen = [...res.data.summary]
      // this._cargarDetalle([...res.data.anexos])

      this.BuscarAnexo('')
    }
    this.loading = false
  }

  MostrarDetalle(anexo) {
    console.log({anexo});
    if (anexo == null) return
    
    if (anexo && anexo.folio) {
      if (this.filtroAnexos[anexo.folio] == undefined) {
        this.filtroAnexos[anexo.folio] = {...anexo}
      } else {
        delete this.filtroAnexos[anexo.folio]
      }
    }

    console.log(Object.keys(this.filtroAnexos).length);
    

    this.filtroResumen = Object.keys(this.filtroAnexos).length ? this.ObtenerResumenCustomizado(this.filtroAnexos) : this.ObtenerResumenCustomizado(this.anexos) 
    
    // this.dialog.open(DialogDetaleCarteraAnexoComponent, {
    //   width: "620px",
    //   minHeight: "450px",
    //   data: anexo
    // })
  }

  ObtenerResumenCustomizado(filtroAnexos) {
    const summary: any = {}
    const customSummary: any = []

    const keys = Object.keys(filtroAnexos)
    for (const key of keys) {
      for (const pago of filtroAnexos[key].desglose) {
        const [year, month, day] = pago.fecha.split('-')

        if (!summary[year]) {
          summary[year] = {}
        } 

        if (!summary[year][month]) {
          summary[year][month] = {
            monto: 0,
            pago: 0,
            capital: 0,
            interes: 0,
            insoluto: 0,
            residual: 0,
            mensualidad: 0,
            seguroSinIva: 0,
            anexo: []
          }
        }

        summary[year][month].monto += pago.monto
        summary[year][month].pago += pago.pago
        summary[year][month].capital += pago.capital
        summary[year][month].interes += pago.interes
        summary[year][month].insoluto += pago.insoluto
        summary[year][month].residual += pago.residual
        summary[year][month].seguroSinIva += pago.seguroSinIva

        summary[year][month].anexo.push({
          folio: key,
          ...pago,
        })
        
      }
    }

    const years = Object.keys(summary)
    for (const year of years) {
      const months = Object.keys(summary[year])
      for (const month of months) {
        customSummary.push({
            ...summary[year][month],
            periodo: `${year}-${month}-01T06:00:00.000Z`
          })
      }
    }
    
    customSummary.sort((a: any, b: any) => a.periodo < b.periodo ? -1 : 0)

    return customSummary.length ? [...customSummary] : []
  }

  MostrarDetalleResumen(resumen) {
    this.dialog.open(DialogDetaleCarteraAnexoComponent, {
      width: "620px",
      minHeight: "450px",
      data: {
        ...resumen,
        resumen: true
      }
    })
  }

  _cargarDetalle(anexos) {
    let _resumen = {}
    for (const anexo of anexos) {
      if (!anexo.desglose || !anexo.desglose.length) {
        console.error(anexo);
        continue 
      }
      const fechaResidual = new Date(anexo.fechaResidual)
      fechaResidual.setDate(1)

      if (_resumen[fechaResidual.toUTCString()]) {
        _resumen[fechaResidual.toUTCString()].residual += anexo.valorResidual || 0
      } else {
        _resumen[fechaResidual.toUTCString()] = {
          capital: 0,
          interes: 0,
          insoluto: 0,
          pago: 0,
          monto: 0,
          residual: anexo.valorResidual,
        }
      }

      
      for (const renta of anexo.desglose) {
        const periodo = new Date(renta.fecha)
        periodo.setDate(1)
       
        if (_resumen[periodo.toUTCString()]) {
           _resumen[periodo.toUTCString()].capital += renta.capital || 0
           _resumen[periodo.toUTCString()].interes += renta.interes || 0
           _resumen[periodo.toUTCString()].insoluto += renta.insoluto || 0
           _resumen[periodo.toUTCString()].pago += renta.pago || 0
           _resumen[periodo.toUTCString()].monto += renta.capital || 0
        } else {
          _resumen[periodo.toUTCString()] = {
            capital: renta.capital || 0,
            interes: renta.interes || 0,
            insoluto: renta.insoluto || 0,
            pago: renta.pago || 0,
            monto: renta.capital || 0,
            residual: 0
          }
        }
      }
    }


    for (const key in _resumen) {
      if (Object.prototype.hasOwnProperty.call(_resumen, key)) {
        this.resumen.push({fecha: key, ..._resumen[key]});
      }
    }
  }

  AnexoSeleccionado(folio) {
    return this.filtroAnexos[folio] != undefined
  }

  exportToExcel() {
    this.descargandoReporte = true
    const filename = "reporte"
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Anexos');
    const worksheetCronograma = workbook.addWorksheet('Cronograma');
    const worksheetSummary = workbook.addWorksheet('Resumen');
    const Anexos = Object.keys(this.filtroAnexos).length ? this.ObtenerAnexosSeleccionados() : [...this.anexos]
    
    worksheet.addRow([
      "N",
      "Folio",
      "Periodo",
      "Fecha contrato macro",
      "Cliente",
      "Monto a financiar",
      "Tasa interés",
      "Tasa implicita",
      "Valor residual",
      "Renta sin iva",
      "Seguro sin iva",
      "Renta total sin iva",
      "Plazo",
      "Fecha primer renta",
      "Fecha residual",
      "Días de atraso",
      "Reestructura",
    ]);
    let count = 0
    Anexos.forEach(anexo => {
      worksheet.addRow([
        ++count,
        anexo.folio,
        anexo.periodo,
        anexo.fechaContratoMarco,
        anexo.cliente,
        anexo.montoFinanciar,
        anexo.tasa,
        anexo.tasaActiva,
        anexo.valorResidual,
        anexo.rentaSinIva,
        Number(anexo.seguroSinIva),
        anexo.rentaTotal,
        anexo.plazo,
        anexo.fechaPrimerRenta,
        anexo.fechaResidual,
        anexo.diasAtraso,
        anexo.reestructurado,
      ]);
    });

    const {fechaInicial, fechaFinal} = this._obtenerFechasCronograma(Anexos)
    const cabeceraCronograma = this._crearFechasCronogramas({fechaInicial, fechaFinal})

    worksheetCronograma.addRow(['', ...cabeceraCronograma])

    for (const anexo of Anexos) {
      const cronograma = this.CrearCronograma(anexo, {fechaInicial, fechaFinal})
      worksheetCronograma.addRow([`Anexo:`, anexo.folio])
      worksheetCronograma.addRow([`Reestructurado:`, anexo.reestructurado])
      worksheetCronograma.addRows(cronograma)
      worksheetCronograma.addRow([])
    }

    const periodos = ['Resumen']
    const saldoInicial = ['Saldo inicial']
    const capital = ['Amortización / Capital']
    const interes = ['Interés']
    const residual = ['Residual']
    const insoluto = ['Saldo final / Insoluto']
    const seguro = ['Seguro']
    const rentaMensual = ['Renta mensual']

    for (let i = 0; i < this.filtroResumen.length; i++) {
      periodos.push(this.filtroResumen[i].periodo)
      saldoInicial.push(this.filtroResumen[i].monto)
      capital.push(this.filtroResumen[i].capital)
      interes.push(this.filtroResumen[i].interes)
      residual.push(this.filtroResumen[i].residual)
      insoluto.push(this.filtroResumen[i].insoluto)
      seguro.push(this.filtroResumen[i].seguroSinIva)
      rentaMensual.push(this.filtroResumen[i].pago)
    }

    worksheetSummary.addRow(periodos)
    worksheetSummary.addRow(saldoInicial)
    worksheetSummary.addRow(capital)
    worksheetSummary.addRow(interes)
    worksheetSummary.addRow(residual)
    worksheetSummary.addRow(insoluto)
    worksheetSummary.addRow(seguro)
    worksheetSummary.addRow(rentaMensual)
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename + `_${(new Date()).getTime()}` + '.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    });

    this.descargandoReporte = false
  }

  CrearCronograma(anexo, {fechaInicial, fechaFinal}: any) {
    const _fechaInicial = new Date(fechaInicial.toISOString())
    const _fechaFinal = new Date(fechaFinal.toISOString())

    _fechaInicial.setDate(1)
    _fechaFinal.setDate(1)
    const _cronograma: any = {
      periodo: [''],
      inicial: ['Saldo inicial'],
      capital: ['Amortización / Capital'],
      interes: ['Interés'],
      residual: ['Residual'],
      insoluto: ['Saldo final / insoluto'],
      seguro: ['Seguro'],
      mensualidad: ['Mensualidad'],
      mensualidadSeguro: ['Mensualidad con seguro'],
      pago: ['Pago'],
      pagado: ['Pagado'],
      fechaPago: ['Fecha pago']
    }

    const primerRenta = new Date(anexo.desglose[0].fecha)
    const ultimaRenta = new Date(anexo.desglose[ anexo.desglose.length -1].fecha)

    primerRenta.setDate(1)
    ultimaRenta.setDate(1)
    do {
      
      if (_fechaInicial < primerRenta) {
        _cronograma.periodo.push('')
        _cronograma.inicial.push(0)
        _cronograma.capital.push(0)
        _cronograma.interes.push(0)
        _cronograma.residual.push(0)
        _cronograma.insoluto.push(0)
        _cronograma.seguro.push(0)
        _cronograma.mensualidad.push(0)
        _cronograma.mensualidadSeguro.push(0)
        _cronograma.pago.push(0)
        _cronograma.pagado.push('')
        _fechaInicial.setMonth(_fechaInicial.getMonth() +1)
      }
    } while (_fechaInicial < primerRenta)
    
    for (const cronograma of anexo.desglose) {
      const pagado = (cronograma.pago - cronograma.montoPago) < 1 ? 'Sí' : 'No'
      const [year, month, _] = cronograma.fecha.split('-')
      
      const mes = MESES[parseInt(month) -1]
      _cronograma.periodo.push(`${mes} ${year}`)
      _cronograma.inicial.push(cronograma.monto)
      _cronograma.capital.push(cronograma.capital)
      _cronograma.interes.push(cronograma.interes)
      _cronograma.residual.push(cronograma.residual)
      _cronograma.insoluto.push(cronograma.insoluto)
      _cronograma.seguro.push(cronograma.seguroSinIva)
      _cronograma.mensualidad.push(cronograma.pago)
      _cronograma.mensualidadSeguro.push(cronograma.pago + cronograma.seguroSinIva)
      _cronograma.pago.push(cronograma.montoPago)
      _cronograma.pagado.push(pagado)
      _cronograma.fechaPago.push(cronograma.fechaPago)
    }

    do {
      if (ultimaRenta < _fechaFinal) {
        _cronograma.periodo.push('')
        _cronograma.inicial.push(0)
        _cronograma.capital.push(0)
        _cronograma.interes.push(0)
        _cronograma.residual.push(0)
        _cronograma.insoluto.push(0)
        _cronograma.seguro.push(0)
        _cronograma.mensualidad.push(0)
        _cronograma.mensualidadSeguro.push(0)
        _cronograma.pago.push(0)
        _cronograma.pagado.push('')
      }
      ultimaRenta.setMonth(ultimaRenta.getMonth() +1)
    } while (ultimaRenta < _fechaFinal)

    return [
      _cronograma.periodo,
      _cronograma.inicial,
      _cronograma.capital,
      _cronograma.interes,
      _cronograma.residual,
      _cronograma.insoluto,
      _cronograma.seguro,
      _cronograma.mensualidad,
      _cronograma.mensualidadSeguro,
      _cronograma.pago,
      _cronograma.pagado,
      _cronograma.fechaPago,
    ]
  }

  BuscarAnexo(word) {
    const words = word.split(' ')
    this.limpiarFiltro = false
    this.anexos = []
    // this.filtroAnexos = []
    for (const anexo of this.anexosCompletos) {
      words.some(_word => {
        const filter = _word.toLowerCase()
        if (anexo.folio.toLowerCase().includes(filter) || anexo.cliente.toLowerCase().includes(filter)) {
          this.anexos.push({...anexo})
        }
      })
    }
    this.MostrarDetalle(null)
  }

  ObtenerAnexosSeleccionados() {
    const _anexos = []
    Object.keys(this.filtroAnexos).forEach( anexo => {
      _anexos.push({...this.filtroAnexos[anexo]})
    })
    return _anexos
  }

  QuitarFiltro() {
    const folios = Object.keys(this.filtroAnexos)
    for (const folio of folios) {
      delete this.filtroAnexos[folio]
    }
    this.BuscarAnexo('')
    this.limpiarFiltro = true
    // this._defaultFiltro()
  }

  _defaultFiltro() {
    const currentDate = new Date()
    const initialDate = new Date()

    initialDate.setDate(1)

    if (currentDate.getDate() == 1) {
      initialDate.setMonth(initialDate.getMonth() -1)
    }

    this.filtroCartera.fechaInicio = `${initialDate.toISOString().split('T')[0]}T00:00:00.000Z`
    this.filtroCartera.fechaFin = `${currentDate.toISOString().split('T')[0]}T23:59:59.000Z`
    this.filtroCartera.restructurado = ''
    this.ObtenerCartera(this.filtroCartera)
  }

  ProcesarMensaje(msg) {
    console.log('ProcesarMensaje: ', msg, !this.anexosCompletos.length);

    if (msg.status == 'SUCCESS' && !this.anexosCompletos.length) {
      this._defaultFiltro()
    } else if (msg.status == 'DATA_SENT') {
      this.loading = false
      this.endProcess = false

      console.log(msg);
        
      this.anexosCompletos = [...this.anexosCompletos, ...msg.data.anexos]
        // this.resumen = [...this.resumen, ...msg.data.summary]
        // this.filtroResumen = [...this.filtroResumen, ...msg.data.summary]

      this.BuscarAnexo('')
    } else if (msg.status == 'END_PROCESS') {
      this.loading = false
      this.endProcess = true
    }
    // else 
    // if (this.anexosCompletos.length == 0 && msg.statusCode == 200) {
    //   const data = {
    //     event: "reporte_cartera",
    //     body: this.filtroCartera
    //   }
    //   console.log({data});
      
    //   // this.wsServices.sendMessage(data);
    // }
  }

  _obtenerFechasCronograma(Anexos) {
    let fechaInicial = null
    let fechaFinal = null

    for (const anexo of Anexos) {
      for (const desglose of anexo.desglose) {
        if (!fechaInicial) fechaInicial = new Date(desglose.fecha)
        else {
          const curFecha = new Date(desglose.fecha)
          fechaInicial = curFecha > fechaInicial ? fechaInicial : curFecha
        }
        if (!fechaFinal) fechaFinal = new Date(desglose.fecha)
        else {
          const curFecha = new Date(desglose.fecha)
          fechaFinal = curFecha < fechaFinal ? fechaFinal : curFecha
        }
      }
    }
    return { fechaInicial, fechaFinal }
  }

  _crearFechasCronogramas({fechaInicial, fechaFinal}: any) {
    const _fechaInicial = new Date(fechaInicial.toISOString())
    _fechaInicial.setDate(1)
    const fechas = []
    do {
      const periodo = `${MESES[_fechaInicial.getMonth()]}  ${_fechaInicial.getFullYear()}`
      fechas.push(periodo)
      _fechaInicial.setMonth(_fechaInicial.getMonth() +1)
    } while (_fechaInicial <= fechaFinal)
    return fechas
  }
}
