<mat-tab-group>
  <mat-tab label="Datos Generales PF">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposDatosgeneralesPf')"
      [inputSeleccionados]="inputs.camposDatosgeneralesPf" 
      [inputSection]="'camposDatosgeneralesPf'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <mat-tab label="Datos Generales PM">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposDatosgeneralesPm')"
      [inputSeleccionados]="inputs.camposDatosgeneralesPm" 
      [inputSection]="'camposDatosgeneralesPm'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <mat-tab label="Aval PF">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposAvalPf')"
      [inputSeleccionados]="inputs.camposAvalPf" 
      [inputSection]="'camposAvalPf'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <mat-tab label="Aval PM">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposAvalPm')"
      [inputSeleccionados]="inputs.camposAvalPm" 
      [inputSection]="'camposAvalPm'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <mat-tab label="Referencias PF">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposReferenciasPf')"
      [inputSeleccionados]="inputs.camposReferenciasPf" 
      [inputSection]="'camposReferenciasPf'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <mat-tab label="Referencias PM">
    <app-selector-inputs-vendor
      (guardar)="GuardarInputs()"
      (actualizar)="ActualizarInput($event, 'camposReferenciasPm')"
      [inputSeleccionados]="inputs.camposReferenciasPm" 
      [inputSection]="'camposReferenciasPm'" 
      [guardando]="loading">
    ></app-selector-inputs-vendor>
  </mat-tab>
  <!-- <mat-tab label="Solicitante Persona Moral"> <app-selector-documentos-vendor (guardar)="GuardarDocumentos()" (actualizar)="ActualizarDocumentos($event, 'documentosSolicitantePM')" [documentosSeleccionados]="documentos.documentosSolicitantePM" [guardando]="loading"></app-selector-documentos-vendor> </mat-tab>
    <mat-tab label="Aval Persona Física"> <app-selector-documentos-vendor (guardar)="GuardarDocumentos()" (actualizar)="ActualizarDocumentos($event, 'documentosAvalPF')" [documentosSeleccionados]="documentos.documentosAvalPF" [guardando]="loading"></app-selector-documentos-vendor> </mat-tab>
    <mat-tab label="Aval Persona Moral"> <app-selector-documentos-vendor (guardar)="GuardarDocumentos()" (actualizar)="ActualizarDocumentos($event, 'documentosAvalPM')" [documentosSeleccionados]="documentos.documentosAvalPM" [guardando]="loading"></app-selector-documentos-vendor> </mat-tab> -->
</mat-tab-group>
