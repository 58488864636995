<form [formGroup]="frmAval">
  <div style="display: flex; flex-direction: column">
    <app-input-validation
      type="text"
      [formGroup]="frmAval"
      [controlName]="'nombreCompleto'"
      [errorMessage]=""
      label="Nombre(s)"
      mask=""
    ></app-input-validation>
  </div>

  <div class="container-form">
    <div class="column">
      <app-input-validation
        type="text"
        [errorMessage]=""
        [formGroup]="frmAval"
        [controlName]="'apellidoPaterno'"
        label="Apellido Paterno"
        mask=""
      ></app-input-validation>
      <app-input-validation
        type="email"
        [formGroup]="frmAval"
        [controlName]="'email'"
        errorMessage="Este campo es requerido"
        label="tucorreo@correo.com"
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'rfc'"
        [errorMessage]="getErrorMessage()"
        label="RFC"
        maxlength="13"
      ></app-input-validation>
    </div>
    <div class="column" style="margin-left: 15px">
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'apellidoMaterno'"
        [errorMessage]=""
        label="Apellido Materno"
        mask=""
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'tel'"
        [errorMessage]=""
        label="Teléfono"
        mask="00-0000-0000"
      ></app-input-validation>
    </div>
    <div class="column" style="margin-left: 15px">
      <app-input-validation
        type="date"
        [formGroup]="frmAval"
        [controlName]="'fecha_nacimiento'"
        errorMessage="Este campo es requerido"
        label="Fecha de Nacimiento"
        mask=""
      ></app-input-validation>

      <app-radio-validation
        [options]="radioOptions"
        [formGroup]="frmAval"
        controlName="genero"
        label="Género"
      >
      </app-radio-validation>
    </div>
  </div>
  <div class="container-domicilio-first" style="margin-top: 20px">
    <div>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'calle'"
        errorMessage="Este campo es requerido"
        label="Calle"
      ></app-input-validation>
    </div>
    <div class="container-number-street">
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'num_ext'"
        errorMessage="Este campo es requerido"
        label="Número exterior"
        maxlength="15"
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'cp'"
        errorMessage="Este campo es requerido"
        label="Código Postal"
        mask="00000"
      ></app-input-validation>
    </div>
    <div>
      <app-input-validation
        type="text"
        [formGroup]="frmAval"
        [controlName]="'ciudad'"
        errorMessage="Este campo es requerido"
        label="Ciudad"
      ></app-input-validation>
    </div>
  </div>
  <div class="container-domicilio-second">
    <app-input-validation
      type="text"
      [formGroup]="frmAval"
      [controlName]="'delegacion_municipio'"
      errorMessage="Este campo es requerido"
      label="Delegación"
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmAval"
      [controlName]="'estado'"
      errorMessage="Este campo es requerido"
      label="Estado"
    ></app-input-validation>
    <div class="custom-input">
      <label>Colonia</label>
      <select formControlName="colonia">
        <option value="" disabled selected>Selecciona una colonia</option>
        <option *ngFor="let colonia of COLONIAS" [value]="colonia">
          {{ colonia }}
        </option>
      </select>
      <div
        class="error-message"
        *ngIf="frmAval.get('colonia').invalid && frmAval.get('colonia').dirty"
      >
        Este campo es requerido
      </div>
    </div>
  </div>
  <div>
    <span style="font-size: 0.8rem; color: #73787c; font-style: italic"
      >Por favor sube los documentos de tu aval.</span
    >
  </div>
  <div class="container-docu">
    <input
      type="file"
      id="documentaval"
      (click)="resset($event)"
      accept="application/pdf"
      (change)="SubirDocumento($event)"
      hidden
    />

    <app-documents-aval-new
      *ngFor="let documento of Documentos.DocumentosAval; let i = index"
      [documento]="documento"
      [index]="i"
      [solicitante]="false"
      style="margin: 10px 0"
      (Upload)="SeleccionarDocumento(documento, false)"
      (VerDocumento)="VerDocumento(documento, false)"
    >
    </app-documents-aval-new>
  </div>
</form>
