<div style="margin-top: 15px; width: 100%; overflow-x: auto; overflow-y: hidden;">
    <label for="select-periodo-gracia">Periodo de Gracia</label>
    <select name="select-periodo-gracia" id="select-periodo-gracia" [(ngModel)]="periodo_gracia" (change)="CargarTabla()">
        <option [value]="0">Sin periodo de gracia</option>
        <option [value]="1">1 mes de gracia</option>
        <option [value]="2">2 meses de gracia</option>
    </select>
    <table [cellSpacing]="0" [cellPadding]="0" >
        <thead>
            <tr>
                <th colspan="12" style="background-color: #AAD1F2; border-radius: 15px 15px 0 0; padding: 5px;">% Pago Inicial</th>
            </tr>
            <tr style="background-color: #AAD1F2; padding: 5px;">
                <th>Plazo</th>
                <th *ngFor="let porcentaje of porcentajes[0].porcentajes">
                    {{ porcentaje }}%
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of porcentajes">
              <th>{{ item.label }}</th>
              <td *ngFor="let porcentaje of item.porcentajes; let i = index" 
                      class="opt_pagos_iniciales" 
                      (click)="tabla_pago_inicial[item.plazo][i] != '0' && Agregar(i, item.label, tabla_pago_inicial[item.plazo][i])"
                      [ngClass]="{
                        'plazo_seleccionado': pagos_iniciales_seleccionados['plazo' + item.label + '_' + i]?.value > 0
                      }">
                    {{ tabla_pago_inicial[item.plazo][i] == '0' ? '-' : tabla_pago_inicial[item.plazo][i] }}%
              </td>

            </tr>
        </tbody>
    </table>

    
    <div>
        Costo Financiero: {{this.porcentaje_pago_inicial || 0}}%
    </div>

    <div>
        <input type="checkbox" name="aum_valor_factura" id="aum_valor_factura" [(ngModel)]="aumentar_valor_factura" (change)="EmitSeleccion('plus')"> ¿Aumentar al valor Factura? <br>
        <input type="checkbox" name="dis_valor_factura" id="dis_valor_factura" [(ngModel)]="disminuir_valor_factura" (change)="EmitSeleccion('minus')"> ¿Disminuir al valor Factura?
    </div>
    <!-- <div>
        <table [cellSpacing]="0" [cellPadding]="0" >
            <thead>
                <tr>
                    <th colspan="12" style="background-color: #AAD1F2; border-radius: 15px 15px 0 0; padding: 5px;">% Pago Inicial</th>
                </tr>
                <tr style="background-color: #AAD1F2; padding: 5px;">
                    <th>Plazo</th>
                    <th *ngFor="let porcentaje of _porcentajes[0].porcentajes">
                        {{ porcentaje }}%
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of _porcentajes">
                  <th>{{ item.label }}</th>
                  <td *ngFor="let porcentaje of item.porcentajes; let i = index" 
                      class="opt_pagos_iniciales" 
                      (click)="tabla_pago_inicial2[item.plazo][i] != '0' && Agregar(i, item.label, tabla_pago_inicial2[item.plazo][i])"
                      [ngClass]="{
                        'plazo_seleccionado': pagos_iniciales_seleccionados['plazo' + item.label + '_' + i] && pagos_iniciales_seleccionados['plazo' + item.label + '_' + i].value > 0
                      }">
                     {{ tabla_pago_inicial2[item.plazo][i] == '0' ? '-' : tabla_pago_inicial2[item.plazo][i] }}%
                  </td>
                </tr>
              </tbody>
        </table>
    </div> -->
</div>