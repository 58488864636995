import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, ProductsService, SolicitudService } from 'src/app/_services';

import * as Papa from 'papaparse';
import { MatDialog } from '@angular/material/dialog';
import { GenerateCsvComponent } from 'src/app/_dialogs/generate-csv/generate-csv.component';
import { AsignarSeguroComponent } from 'src/app/_components/asignar-seguro/asignar-seguro.component';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { SelectInvoiceDateInitComponent } from 'src/app/_components/select-invoice-date-init/select-invoice-date-init.component';
import * as ExcelJS from 'exceljs';
import { ArcusService } from 'src/app/_services/arcus.service';

@Component({
  selector: 'app-calculo-tir',
  templateUrl: './calculo-tir.component.html',
  styleUrls: ['./calculo-tir.component.scss']
})
export class CalculoTIRComponent implements OnInit {

  folio = ''

  loading = false
  data = null
  search = false
  versionSelected = 0
  tramite = null
  amount = 0

  vendor = ''
  comercial = ''

  confirmada = false
  PUEDE_CREAR_ANEXO = false
  CREANDO_ANEXO = false
  plazo_confirmado = 0
  enganche_confirmado = 0

  tramite_confirmado = null
  tramite_confirmado_index = null

  cot_msi = false

  isAuto = false
  asignar_seguro = false

  PUEDE_ASIGNAR_SEGURO = [3,10,15,17,12,14]
  comission_details:any = {}

  valorImpuesto = 0

  constructor(
    private productosService: ProductsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
	  private route: ActivatedRoute,
    private dialog: MatDialog,
    private arcusServices: ArcusService
  ) { }

  ngOnInit(): void {
		const { perfil } = JSON.parse( localStorage.getItem('currentUser') )
    this.asignar_seguro = this.PUEDE_ASIGNAR_SEGURO.includes(Number(perfil))

    this.folio = this.route.snapshot.params.folio || ''
    console.log(`Folio: ${this.folio}`);
    this.BuscarTramite()
  }

  async BuscarTramite() {
    this.search = false
    if (this.folio.length < 6) return
    this.loading = true
    console.log(`Buscar tramite: ${this.folio}`);
    const response: any = await this.solicitudService.ObtenerTramite(this.folio)
    console.log({response});
    this.data = null
    if (response.data.length) {
      this.data = response.data
    }
    this.vendor = response.vendor
    this.comercial = response.comercial
    this.versionSelected = 0
    this.tramite = null
    this.amount = 0
    this.search = true
    this.loading = false
  }

  MostrarInfo() {
    console.log(`Versión seleccionada: ${this.versionSelected}`);
    const _tramiteEncontrado = this.data.filter(tramite => tramite.version == this.versionSelected)
    const _tramite = _tramiteEncontrado.length > 0 ? _tramiteEncontrado[0] : null
    // console.log(_tramiteEncontrado[0].raw_cot);
    this.cot_msi = _tramite.plazo_confirmado.includes('MSI')
    console.log("Tramite: ", _tramite.plazo_confirmado.includes('MSI'));
  
    const cot = _tramite ? JSON.parse(_tramite.raw_cot) : {}
    this.confirmada = _tramite ? _tramite.confirmada : false
    this.PUEDE_CREAR_ANEXO = cot.country == 'MX'


    if (this.cot_msi) {
      const [plazo, enganche] = _tramite.plazo_confirmado.split('-')
      console.log({plazo, enganche});
      this.plazo_confirmado = parseInt(plazo)
      this.enganche_confirmado = parseInt(enganche)
    } else {
      this.plazo_confirmado = _tramite ? parseInt(_tramite.plazo_confirmado) : 0
    }
    this.tramite = Object.keys(cot).length > 0 ? cot : null
    this.valorImpuesto = this.tramite.country == 'PE' ? 1.18 : 1.16

    this.comission_details = this.tramite.periods[0].comission_details
      ? this.tramite.periods[0].comission_details
      : null

    this.tramite_confirmado = this.confirmada && this.tramite ? {...this.tramite} : {
      periods: []
    }

    
    for (let index = 0; index < this.tramite_confirmado.periods.length; index++) {
      console.log(this.tramite_confirmado.periods[index], this.tramite_confirmado.periods[index].period, this.plazo_confirmado);

      this.tramite_confirmado_index = (!this.cot_msi && (this.tramite_confirmado.periods[index].period == this.plazo_confirmado ||
                                      this.tramite_confirmado.periods[index].period +1 == this.plazo_confirmado)) ||
                                      (this.cot_msi && this.tramite_confirmado.periods[index].period == this.plazo_confirmado &&
                                        this.tramite_confirmado.periods[index].initial_payment.includes(this.enganche_confirmado))
        ? index
        : null
      if (this.tramite_confirmado_index != null) break
    }

    console.log(this.tramite, this.tramite_confirmado_index);

    this.isAuto = this.tramite.type == "Auto"
    this.amount = Number(this.tramite.discount_amount.replace(/[\$|,]/ig,''))
  }

  DescargarArchivoCsv(){
    console.log('Descargando csv');

    const subtotal = Number(this.tramite.subtotal.replaceAll(/\$|,/ig, ''))
    const total = Number(this.tramite.total.replaceAll(/\$|,/ig, ''))
    const iva = (total - subtotal).toFixed(2)

    console.log(this.tramite_confirmado_index);
    
    const comision_total = this.tramite.periods[this.tramite_confirmado_index].comission
    const comision_subtotal = (comision_total / this.valorImpuesto).toFixed(2)
    const comision_iva = (comision_total - Number(comision_subtotal)).toFixed(2)

    const comision = ((comision_total * 100) / total).toFixed(1)

    const initial_payment_total = Number(this.tramite.periods[this.tramite_confirmado_index].initial_payment_amount.replaceAll(/\$|,/ig, '')) - comision_total
    const initial_payment_subtotal = (initial_payment_total / this.valorImpuesto).toFixed(2)
    const initial_paytment_iva = (initial_payment_total - Number(initial_payment_subtotal)).toFixed(2)
    
    const monthly_payment_total = this.tramite.periods[this.tramite_confirmado_index].only_monthly_payment
    const monthly_payment_subtotal = (monthly_payment_total / this.valorImpuesto).toFixed(2)
    const monthly_payment_iva = (monthly_payment_total - Number(monthly_payment_subtotal)).toFixed(2)

    const _residual_total = Number(this.tramite.periods[this.tramite_confirmado_index].residual_amount.replaceAll(/\$|,/ig, ''))
    const residual_total = _residual_total > 0 ? _residual_total : 1
    const residual_subtotal = (residual_total / this.valorImpuesto).toFixed(2)
    const residual_iva = (residual_total - Number(residual_subtotal)).toFixed(2)

    const monthly_insurance_amount_total = this.tramite.periods[this.tramite_confirmado_index].monthly_insurance_amount
    const monthly_insurance_amount_subtotal = (monthly_insurance_amount_total / this.valorImpuesto).toFixed(2)
    const monthly_insurance_amount_iva = (monthly_insurance_amount_total - Number(monthly_insurance_amount_subtotal)).toFixed(2)

    const seguro_total = this.tramite.periods[this.tramite_confirmado_index].seguro
    const seguro_subtotal = (seguro_total / this.valorImpuesto).toFixed(2)
    const seguro_iva = (seguro_total - Number(seguro_subtotal)).toFixed(2)
    
    const period = this.tramite.periods[this.tramite_confirmado_index].period

    console.log({subtotal, total});
    
    const csvJson = [
      {
        "descripcion": this.tramite.product,
        "montoSIva": subtotal,
        "iva": iva,
        "periodicidad": "unico",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "start",
        "tir": "",
        "comisionApertura": comision,
        "depositoGarantia": 0,
        "comercial": this.comercial.trim(),
        "vendor": this.vendor.trim(),
      },
      {
        "descripcion": "Equipo Médico DEPOSITO EN GARANTIA",
        "montoSIva": "0",
        "iva": "0.0",
        "periodicidad": "unico",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "DEPOSITO EN GARANTIA",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico RENTA EXTRAORDINARIA",
        "montoSIva": initial_payment_subtotal,
        "iva": initial_paytment_iva,
        "periodicidad": "unico",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "RENTA EXTRAORDINARIA",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico RENTA EQUIPO MEDICO",
        "montoSIva": monthly_payment_subtotal,
        "iva": monthly_payment_iva,
        "periodicidad": "mensual",
        "plazo": period,
        "tipoActivo": "Equipo médico",
        "tipoLinea": "RENTA EQUIPO MEDICO",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico GASTOS POR SEGURO",
        "montoSIva": seguro_subtotal,
        "iva": seguro_iva,
        "periodicidad": "mensual",
        "plazo": period,
        "tipoActivo": "Equipo médico",
        "tipoLinea": "GASTOS POR SEGURO",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico RESIDUAL",
        "montoSIva": residual_subtotal,
        "iva": residual_iva,
        "periodicidad": "unico",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "RESIDUAL",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico COMISION POR APERTURA",
        "montoSIva": comision_subtotal,
        "iva": comision_iva,
        "periodicidad": "unico",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "COMISION POR APERTURA",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
      {
        "descripcion": "Equipo Médico PÓLIZA DE MANTENIMIENTO PREVENTIVO",
        "montoSIva": monthly_insurance_amount_subtotal,
        "iva": monthly_insurance_amount_iva,
        "periodicidad": "mensual",
        "plazo": "",
        "tipoActivo": "Equipo médico",
        "tipoLinea": "PÓLIZA DE MANTENIMIENTO PREVENTIVO",
        "tir": "",
        "comisionApertura": "",
        "depositoGarantia": "",
        "comercial": "",
        "vendor": "",
      },
    ]

    this.dialog.open(GenerateCsvComponent, {
      data: csvJson,
      width: '100%',
      height: '550px'
      
    }).afterClosed().subscribe( res => {
      console.log(res);
      if (res != null ){
        this.exportToCsv(res, `folio_${this.folio}.csv`)
      }
    })
    
  }

  DescargarInvoices() {
    this.dialog.open(SelectInvoiceDateInitComponent, {
      width: '350px',
      height: '180px'
    }).afterClosed().subscribe( data => {
      console.log(data);
      if (!data) return
      const fecha = new Date(data)
      const day = fecha.getUTCDate()
      let month = fecha.getUTCMonth()
      let year = fecha.getUTCFullYear()

      let invoice_num = 0

      console.log({fecha, month, year});
      const period = this.tramite.periods[this.tramite_confirmado_index].period
      const comision_total = this.tramite.periods[this.tramite_confirmado_index].comission
      const initial_payment_total = Number(this.tramite.periods[this.tramite_confirmado_index].initial_payment_amount.replaceAll(/\$|,/ig, ''))
      const initial_payment_subtotal = (initial_payment_total / this.valorImpuesto).toFixed(2)
      
      const monthly_payment_total = this.tramite.periods[this.tramite_confirmado_index].only_monthly_payment
      const monthly_payment_subtotal = (monthly_payment_total / this.valorImpuesto).toFixed(2)

      const seguro_total = this.tramite.periods[this.tramite_confirmado_index].seguro
      const seguro_subtotal = (seguro_total / this.valorImpuesto).toFixed(2)

      const _residual_total = Number(this.tramite.periods[this.tramite_confirmado_index].residual_amount.replaceAll(/\$|,/ig, ''))
      const residual_total = _residual_total > 0 ? _residual_total : 1
      const residual_subtotal = (residual_total / this.valorImpuesto).toFixed(2)

      const esAuto = this.tramite.type == 'Auto'

      const tenencia_placas = this.tramite.periods[this.tramite_confirmado_index].tenencia + this.tramite.periods[this.tramite_confirmado_index].placas
      const tenencia_placas_subtotal = (tenencia_placas / this.valorImpuesto).toFixed(2)
      
      const gps_inicial = this.tramite.periods[this.tramite_confirmado_index].gps_inicial
      const gps_inicial_subtotal = (gps_inicial / this.valorImpuesto).toFixed(2)
      
      const gps_mensualidad = this.tramite.periods[this.tramite_confirmado_index].gps_mensualidad
      const gps_mensual_subtotal = (gps_mensualidad / this.valorImpuesto).toFixed(2)
      
      const seguro_auto_inicial = this.tramite.periods[this.tramite_confirmado_index].seguro_auto_inicial
      const seguro_auto_inicial_subtotal = (seguro_auto_inicial / this.valorImpuesto).toFixed(2)
      
      const seguro_auto_mensualidad = this.tramite.periods[this.tramite_confirmado_index].seguro_auto_mensualidad
      const seguro_auto_mensualidad_subtotal = (seguro_auto_mensualidad / this.valorImpuesto).toFixed(2)

      const capital_renta = Number(this.tramite.periods[this.tramite_confirmado_index].amortizacion_mensual.replaceAll(/\$|,/ig, ''))
      const capital_renta_subtotal =(capital_renta / this.valorImpuesto).toFixed(2)

      const interes_mensual = Number(this.tramite.periods[this.tramite_confirmado_index].interes_mensual.replaceAll(/\$|,/ig, ''))
      const interes_mensual_subtotal =(interes_mensual / this.valorImpuesto).toFixed(2)

      const igv = ((Number(capital_renta_subtotal) + Number(interes_mensual_subtotal) + Number(seguro_subtotal)) * this.valorImpuesto).toFixed(2)

      const cvsJson = []
      
      if(!esAuto) {
        cvsJson.push({
          "Invoice": invoice_num +1,
          "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
          "Fecha límite": (new Date(year, month, day, -6,0,0)),
          "Monto sin iva": Number(initial_payment_subtotal),
          "Tipo transacción": 'RENTA EXTRAORDINARIA',
          "Concepto": 'RENTA EXTRAORDINARIA',
        })
      }

      if (esAuto) {
        const inicial = Number(initial_payment_subtotal) - Number(tenencia_placas_subtotal) -  Number(gps_inicial_subtotal) - Number(seguro_auto_inicial_subtotal)
        console.log("Inicial: ", inicial, Number(initial_payment_subtotal), Number(tenencia_placas_subtotal),  Number(gps_inicial_subtotal), Number(seguro_auto_inicial_subtotal));
        cvsJson.push({
          "Invoice": invoice_num +1,
          "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
          "Fecha límite": (new Date(year, month, day, -6,0,0)),
          "Monto sin iva": inicial,
          "Tipo transacción": 'RENTA EXTRAORDINARIA',
          "Concepto": 'RENTA EXTRAORDINARIA',
        })

        cvsJson.push({
          "Invoice": invoice_num +1,
          "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
          "Fecha límite": (new Date(year, month, day, -6,0,0)),
          "Monto sin iva": Number(tenencia_placas_subtotal),
          "Tipo transacción": 'TRAMITES VEHICULARES',
          "Concepto": 'TRAMITES VEHICULARES',
        })
        
        cvsJson.push({
          "Invoice": invoice_num +1,
          "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
          "Fecha límite": (new Date(year, month, day, -6,0,0)),
          "Monto sin iva": Number(gps_inicial_subtotal),
          "Tipo transacción": 'GPS',
          "Concepto": 'GPS INICIAL',
        })

        if (seguro_auto_inicial > 0) {
          cvsJson.push({
            "Invoice": invoice_num +1,
            "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
            "Fecha límite": (new Date(year, month, day, -6,0,0)),
            "Monto sin iva": Number(seguro_auto_inicial_subtotal),
            "Tipo transacción": 'seguro no aplica a cartera',
            "Concepto": 'SEGURO CONTADO',
          })
        }
      }

      for (let index = 0; index < period; index++) {
        ++invoice_num
        if (month > 12) {
          month = 1
          ++year
        }

        if (!esAuto) {
          cvsJson.push({
            "Invoice": invoice_num +1,
            "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
            "Fecha límite": (new Date(year, month, day, -6,0,0)),
            "Monto sin iva": Number(seguro_subtotal),
            "Tipo transacción": `Seguro`,
            "Concepto": `SEGURO ${invoice_num}`,
          })
        }


        // if (this.tramite.country == 'PE') {
        //   cvsJson.push({
        //     "Invoice": invoice_num +1,
        //     "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
        //     "Fecha límite": (new Date(year, month, day, -6,0,0)),
        //     "Monto sin iva": Number(capital_renta_subtotal),
        //     "Tipo transacción": `Capital renta`,
        //     "Concepto": `Renta ${invoice_num}`,
        //   })

        //   cvsJson.push({
        //     "Invoice": invoice_num +1,
        //     "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
        //     "Fecha límite": (new Date(year, month, day, -6,0,0)),
        //     "Monto sin iva": Number(interes_mensual_subtotal),
        //     "Tipo transacción": `Interés renta`,
        //     "Concepto": `Renta ${invoice_num}`,
        //   })

        //   cvsJson.push({
        //     "Invoice": invoice_num +1,
        //     "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
        //     "Fecha límite": (new Date(year, month, day, -6,0,0)),
        //     "Monto sin iva": Number(igv),
        //     "Tipo transacción": `IGV`,
        //     "Concepto": `Renta ${invoice_num}`,
        //   })
        // } else {
          cvsJson.push({
            "Invoice": invoice_num +1,
            "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
            "Fecha límite": (new Date(year, month, day, -6,0,0)),
            "Monto sin iva": Number(monthly_payment_subtotal),
            "Tipo transacción": `Renta`,
            "Concepto": `Renta ${invoice_num}`,
          })
        // }

        if (this.tramite.monthly_insurance_amount_included) {
          const poliza_mantenimiento = (Number(this.tramite.monthly_insurance_amount.replaceAll(/\$|,/ig, '')) / this.valorImpuesto).toFixed(2)
          cvsJson.push({
            "Invoice": invoice_num +1,
            "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
            "Fecha límite": (new Date(year, month, day, -6,0,0)),
            "Monto sin iva": Number(poliza_mantenimiento),
            "Tipo transacción": `Mantenimiento`,
            "Concepto": `Mantenimiento ${invoice_num}`,
          })
        }

        if (esAuto) {
          cvsJson.push({
            "Invoice": invoice_num +1,
            "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
            "Fecha límite": (new Date(year, month, day, -6,0,0)),
            "Monto sin iva": Number(gps_mensual_subtotal),
            "Tipo transacción": 'GPS',
            "Concepto": `GPS MENSUAL ${invoice_num}`,
          })

          if (seguro_auto_mensualidad > 0) {
            cvsJson.push({
              "Invoice": invoice_num +1,
              "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
              "Fecha límite": (new Date(year, month, day, -6,0,0)),
              "Monto sin iva": Number(seguro_auto_mensualidad_subtotal),
              "Tipo transacción": 'Seguro',
              "Concepto": `SEGURO ${invoice_num}`,
            })
          }
        }
        ++month
      }
      --month

      cvsJson.push({
        "Invoice": ++invoice_num +1,
        "Fecha emisión": (new Date(year, month, 1, -6,0,0)),
        "Fecha límite": (new Date(year, month, day, -6,0,0)),
        "Monto sin iva": Number(residual_subtotal),
        "Tipo transacción": `Residual`,
        "Concepto": `Residual`,
      })

      this.exportToExcel(cvsJson, `invoices_${this.folio}`)

    })
  }

  exportToCsv(json: any[], filename: string) {
    const csv = Papa.unparse(json);
    const blob = new Blob(["\ufeff"+csv], { type: 'text/csv;charset=utf-8;' });
  
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  }

  AsignarSeguro() {
    this.dialog.open(AsignarSeguroComponent,{
      width: '450px',
      height: '250px'
    }).afterClosed().subscribe(res => {
      if (res) {
        console.log(res);
        const {seguro_auto, seguro_financiado} = res
        this.Cotizar(Number(seguro_auto), Number(seguro_financiado))
      }
    })
  }

  
  Cotizar(seguro_auto, seguro_financiado) {
    const {
      phone,
      email,
      total,
      product_description,
      product_model,
      product_year,
      product_image,
      customer,
      user_id,
      vendor_id,
    } = this.tramite
    const {
      blind_discount,
      vendor_payment,
      delivery_time,
      initial_payment,
      auto_nuevo,
      estado,
      tenencia,
      placas,
      gps_inicial,
      gps_mensualidad,
      month,
      monthly_rate,
      comission_details
    } = this.tramite.periods[0]
    const [client_name = '',client_first_lastname = '',client_second_lastname = ''] = customer.split(' ')

    const plazos = []
    const residuales:any = {}

    for (const periodo of this.tramite.periods) {
      console.log(periodo.period, periodo.residual);
      if(parseInt(initial_payment) == 0){
        plazos.push(`ap${periodo.period + 1}`)
        residuales[`ap${periodo.period + 1}`] = parseInt(periodo.residual)
        console.log("RESIDUALES: ")
      }else{
        plazos.push(`ap${periodo.period}`)
        residuales[`ap${periodo.period}`] = parseInt(periodo.residual)
    }
    }
    
    this.loading = true

    const data = {
      folio: this.folio,
      preview: false,
      user_id,
      vendor_id,
      client_name,
      client_first_lastname,
      client_second_lastname,
      client_email: email,
      client_phone: phone,
      factor: (monthly_rate * 12) / 2,
      product_id: 0,
      amount: parseFloat(total.replaceAll(/\$|,/ig, '')),
      blind_discount: parseFloat(blind_discount),
      vendor_payment: parseFloat(vendor_payment),
      delivery_time: delivery_time,
      initial_payment: parseFloat(initial_payment) /100,
      msi_initial_payment: 0,
      periods: plazos.join(','), // crear funcion
      precioSeguroMensualFinanciado: 0,
      sin_producto: true,
      auto: true,
      discount_amount: 0,
      residuales, // crear funcion
      send: true,
      description: product_description,
      model: product_model,
      year: product_year,
      warranty: '',
      msi_seleccionados: [],
      custom_image: product_image,
      only_monthly_payment: false,
      nuevo: auto_nuevo,
      month,
      estado,
      seguro_financiado,
      tenencia,
      placas,
      seguro_auto,
      gps_inicial,
      gps_mensualidad,
      incluye_seguro: true,
      edit_commission: true,
      commission_for_opening_type: this.comission_details.opening_commission_type,
      commission_for_opening: this.comission_details.opening_comission_value
    }
    console.log( this.comission_details);
    console.log(data);

  this.productosService.CotizarProducto(data).subscribe( res => {
    console.log(res);
    console.log('url: ', res.url_cotizacion);
    window.open(res.url_cotizacion, "downlaod");
    this.ShowDialog('success', `Se ha generado la cotización con éxito con folio ${res.folio}`, '')
    this.BuscarTramite()
    this.loading = false
  }, err => {
    console.log(err);
    this.loading = false
    this.ShowDialog('error', `Ocurrió un error`, err.name)
  })

  }

  exportToExcel(fields: any, filename: string) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    worksheet.addRow([
      "Invoice",
      "Fecha emisión",
      "Fecha límite",
      "Monto sin iva",
      "Tipo transacción",
      "Concepto",
    ]);
    fields.forEach(row => {
      worksheet.addRow([
        row["Invoice"],
        row["Fecha emisión"],
        row["Fecha límite"],
        row["Monto sin iva"],
        row["Tipo transacción"],
        row["Concepto"],
      ]);
    });
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename + '.xlsx';
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }
 
  ShowDialog(type, text, text2 = '', home_redirect = false) {
    const dialog = this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    })

    if (home_redirect) {
      dialog.afterClosed().subscribe( ()=>{
        this.router.navigate(['dashboard','principal'])
      })
    }
  }

  async CrearAnexo() {
    this.CREANDO_ANEXO = true
    const crear_anexo: any = await this.arcusServices.CrearAnexo(this.folio)
    console.log({crear_anexo});
    
    if (crear_anexo.statusCode == 400) {

    }
    this.CREANDO_ANEXO = false
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["/login"]);
  }
}
