import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_services';
import { Location } from '@angular/common';
import { VendorsService } from 'src/app/_services/vendors.service';
import { Perfil } from './../../../_models/perfiles'

@Component({
  selector: 'app-vendors-list',
  templateUrl: './vendors-list.component.html',
  styleUrls: ['./vendors-list.component.scss']
})
export class VendorsListComponent implements OnInit {
  perfil
  id_usuario
  tipo_dashboard
  VendorsFiltro = []
  Vendors = []
  empresa = ''
  total_unread_comments = 0
  placeholderSearch="Buscar vendors"
  load= true;
  loading= true;
  is_loading = false
  View = {
    view_list: false,
    view_module: true
  }

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private vendorService: VendorsService
    ) { }

  async ngOnInit() {
    localStorage.removeItem('vendor')
    const { id, perfil, perfil_master, id_master } = JSON.parse(localStorage.getItem('currentUser'))

    this.perfil = Number(perfil)
    this.id_usuario = Number(id)
    this.tipo_dashboard = Perfil[this.perfil]
    await this.BuscarVendors()
  }

  Filtrar(filtro) {
    this.VendorsFiltro = this.Vendors.filter( Vendor => 
      Vendor.fullname.toLowerCase().includes(filtro.toLowerCase()) 
    )
    this.load = !(this.VendorsFiltro.length==0);
  }

  Editar(Vendor) {
    console.log('Editar', Vendor);
    localStorage.setItem('vendor', JSON.stringify(Vendor))
    this.router.navigate(['/vendors/editar']);
  }

  async BuscarVendors() {
    this.loading = false;
    console.log("LOADING", this.loading);
    console.log(`País: ${this.empresa}`);
    const resp_vendors = await this.vendorService.ObtenerVendors({perfil: this.perfil, id_usuario: this.id_usuario, country: this.empresa}).toPromise()

    console.log("*********")
    console.log("*********", resp_vendors)
    this.Vendors = resp_vendors.data.length ? resp_vendors.data.map( vendor => ({
      ...vendor,
      tasa_mensual_financiamiento: JSON.parse(vendor.tasa_mensual_financiamiento),
      cotizar_auto: vendor.cotizar_auto || false,
      factor: vendor.factor || 0,
      vendor_payment: vendor.vendor_payment || 0,
      annual_insurance_amount: vendor.annual_insurance_amount || 0,
      commission_for_opening: vendor.commission_for_opening || 0,
      commission_for_opening_type: vendor.commission_for_opening_type || 'a',
      id_vendor:vendor.id_vendor
    })) : []
    console.log("VENDORS",this.Vendors);
    console.log("Valor BOOLEANO:", this.Vendors.length>0);
 
    this.Filtrar('')
  }
   ChangeView(view) {
    this.View.view_list = false
    this.View.view_module = false

    this.View[view] = true
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  ShowDialog(type, text, text2 = '', home = false) {
    const dialog = this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    }).afterClosed().subscribe(res => {
      if (home) {
        this.location.back
      }
    })
  }

  ResetPassword(Vendor) {
    this.is_loading = true
    localStorage.setItem('vendor', JSON.stringify(Vendor));
    this.vendorService.ResetPassword(Vendor.email).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha cambiado la contraseña con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar la contraseña")
      }
    )
  }

  async ActivarVendor({user_id}){
    
    this.is_loading = true
    const valor = this.vendorService.ActivarVendor(user_id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha activado con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar")
      }
    );
    
    
  }
  async DesactivarVendor({user_id}){
    this.is_loading = true
    const valor = this.vendorService.DesactivarVendor(user_id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha Desactivado con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar")
      }
    );
    
    
  }
  EliminarVendor({user_id}){
    this.is_loading=true;
    console.log("Eliminar ID:",user_id)

    this.vendorService.EliminarVendor(user_id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha eliminado con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar el estado")
      }
    );
  }
}

