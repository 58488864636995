import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InputsService {
  campos = {
    camposDatosgeneralesPf: [],
    camposDatosgeneralesPm: [],
    camposReferenciasPf: [],
    camposReferenciasPm: [],
    camposAvalPf: [],
    camposAvalPm: []
  };

  constructor(private http: HttpClient, private router: Router) {
    // this.ObtenerInputs();
  }

  async ObtenerInputs() {
    const Vendor = JSON.parse(localStorage.getItem('vendor')!);
    const { country } = Vendor;

      const res = await this.http.post<any>(`${environment.config.apiUrl}config/inputs_disponibles`, { country }).toPromise();

      this.campos = {
        camposDatosgeneralesPf: [],
        camposDatosgeneralesPm: [],
        camposReferenciasPf: [],
        camposReferenciasPm: [],
        camposAvalPf: [],
        camposAvalPm: []
      };

      // Recorrer los datos y separarlos por sección
      res.data.forEach((item: any) => {
        switch (item.section) {
          case 'DG':
            this.campos.camposDatosgeneralesPf.push(item);
            break;
          case 'DGM':
            this.campos.camposDatosgeneralesPm.push(item);
            break;
          case 'RF':
            this.campos.camposReferenciasPf.push(item);
            break;
          case 'RFM':
            this.campos.camposReferenciasPm.push(item);
            break;
          case 'AVAL':
            if (item.type_person == "1" || item.type_person == "4") {
              this.campos.camposAvalPf.push(item);
            }
            if (item.type_person == "2" || item.type_person == "4") {
              this.campos.camposAvalPm.push(item);
            }
            break;
          default:
            console.warn(`Sección desconocida: ${item.section}`);
        }
      });

      console.log(this.campos);
  
  }

  async ActualizarInputs(data: any) {
    return this.http.post<any>(`${environment.config.apiUrl}config/actualizar_inputs`, data).toPromise();
  }

  async ObtenerInputsByTramite(data: any) {
    return this.http.post<any>(`${environment.config.apiUrl}config/inputs_disponibles_by_tramite`, data).toPromise();
  }
}
