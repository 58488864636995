import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BuroService, UserService } from 'src/app/_services';

@Component({
  selector: 'app-aval-nueva-solicitud',
  templateUrl: './aval-nueva-solicitud.component.html',
  styleUrls: ['./aval-nueva-solicitud.component.scss']
})
export class AvalNuevaSolicitudComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  data_aval: any = {};
  frmAval: FormGroup;
  frmAvalMoral: FormGroup;
  estados = [
    { id: "CDMX", valor: "Ciudad de México", estado: "Ciudad de México" },
    { id: "AGS", valor: "Aguascalientes", estado: "Aguascalientes" },
    { id: "BCN", valor: "Baja California", estado: "Baja California" },
    { id: "BCS", valor: "Baja California Sur", estado: "Baja California Sur" },
    { id: "CAM", valor: "Campeche", estado: "Campeche" },
    { id: "CHS", valor: "Chiapas", estado: "Chiapas" },
    { id: "CHI", valor: "Chihuahua", estado: "Chihuahua" },
    { id: "COA", valor: "Coahuila", estado: "Coahuila de Zaragoza" },
    { id: "COL", valor: "Colima", estado: "Colima" },
    { id: "DGO", valor: "Durango", estado: "Durango" },
    { id: "EM", valor: "Estado de México", estado: "México" },
    { id: "GTO", valor: "Guanajuato", estado: "Guanajuato" },
    { id: "GRO", valor: "Guerrero", estado: "Guerrero" },
    { id: "HGO", valor: "Hidalgo", estado: "Hidalgo" },
    { id: "JAL", valor: "Jalisco", estado: "Jalisco" },
    { id: "MICH", valor: "Michoacán", estado: "Michoacán de Ocampo" },
    { id: "MOR", valor: "Morelos", estado: "Morelos" },
    { id: "NAY", valor: "Nayarit", estado: "Nayarit" },
    { id: "NL", valor: "Nuevo León", estado: "Nuevo León" },
    { id: "OAX", valor: "Oaxaca", estado: "Oaxaca" },
    { id: "PUE", valor: "Puebla", estado: "Puebla" },
    { id: "QRO", valor: "Querétaro", estado: "Querétaro" },
    { id: "QR", valor: "Quintana Roo", estado: "Quintana Roo" },
    { id: "SLP", valor: "San Luis Potosí", estado: "San Luis Potosí" },
    { id: "SIN", valor: "Sinaloa", estado: "Sinaloa" },
    { id: "SON", valor: "Sonora", estado: "Sonora" },
    { id: "TAB", valor: "Tabasco", estado: "Tabasco" },
    { id: "TAM", valor: "Tamaulipas", estado: "Tamaulipas" },
    { id: "TLAX", valor: "Tlaxcala", estado: "Tlaxcala" },
    { id: "VER", valor: "Veracruz", estado: "Veracruz de Ignacio de la Llave" },
    { id: "YUC", valor: "Yucatán", estado: "Yucatán" },
    { id: "ZAC", valor: "Zacatecas", estado: "Zacatecas" },
  ];

  id_usuario = null;
  perfil = 0;
  id_cliente = null;
  folio = "";
  tipo_persona = 1;
  tipo_aval = 1;
  loading = false;
  $loading = null;

  Documentos = {
    DocumentosSolicitante: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalLegal: [],
  };
  documentos_necesarios = 0;
  faltanDocumentos = false;
  DocumentosValidar = [];
  validandoDocumentos = false;
  cancelDoc = false;
  completado = false;
  solicitud_prellenada = false;
  formData: FormData;
  allowed_type = "application/pdf";
  COLONIAS = [];
  radioOptions = [
    {
      label: "Mujer",
      value: "F",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
    {
      label: "Hombre",
      value: "M",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
  ];



  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private buroService: BuroService,

  ) { }

  ngOnInit(): void {
    this.id_cliente = localStorage.getItem('current_id_cliente')
    this.id_usuario = JSON.parse(localStorage.getItem('currentUser')).id
    this.folio = JSON.parse(localStorage.getItem('folio_solicitud'))
    this.data_aval =
      this.tipo_persona == 1
        ? {
            folio: this.folio.toString(),
            id_cliente: this.id_cliente,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
            empleado: false,
            nuevo_flujo: true,
          }
        : {
            folio: this.folio,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
          };
    this.frmAval = this.formBuilder.group({
      nombreCompleto: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      ocupacion: [''],
      email: ['', [Validators.required, this.customEmailValidator]],
      tel: ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
      calle: ['', Validators.required],
      num_ext: ['', Validators.required],
      cp: ['', Validators.required],
      ciudad: ['', Validators.required],
      delegacion_municipio: ['', Validators.required],
      estado: ['', Validators.required],
      colonia: ['', Validators.required],
      estado_nombre: [""],
      fecha_nacimiento: ['', Validators.required],
      genero: ['', Validators.required],
      rfc: ['', [Validators.required,Validators.maxLength(13),Validators.minLength(10)],],
    })
    this.ObtenerStatus()
    this.frmAval.valueChanges.subscribe(() => {
      if (this.frmAval.valid) {
        this.data = { ...this.data, aval: this.frmAval.value };
        this.datosActualizados.emit(this.data);
      }
    });
    if(this.data.aval){
      this.frmAval.patchValue({
        nombres: this.data.aval.nombreCompleto || '',
        apellido_paterno: this.data.aval.apellidoPaterno || '',
        apellido_materno: this.data.aval.apellidoMaterno || '',
        ocupacion: this.data.aval.ocupacion || '',
      });
    }
    this.frmAval.get("cp").valueChanges.subscribe((value) => {
      if (value && value.length === 5) {
        this.SearchState(value);
      }
    });
  }
  get fa() {
    return this.frmAval.controls;
  }


customEmailValidator(control: FormControl) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(control.value)) {
    return { invalidEmail: true, message: 'Por favor, ingresa un correo electrónico válido.' };
  }
  return null;
}
ObtenerStatus() {
  this.userService.ObtenerStatusDocuments(this.data_aval).subscribe(
    (res) => {
      this.Documentos = res;
      this.documentos_necesarios =
        this.Documentos.DocumentosSolicitante.length +
        this.Documentos.DocumentosAval.length;
      this.Documentos.DocumentosAval = this.Documentos?.DocumentosAval?.filter(documento => documento.tipo_documento !== 20)

      if (Number(this.perfil) === 4) {
        const result1 = this.Documentos.DocumentosSolicitante.filter(
          (doc: any) => {
            return doc.status === 2;
          }
        );
        const result2 = this.Documentos.DocumentosAval.filter((doc: any) => {
          return doc.status === 2;
        });
        this.cancelDoc = result1.length + result2.length > 0;
      }

      this.faltanDocumentos = false;
      this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante);
      this._hayDocumentosSinCargar(this.Documentos.DocumentosAval);
      this.DocumentosCompletos();
    },
    (err) => {
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  );
}
private _hayDocumentosSinCargar(documentos) {
  if (!this.faltanDocumentos) {
    for (const documento of documentos) {
      if (documento.tipo_documento != 21 && documento.status == 0) {
        this.faltanDocumentos = true;
        break;
      }
    }
  }
}
DocumentosCompletos() {
  if (!this.userService.alertService.isOnline()) return;
  this.completado = true;
  let status = 0;

  this.Documentos.DocumentosSolicitante.forEach((documento) => {
    
    if (documento.requerido == true && documento.status != 3) {
      this.completado = false;
    }
    this.solicitud_prellenada =
      documento.tipo_documento == 21 && documento.url.length > 0;
  });
  this.Documentos.DocumentosAval.forEach((documento) => {
    if (documento.requerido == true && documento.status != 3) {
      this.completado = false;
    }
  });

  if (this.Documentos.DocumentosSolicitanteLegal)
    this.Documentos.DocumentosSolicitanteLegal.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
  if (this.Documentos.DocumentosAvalLegal)
    this.Documentos.DocumentosAvalLegal.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
    });

  if (!this.solicitud_prellenada) {
    this.documentos_necesarios -= 1;
  }
}

SubirDocumento({ target: { files } }) {
  
  // if (!this.userService.alertService.isOnline()) return;
  const file = files[0];

  if (file) {
    this.formData.append("document", file);

    this.userService.SubirDocumento(this.formData).subscribe(
      (res) => {
        const $document = document.querySelector("#documentaval");
        this._snackBar.open(
          "Se ha cargado correctamente el documento",
          "Ok",
          {
            duration: 5000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["success-snackbar"],
          }
        );
        // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
        this.ObtenerStatus();
      },
      (err) => {
        this._snackBar.open(
          "Ha ocurrido un error, por favor vuelve a intentarlo. ",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
      }
    );
  }
}

SeleccionarDocumento(doc, solicitante) {
  // if (!this.userService.alertService.isOnline()) return;
  // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]
  if (doc.status == 3 || doc.status == 1) return;

  this.formData = new FormData();
  this.formData.append("folio", this.folio);
  this.formData.append("id_cliente", this.id_cliente.toString());
  this.formData.append("tipo_documento", doc.tipo_documento);
  this.formData.append("solicitante", solicitante);
  this.formData.append("id_usuario_mod", this.id_cliente.toString() ?? this.id_usuario);


  const $document: any = document.querySelector("#documentaval");
  $document.click();
}

resset = (event) => {
  event.target.value = null;
};

VerDocumento(documento, solicitante) {
  localStorage.setItem("documento", JSON.stringify(documento));

  if (documento.url) {
    const url = `#/dashboard/cliente/${this.folio}/ver_documento`;
    window.open(url, "_blank");
  }
}

async SearchState(cp) {
  if (cp && cp.length == 5) {
    const estados: any = await this?.buroService.buscarEstado(cp);
    const colonia = estados.map((d) => d.response.asentamiento);
    this.COLONIAS = colonia;
    for (const cur of estados) {
      if (!cur.error) {
        const { municipio, estado, ciudad, asentamiento } = cur.response;
        this.fa.delegacion_municipio.setValue(municipio);
        this.fa.ciudad.setValue(ciudad);
        this.fa.estado.setValue(this.getEstado(estado));
        this.fa.estado_nombre.setValue(estado);

        break;
      }
    }
  }
}
getEstado(estado) {
  const result: any = this.estados.filter((e) => e.estado === estado);
  return result.length ? result[0].id : "CDMX";
}

getErrorMessage() {
  const rfcControl = this.frmAval.get("rfc");
  if (rfcControl && rfcControl.hasError("minlength")) {
    return "Inserta un RFC correcto";
  }
  return "";
}
}
