import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VendorsService } from 'src/app/_services/vendors.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';


@Component({
  selector: 'app-vendedores-list',
  templateUrl: './vendedores-list.component.html',
  styleUrls: ['./vendedores-list.component.scss']
})
export class VendedoresListComponent implements OnInit {
  @Input() id_vendor;
  loading= true;
  is_loading = false

  Vendedores: any = []
  VendedoresFiltro: any = []

  constructor(
    private dialog: MatDialog,
    private vendorService: VendorsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.ObtenerVendedores()
  }

  async ObtenerVendedores() {
    const resp = await this.vendorService.ObtenerVendedores(this.id_vendor).toPromise()
    this.loading= false;
    console.log(resp);
    if(resp && resp.data && resp.data.length) {
      console.log('Hay datos');
      this.Vendedores = [...resp.data]
      this.FiltrarVendedores('')
    }
  }

  ShowDialog(type, text, text2 = '') {
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    });
  }

  FiltrarVendedores(word) {
    this.VendedoresFiltro = this.Vendedores.filter (vendedor => 
      vendedor.nombres.toLowerCase().includes(word.toLowerCase()) 
    )
  }

  Editar(vendedor) {
    localStorage.setItem('vendedor', JSON.stringify(vendedor))
    this.router.navigate(['/vendedor/editar']);
  }

  AgregarVendedor() {
    localStorage.removeItem('vendedor')
    this.router.navigate(['/vendedor/editar']);
  }
  ResetPassword(Vendedor) {
    this.is_loading = true
    console.log("mailVendedor ", Vendedor.email);
    this.vendorService.ResetPassword(Vendedor.email).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha cambiado la contraseña con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar la contraseña")
      }
    )
  }
  ActivarVendedor({id}){
    this.is_loading = true
    const valor = this.vendorService.ActivarVendedor(id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha activado con exito")
        this.ObtenerVendedores() 
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar")
      }
    );
  }
  DesactivarVendedor({id}){
    this.is_loading = true
    const valor = this.vendorService.DesactivarVendedor(id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha Desactivado con exito")
        this.ObtenerVendedores() 
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar")
      }
    );
  }
  EliminarVendedor({id}){
    this.is_loading=true;

    this.vendorService.EliminarVendedor(id).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha eliminado con exito")
        this.ObtenerVendedores() 
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar el estado")
      }
    );
  }

}
