import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import {
  BuroService,
  ProductsService,
  SolicitudService,
  UserService,
} from "src/app/_services";
import { MejorarCotizacionService } from "src/app/_services/mejorar-cotizacion.service";
import { NuevaSolicitudService } from "src/app/_services/nueva_solicitud.service";

@Component({
  selector: "app-datos-personales",
  templateUrl: "./datos-personales.component.html",
  styleUrls: ["./datos-personales.component.scss"],
})
export class DatosPersonalesComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  @Output() siguiente = new EventEmitter();
  frmDatosPersonales: FormGroup;

  show_password = false;
  folio = "";

  radioOptions = [
    {
      label: "Mujer",
      value: "F",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
    {
      label: "Hombre",
      value: "M",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
  ];
  radioOptionsConsultorio = [
    {
      label: "Propio",
      value: "PROPIO",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
    {
      label: "Rentado",
      value: "RENTADO",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
  ];
  radioOptionsVivienda = [
    {
      label: "Propia",
      value: "PROPIA",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
    {
      label: "Rentada",
      value: "RENTADA",
      activeImageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/check-blue-new.svg",
      imageUrl:
        "https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/circle-radio-desactive.svg",
    },
  ];
  COLONIAS = [];
  estados = [
    { id: "CDMX", valor: "Ciudad de México", estado: "Ciudad de México" },
    { id: "AGS", valor: "Aguascalientes", estado: "Aguascalientes" },
    { id: "BCN", valor: "Baja California", estado: "Baja California" },
    { id: "BCS", valor: "Baja California Sur", estado: "Baja California Sur" },
    { id: "CAM", valor: "Campeche", estado: "Campeche" },
    { id: "CHS", valor: "Chiapas", estado: "Chiapas" },
    { id: "CHI", valor: "Chihuahua", estado: "Chihuahua" },
    { id: "COA", valor: "Coahuila", estado: "Coahuila de Zaragoza" },
    { id: "COL", valor: "Colima", estado: "Colima" },
    { id: "DGO", valor: "Durango", estado: "Durango" },
    { id: "EM", valor: "Estado de México", estado: "México" },
    { id: "GTO", valor: "Guanajuato", estado: "Guanajuato" },
    { id: "GRO", valor: "Guerrero", estado: "Guerrero" },
    { id: "HGO", valor: "Hidalgo", estado: "Hidalgo" },
    { id: "JAL", valor: "Jalisco", estado: "Jalisco" },
    { id: "MICH", valor: "Michoacán", estado: "Michoacán de Ocampo" },
    { id: "MOR", valor: "Morelos", estado: "Morelos" },
    { id: "NAY", valor: "Nayarit", estado: "Nayarit" },
    { id: "NL", valor: "Nuevo León", estado: "Nuevo León" },
    { id: "OAX", valor: "Oaxaca", estado: "Oaxaca" },
    { id: "PUE", valor: "Puebla", estado: "Puebla" },
    { id: "QRO", valor: "Querétaro", estado: "Querétaro" },
    { id: "QR", valor: "Quintana Roo", estado: "Quintana Roo" },
    { id: "SLP", valor: "San Luis Potosí", estado: "San Luis Potosí" },
    { id: "SIN", valor: "Sinaloa", estado: "Sinaloa" },
    { id: "SON", valor: "Sonora", estado: "Sonora" },
    { id: "TAB", valor: "Tabasco", estado: "Tabasco" },
    { id: "TAM", valor: "Tamaulipas", estado: "Tamaulipas" },
    { id: "TLAX", valor: "Tlaxcala", estado: "Tlaxcala" },
    { id: "VER", valor: "Veracruz", estado: "Veracruz de Ignacio de la Llave" },
    { id: "YUC", valor: "Yucatán", estado: "Yucatán" },
    { id: "ZAC", valor: "Zacatecas", estado: "Zacatecas" },
  ];
  especialidades = [
    { id: 1, especialidad: "MÉDICO RADIOLOGO" },
    { id: 2, especialidad: "GINECOLOGÍA Y OBSTETRICIA" },
    { id: 3, especialidad: "OFTALMOLOGÍA" },
    { id: 4, especialidad: "RADIODIAGNÓSTICO" },
    { id: 5, especialidad: "IMAGENOLOGÍA DIAGNÓSTICA Y TERAPÉUTICA" },
    { id: 6, especialidad: "CARDIOLOGÍA CLÍNICA" },
    { id: 7, especialidad: "ORTOPEDIA Y TRAUMATOLOGIA" },
    { id: 8, especialidad: "ANATOMÍA PATOLÓGICA" },
    { id: 9, especialidad: "ANGIOLOGIA Y CIRUGIA VASCULAR" },
    { id: 10, especialidad: "CIRUGIA GASTROENTEROLOGICA" },
    { id: 11, especialidad: "ANESTESIOLOGÍA" },
    { id: 12, especialidad: "CIRUGÍA PLÁSTICA Y RECONSTRUCTIVA" },
    { id: 13, especialidad: "CIRUGIA GENERAL" },
    { id: 14, especialidad: "MEDICINA FAMILIAR" },
    { id: 15, especialidad: "NEUROCIRUGIA" },
    { id: 16, especialidad: "OTORRINOLARINGOLOGIA" },
    { id: 17, especialidad: "MEDICINA EN URGENCIAS" },
    { id: 18, especialidad: "DERMATOLOGIA" },
    { id: 19, especialidad: "UROLOGÍA " },
    { id: 20, especialidad: "RADIOLOGIA E IMAGEN" },
    { id: 21, especialidad: "MEDICINA DEL ENFERMO EN ESTADO CRÍTICO" },
    { id: 22, especialidad: "URGENCIAS MEDICO QUIRURGICAS" },
    { id: 23, especialidad: "COMUNICACIÓN, AUDIOLOGÍA Y FONIATRÍA" },
    { id: 24, especialidad: "CIRUGIA VASCULAR" },
    { id: 25, especialidad: "GINECO - OBSTETRICIA" },
    { id: 26, especialidad: "CIRUGIA PLASTICA Y RECONSTRUCTIVA" },
    { id: 27, especialidad: "IMAGENOLOGÍA Y RADIODIAGNÓSTICO" },
    { id: 28, especialidad: "MÉDICO PEDIATRA" },
    { id: 29, especialidad: "MEDICINA CRITICA" },
    { id: 30, especialidad: "UROLOGÍA" },
    { id: 31, especialidad: "ESTETICA Y LONGEVIDAD" },
    { id: 32, especialidad: "CIRUGÍA GENERAL" },
    { id: 33, especialidad: "MEDICINA INTEGRADA" },
    { id: 34, especialidad: "MEDICINA INTERNA" },
    { id: 35, especialidad: "NEFROLOGIA" },
    { id: 36, especialidad: "RADIOLOGÍA E IMAGEN" },
    { id: 37, especialidad: "MÉDICO GINECOLOGO" },
    { id: 38, especialidad: "NEUROLOGIA" },
    { id: 39, especialidad: "PERINATOLOGIA OBSTETRICIA" },
    { id: 40, especialidad: "OBESIDAD Y COMORBILIDADES" },
    { id: 41, especialidad: "GESTION DE HOSPITALES" },
    { id: 42, especialidad: "MÉDICO AUDIOLOGO" },
    { id: 43, especialidad: "MÉDICO CARDIOLOGO" },
    { id: 44, especialidad: "MÉDICO REUMATOLOGO" },
    { id: 45, especialidad: "MÉDICO GERIATRICO" },
    { id: 46, especialidad: "MÉDICO INTERNISTA" },
    { id: 47, especialidad: "NEUMOLOGÍA" },
    { id: 48, especialidad: "MÉDICINA FISICA Y DEL DEPORTE" },
    { id: 49, especialidad: "OTRO" },
  ];

  subespecialidades = [
    { id: 1, subespecialidad: "MEDICINA MATERNO FETAL" },
    { id: 2, subespecialidad: "MEDICINA CIRUGÍA PEDIÁTRICA" },
    { id: 3, subespecialidad: "BIOLOGÍA DE LA REPRODUCCIÓN HUMANA" },
    { id: 4, subespecialidad: "PERINATAL PEDIATRICA" },
    { id: 5, subespecialidad: "NEUROCIRUGIA PEDIATRICA" },
    { id: 6, subespecialidad: "UROLOGÍA GINECOLOGICA" },
    { id: 7, subespecialidad: "ENDOCRINOLOGIA" },
    { id: 8, subespecialidad: "DERMATOLOGIA PEDIATRICA" },
    { id: 9, subespecialidad: "PERINATOLOGIA" },
    { id: 10, subespecialidad: "MEDICINA CRITICA PEDIATRICA" },
    { id: 11, subespecialidad: "NEURO ANESTESIOLOGIA" },
    { id: 12, subespecialidad: "RADIOLOGIA ONCOLOGICA" },
    { id: 13, subespecialidad: "CARDIOLOGIA PEDIATRICA" },
    { id: 14, subespecialidad: "GINECOLOGÍA ONCOLOGICA" },
    { id: 15, subespecialidad: "CIRUGIA ONCOLOGICA" },
    { id: 16, subespecialidad: "UROLOGÍA ONCOLOGICA" },
    { id: 17, subespecialidad: "CARDIOLOGÍA VASCULAR" },
    { id: 18, subespecialidad: "PEDIATRIA/ NEUMOLOGÍA PEDIATRICA" },
    { id: 19, subespecialidad: "GASTROENTEROLOGIA Y NUTRICION PEDIATRICA" },
    { id: 20, subespecialidad: "PEDIATRIA/ CARDIOLOGÍA PEDIATRICA" },
    { id: 21, subespecialidad: "ENDOCOSPIA GINECOLOGICA" },
    { id: 22, subespecialidad: "ANESTESIOLOGÍA PEDIATRICA" },
    { id: 23, subespecialidad: "CIRUGÍA PEDIATRICA" },
    { id: 24, subespecialidad: "CARDIOLOGÍA PEDIATRICA" },
    { id: 25, subespecialidad: "ALERGIA E INMUNOLOGÍA PEDIATRICA" },
    { id: 26, subespecialidad: "MEDICINA CRITICA GINECOLOGICA" },
    { id: 27, subespecialidad: "OFTALMOLOGÍA EN RETINA" },
    { id: 28, subespecialidad: "REUMATOLOGÍA PEDIATRICA" },
    { id: 29, subespecialidad: "OTRO" },
  ];

  validForm = false;
  loading = false;
  haveSpeciality = false;
  errorMessageGuardar = false;
  errorMessageGuardarText = "";
  is_flujo_solicitud_cotizador = false;
  omitir_identificacion = false;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private buroService: BuroService,
    private mejorarSolicitudService: MejorarCotizacionService,
    private router: Router,
    private userService: UserService,
    private nuevaSolicitudService: NuevaSolicitudService,
    private productosService: ProductsService,
    private _snackBar: MatSnackBar, // private solicitudService: SolicitudService
    private solicitudService: SolicitudService
  ) {}
  get fg() {
    return this.frmDatosPersonales.controls;
  }

  ngOnInit(): void {
    const omitir = localStorage.getItem("sin_ine") || "";

    this.omitir_identificacion = omitir === "false" ? false : true;

    this.frmDatosPersonales = this.formBuilder.group({
      nombres: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      apellido_paterno: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      apellido_materno: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      fecha_nacimiento: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      rfc: ["",[Validators.required,Validators.maxLength(13),Validators.minLength(10),],],
      curp: ["",[Validators.required,Validators.maxLength(18),Validators.minLength(18),],],
      sexo: ["", Validators.required],
      correo_cliente: ["", [Validators.required, this.customEmailValidator]],
      password: ["", Validators.required],
      calle: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      cp: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      ciudad: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      estado: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      delegacion_municipio: ["",[Validators.required, this.noLeadingWhitespaceValidator]],
      colonia: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      estado_nombre: [""],
      num_ext: ["", [Validators.required, this.noLeadingWhitespaceValidator]],
      especialidad: ["", []],
      cedula_especialidad: ["", []],
      subespecialidad: ["", []],
      cedula_subespecialidad: ["", []],
      tipo_consultorio: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      total_gasto: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      antiguedad: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      tipo_vivienda: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
      pago_mensual: ["", [this.noLeadingWhitespaceValidator]],
      numero_dependientes: [""],
      equipamiento: [" ", [this.noLeadingWhitespaceValidator]],
      ingreso_mensual_promedio: ["",[Validators.required, this.noLeadingWhitespaceValidator],],
    });

    this.is_flujo_solicitud_cotizador = JSON.parse(this.data?._flujo_cotizador);

    if (this.data.first_name) {
      // console.log("ENTRO DATA", JSON.parse(this.data._flujo_cotizador));
      // this.is_flujo_solicitud_cotizador =
      //   JSON.parse(
      //     this.data?._flujo_cotizador !== undefined
      //       ? this.data._flujo_cotizador
      //       : "false"
      //   ) ||
      //   JSON.parse(
      //     this.data?._flujo_cotizador !== undefined
      //       ? this.data._flujo_cotizador
      //       : "false"
      //   );

      const fechaNacimientoPart = this.data.birthday.split("/");
      const fechaNacimiento = new Date(
        parseInt(fechaNacimientoPart[2]),
        parseInt(fechaNacimientoPart[1]) - 1,
        parseInt(fechaNacimientoPart[0])
      );
      const fechaNacimientoISO = fechaNacimiento.toISOString().split("T")[0];
      const rfc = this.data.curp.slice(0, 10);
      const nombres =
        (this.data.first_name ?? "") + " " + (this.data.second_name ?? "");
      this.frmDatosPersonales.patchValue({
        nombres: nombres,
        segundo_nombre: this.data.second_name,
        apellido_paterno: this.data.first_lastname,
        apellido_materno: this.data.second_lastname,
        fecha_nacimiento: fechaNacimientoISO,
        curp: this.data.curp,
        sexo: this.data.sexo,
        rfc,
        calle: this.data?.direccion?.street ?? "",
        num_ext: this.data?.direccion?.number ?? "",
        colonia: this.data?.direccion?.suburb ?? "",
        correo_cliente: this.data?.email ?? "",
      });
      if (
        this.data.direccion.zip ||
        this.data.direccion.zip != "" ||
        this.data.direccion.zip != "0000"
      ) {
        this.frmDatosPersonales.patchValue({
          cp: this.data.direccion.zip,
        });
        this.SearchState(this.data.direccion.zip);
      }
      this.data._flujo_cotizador = JSON.parse(this.data._flujo_cotizador);
      if (this.data.correo || this.data.correo != "") {
        if (this.data._flujo_cotizador) {
          const passwordControl = this.frmDatosPersonales.get("password");
          passwordControl.clearValidators();
          passwordControl.updateValueAndValidity();
          this.frmDatosPersonales.patchValue({
            correo_cliente: this.data.correo,
          });
        }
      }
      this.frmDatosPersonales
        .get("cp")
        .valueChanges.subscribe((value: string) => {
          if (value.length === 5) {
            this.SearchState(value);
          }
        });
    }
    this.data._flujo_cotizador = JSON.parse(this.data._flujo_cotizador);
    if (this.data.correo || this.data.correo != "") {
      if (this.data._flujo_cotizador) {
        const passwordControl = this.frmDatosPersonales.get("password");
        passwordControl.clearValidators();
        passwordControl.updateValueAndValidity();
        this.frmDatosPersonales.patchValue({
          correo_cliente: this.data.correo,
        });
      }
    }
    this.frmDatosPersonales
      .get("cp")
      .valueChanges.subscribe((value: string) => {
        if (value.length === 5) {
          this.SearchState(value);
        }
      });

    this.frmDatosPersonales.valueChanges.subscribe(() => {
      this.validForm = this.frmDatosPersonales.valid;
      this.updateSpecialityValidators();
    });
    this.frmDatosPersonales.get("cp").valueChanges.subscribe((value) => {
      if (value && value.length === 5) {
        this.SearchState(value);
      }
    });
    this.updateSpecialityValidators();
  }
  get f() {
    return this.frmDatosPersonales.controls;
  }

  ngAfterViewInit(): void {
    this.markFormGroupTouched(this.frmDatosPersonales);
    this.cdr.detectChanges();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    const excludedFields = [
      "especialidad",
      "cedula_especialidad",
      "subespecialidad",
      "cedula_subespecialidad",
      "equipamiento",
      "pago_mensual",
    ];

    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);

      // Si el control no está en la lista excluida
      if (!excludedFields.includes(controlName)) {
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        } else if (control instanceof FormGroup) {
          this.markFormGroupTouched(control); // Recursividad para sub-formularios
        }
      }
    });
    // Object.values(formGroup.controls).forEach(control => {
    //   if (control instanceof FormControl) {
    //     control.markAsTouched({ onlySelf: true });
    //     control.markAsDirty({ onlySelf: true });
    //   } else if (control instanceof FormGroup) {
    //     this.markFormGroupTouched(control);
    //   }
    // });
  }
  get fp() {
    return this.frmDatosPersonales.controls;
  }
  getErrorMessage() {
    const rfcControl = this.frmDatosPersonales.get("rfc");
    const curpControl = this.frmDatosPersonales.get("curp");
    const especialidadControl = this.frmDatosPersonales.get("especialidad");
    const subEspecialidadControl =
      this.frmDatosPersonales.get("subespecialidad");
    if (rfcControl && rfcControl.hasError("minlength")) {
      return "Inserta un RFC correcto";
    }
    if (curpControl && curpControl.hasError("minlength")) {
      return "Inserta un CURP correcto";
    }
    if (especialidadControl && especialidadControl.hasError("required")) {
      return "Este campo es requerido";
    }
    if (subEspecialidadControl && subEspecialidadControl.hasError("required")) {
      return "Este campo es requerido";
    }

    return "";
  }
  toggleEspeciality(event: MatSlideToggleChange) {
    this.haveSpeciality = event.checked;
  }
  customEmailValidator(control: FormControl) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(control.value)) {
      return {
        invalidEmail: true,
        message: "Por favor, ingresa un correo electrónico válido.",
      };
    }
    return null;
  }
  noLeadingWhitespaceValidator(control: FormControl) {
    if (
      control.value &&
      control.value.trim().length > 0 &&
      control.value.startsWith(" ")
    ) {
      return {
        leadingWhitespace: true,
        message: "El campo no debe contener espacios en blanco al inicio.",
      };
    }
    return null;
  }
  async SearchState(cp) {
    if (cp && cp.length == 5) {
      const estados: any = await this?.buroService.buscarEstado(cp);
      const colonia = estados.map((d) => d.response.asentamiento);
      this.COLONIAS = colonia;
      for (const cur of estados) {
        if (!cur.error) {
          const { municipio, estado, ciudad, asentamiento } = cur.response;
          this.fp.delegacion_municipio.setValue(municipio);
          this.fp.ciudad.setValue(ciudad);
          this.fp.estado.setValue(this.getEstado(estado));
          this.fp.estado_nombre.setValue(estado);

          break;
        }
      }
    }
  }

  getEstado(estado) {
    const result: any = this.estados.filter((e) => e.estado === estado);
    return result.length ? result[0].id : "CDMX";
  }
  // atras(){
  //   this.router.navigate(['/nueva_solicitud'])
  // }

  updateSpecialityValidators() {
    const cedulaEspecialidadControl = this.frmDatosPersonales.get(
      "cedula_especialidad"
    );
    const especialidadControl = this.frmDatosPersonales.get("especialidad");
    const cedulaSubespecialidadControl = this.frmDatosPersonales.get(
      "cedula_subespecialidad"
    );
    const subespecialidadControl =
      this.frmDatosPersonales.get("subespecialidad");

    if (
      cedulaEspecialidadControl &&
      especialidadControl &&
      cedulaSubespecialidadControl &&
      subespecialidadControl
    ) {
      if (this.haveSpeciality) {
        cedulaEspecialidadControl.setValidators(Validators.required);
        especialidadControl.setValidators(Validators.required);
      } else {
        cedulaEspecialidadControl.clearValidators();
        especialidadControl.clearValidators();
        cedulaSubespecialidadControl.clearValidators();
        subespecialidadControl.clearValidators();
      }

      cedulaEspecialidadControl.updateValueAndValidity({ emitEvent: false });
      especialidadControl.updateValueAndValidity({ emitEvent: false });
      cedulaSubespecialidadControl.updateValueAndValidity({ emitEvent: false });
      subespecialidadControl.updateValueAndValidity({ emitEvent: false });
    } else {
      console.error("Uno o más controles de formulario no existen");
    }
  }

  async enviarDatos() {
    const emailCliente = this.frmDatosPersonales.get("correo_cliente").value;
    const rfcCliente = this.frmDatosPersonales.get("rfc").value.toUpperCase();
    if (this.frmDatosPersonales.valid) {
      this.loading = true;
      this.errorMessageGuardar = false;
      const nombres = this.data.first_name
        ? `${this.data.first_name} ${this.data.second_name}`
        : this.frmDatosPersonales.get("nombres").value;

      const data_send = {
        email: this.frmDatosPersonales.get("correo_cliente").value,
        rfc: this.frmDatosPersonales.get("rfc").value.toUpperCase(),
        curp: this.frmDatosPersonales.get("curp").value,
        first_name: nombres ? nombres.split(" ")[0].toUpperCase() : "",
        second_name: this.data.second_name
          ? this.data.second_name.toUpperCase()
          : nombres.split(" ")[1]?.toUpperCase() ?? "",
        first_lastname: this.data?.first_lastname
          ? this.data?.first_lastname.toUpperCase()
          : this.frmDatosPersonales
              .get("apellido_paterno")
              .value.toUpperCase() ?? "",
        second_lastname: this.data?.second_lastname
          ? this.data?.second_lastname.toUpperCase()
          : this.frmDatosPersonales
              .get("apellido_materno")
              .value.toUpperCase() ?? "",
        birthday:
          this.data.birthday ??
          this.frmDatosPersonales.get("fecha_nacimiento").value,
        folio: this.data.folio,
        flujo_cotizador: false,
        cotizacion: this.data.cotizacion,
        omitir_ine: this.omitir_identificacion,
      };

      try {
        if (!this.is_flujo_solicitud_cotizador) {
          const formRegister = {
            email: this.frmDatosPersonales.get("correo_cliente").value,
            password: this.frmDatosPersonales.get("password").value,
            typeUser: 1,
            longitude: "0",
            latitude: "0",
            place: "otro",
            name: nombres,
            lastname: this.frmDatosPersonales.get("apellido_paterno").value,
            secondlastname:
              this.frmDatosPersonales.get("apellido_materno").value,
          };

          const response: any = await this.userService.nuevoRegistro(
            formRegister
          );

          if (response.id_usuario) {
            const resSolicitud =
              await this.nuevaSolicitudService.guardarDatosSolicitud(
                data_send,
                this.data.frontImagePath,
                this.data.backImagePath
              );

            if (resSolicitud) {
              this.data = { ...data_send,  id_usuario: response.id_usuario };
              localStorage.setItem("current_id_cliente", response.id_usuario);
              const _folio = localStorage.getItem("folio_solicitud");
              const reqGuardadoDatosDireccion = {
                direccion: {
                  street: this.frmDatosPersonales.get("calle").value,
                  number: this.frmDatosPersonales.get("num_ext").value,
                  zip: this.frmDatosPersonales.get("cp").value,
                  city: this.frmDatosPersonales.get("ciudad").value,
                  state: this.frmDatosPersonales.get("estado").value,
                  town_hall: this.frmDatosPersonales.get("delegacion_municipio")
                    .value,
                  suburb: this.frmDatosPersonales.get("colonia").value,
                  country: "MX",
                },
                especialidad: {
                  cedula:
                    this.frmDatosPersonales.get("cedula_especialidad").value ||
                    " ",
                  carrera:
                    this.frmDatosPersonales.get("especialidad").value || " ",
                },
                subespecialidad: {
                  cedula:
                    this.frmDatosPersonales.get("cedula_especialidad").value ||
                    " ",
                  carrera:
                    this.frmDatosPersonales.get("subespecialidad").value || " ",
                },
                type_id: this.data?.type_id ?? "",
                number_id: this.data?.number_id ?? "",
              };

              const resGuardarDatosCliente =
                await this.nuevaSolicitudService.guardarTodosDatosSolicitud(
                  emailCliente,
                  rfcCliente,
                  reqGuardadoDatosDireccion
                );
              if (resGuardarDatosCliente) {
                const form = {
                  folio: _folio,
                  client_name: nombres,
                  client_email:
                    this.frmDatosPersonales.get("correo_cliente").value,
                  client_first_name: this.data.first_lastname,
                  client_second_name: this.data.second_lastname,
                  client_phone: " ",
                  id: 0,
                };

                const resp: any =
                  await this.nuevaSolicitudService.crearCotizacionVistaPrevia(
                    form
                  );
                if (resp && resp.folio) {
                  this.loading = false;
                  localStorage.setItem("folio_solicitud", resp.folio);
                  this.data = { ...data_send, ...reqGuardadoDatosDireccion, folio: resp.folio };
                  this.datosActualizados.emit(this.data);
                  const cliente = `${
                    this.frmDatosPersonales.get("nombres").value
                  } ${this.frmDatosPersonales.get("apellido_paterno").value} ${
                    this.frmDatosPersonales.get("apellido_materno").value
                  } `;

                  const contacto = {
                    tipoPersona: "Persona fisica",
                    tituloPersona:
                      this.frmDatosPersonales.get("sexo").value == "F"
                        ? "Sra."
                        : "Sr.",
                    nombreCompleto:
                      this.frmDatosPersonales.get("nombres").value,
                    apellidoPaterno:
                      this.frmDatosPersonales.get("apellido_paterno").value,
                    apellidoMaterno:
                      this.frmDatosPersonales.get("apellido_materno").value,
                    nacionalidad: "1595863462706x437395425278820350",
                    fechaNacimiento:
                      this.frmDatosPersonales.get("fecha_nacimiento").value,
                    genero:
                      this.frmDatosPersonales.get("sexo").value == "F"
                        ? "Mujer"
                        : "Hombre",
                    tipoIdentificacion: this.data.type_id ?? "",
                    numeroIdentificacion: this.data.number_id ?? "",
                    rfc: this.frmDatosPersonales.get("rfc").value ?? "",
                    email: this.frmDatosPersonales.get("correo_cliente").value,
                    curp: this.frmDatosPersonales.get("curp").value,
                  };
                  const direccion = {
                    calle: this.frmDatosPersonales.get("calle").value,
                    ciudad: this.frmDatosPersonales.get("ciudad").value,
                    colonia: this.frmDatosPersonales.get("colonia").value,
                    cp: this.frmDatosPersonales.get("cp").value,
                    estado: this.frmDatosPersonales.get("estado").value,
                    municipio: this.frmDatosPersonales.get(
                      "delegacion_municipio"
                    ).value,
                    numeroExterior:
                      this.frmDatosPersonales.get("num_ext").value,
                  };
                  const informacionAdicional = {
                    tipo_consultorio:
                      this.frmDatosPersonales.get("tipo_consultorio").value,
                    total_gasto:
                      this.frmDatosPersonales.get("total_gasto").value,
                    antiguedad: this.frmDatosPersonales.get("antiguedad").value,
                  };
                  const datosAdicionalesDomicilio = {
                    tipo_vivienda:
                      this.frmDatosPersonales.get("tipo_vivienda").value,
                    pago_mensual:
                      this.frmDatosPersonales.get("pago_mensual").value ?? "",
                  };
                  const resGuardarSolicitud =
                    await this.solicitudService.GuardarSolicitud(resp.folio, {
                      cliente,
                      contacto,
                      direccion,
                      informacionAdicional,
                      datosAdicionalesDomicilio,
                    });
                  if (resGuardarSolicitud) {
                    this.siguiente.emit();
                  }else{
                  }
                } else {
                  throw new Error("Error al crear la cotización vista previa");
                }
              }
            } else {
              throw new Error("Error al guardar datos de solicitud");
            }
          } else if (response.errorResponse?.Code === "23505") {
            this.loading = false;
            this.errorMessageGuardar = true;
            this.errorMessageGuardarText = "Usuario ya registrado";
          }
        } else {
          const resSolicitud =
            await this.nuevaSolicitudService.guardarDatosSolicitud(
              data_send,
              this.data.frontImagePath ?? "",
              this.data.backImagePath ?? ""
            );

          if (resSolicitud) {
            const _folio = localStorage.getItem("folio_solicitud");
            const reqGuardadoDatosDireccion = {
              direccion: {
                street: this.frmDatosPersonales.get("calle").value,
                number: this.frmDatosPersonales.get("num_ext").value,
                zip: this.frmDatosPersonales.get("cp").value,
                city: this.frmDatosPersonales.get("ciudad").value,
                state: this.frmDatosPersonales.get("estado").value,
                town_hall: this.frmDatosPersonales.get("delegacion_municipio")
                  .value,
                suburb: this.frmDatosPersonales.get("colonia").value,
                country: "MX",
              },
              especialidad: {
                cedula:
                  this.frmDatosPersonales.get("cedula_especialidad").value ||
                  " ",
                carrera:
                  this.frmDatosPersonales.get("especialidad").value || " ",
              },
              subespecialidad: {
                cedula:
                  this.frmDatosPersonales.get("cedula_especialidad").value ||
                  " ",
                carrera:
                  this.frmDatosPersonales.get("subespecialidad").value || " ",
              },
              type_id: this.data?.type_id ?? "",
              number_id: this.data?.number_id ?? "",
            };
            const resGuardarDatosCliente =
              await this.nuevaSolicitudService.guardarTodosDatosSolicitud(
                emailCliente,
                rfcCliente,
                reqGuardadoDatosDireccion
              );
            this.data = { ...data_send, ...reqGuardadoDatosDireccion };

            this.datosActualizados.emit(this.data);
            this.loading = false;
            const cliente = `${this.frmDatosPersonales.get("nombres").value} ${
              this.frmDatosPersonales.get("apellido_paterno").value
            } ${this.frmDatosPersonales.get("apellido_materno").value} `;
            const contacto = {
              tipoPersona: "Persona fisica",
              tituloPersona:
                this.frmDatosPersonales.get("sexo").value == "F"
                  ? "Sra."
                  : "Sr.",
              nombreCompleto: this.frmDatosPersonales.get("nombres").value,
              apellidoPaterno:
                this.frmDatosPersonales.get("apellido_paterno").value,
              apellidoMaterno:
                this.frmDatosPersonales.get("apellido_materno").value,
              nacionalidad: "1595863462706x437395425278820350",
              fechaNacimiento:
                this.frmDatosPersonales.get("fecha_nacimiento").value,
              genero:
                this.frmDatosPersonales.get("sexo").value == "F"
                  ? "Mujer"
                  : "Hombre",
              tipoIdentificacion: this.data.type_id ?? "",
              numeroIdentificacion: this.data.number_id ?? "",
              rfc: this.frmDatosPersonales.get("rfc").value ?? "",
              email: this.frmDatosPersonales.get("correo_cliente").value,
              curp: this.frmDatosPersonales.get("curp").value,
            };
            const direccion = {
              calle: this.frmDatosPersonales.get("calle").value,
              ciudad: this.frmDatosPersonales.get("ciudad").value,
              colonia: this.frmDatosPersonales.get("colonia").value,
              cp: this.frmDatosPersonales.get("cp").value,
              estado: this.frmDatosPersonales.get("estado").value,
              municipio: this.frmDatosPersonales.get("delegacion_municipio")
                .value,
              numeroExterior: this.frmDatosPersonales.get("num_ext").value,
            };
            const informacionAdicional = {
              tipo_consultorio:
                this.frmDatosPersonales.get("tipo_consultorio").value,
              total_gasto: this.frmDatosPersonales.get("total_gasto").value,
              antiguedad: this.frmDatosPersonales.get("antiguedad").value,
              ingreso_mensual_promedio: this.frmDatosPersonales.get(
                "ingreso_mensual_promedio"
              ).value,
              equipamiento: this.frmDatosPersonales.get("equipamiento").value,
              numero_dependientes: this.frmDatosPersonales.get(
                "numero_dependientes"
              ).value,
            };
            const datosAdicionalesDomicilio = {
              tipo_vivienda: this.frmDatosPersonales.get("tipo_vivienda").value,
              pago_mensual:
                this.frmDatosPersonales.get("pago_mensual").value ?? "",
            };
            const resGuardarSolicitud =
              await this.solicitudService.GuardarSolicitud(this.data.folio, {
                cliente,
                contacto,
                direccion,
                informacionAdicional,
                datosAdicionalesDomicilio,
              });
            if (resGuardarSolicitud) {
              this.siguiente.emit();
            }
          } else {
            throw new Error("Error al guardar datos de solicitud");
          }
        }
      } catch (error) {
        this.loading = false;
        this._snackBar.open(
          "Se ha producido un error, por favor vuelva a intentarlo más tarde",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
        console.error(error);
      }
    } else {
      alert("El formulario no es válido");
    }
  }
}
