<div>
    <div class="filter">
        <label>Selecciona el rango de fechas</label>
        <div class="row-filter"><span style="width: 100px;">Fecha inicial:</span><input [(ngModel)]="filtros.fechaInicio" type="date" placeholder="Start date" (input)="filtrarFechas()"></div>
        <div class="row-filter"><span style="width: 100px;">Fecha final:</span><input [(ngModel)]="filtros.fechaFin" type="date" placeholder="End date" (input)="filtrarFechas()"></div>
    </div>

    <div class="filter">
        <label>Selecciona el tipo de Reestructuración</label>
        <mat-select class="restructura" [(ngModel)]="filtros.restructura" (selectionChange)="filtrarFechas()">
            <mat-option [value]="''">Todos los anexos</mat-option>
            <mat-option [value]="true">Con reestructuración</mat-option>
            <mat-option [value]="false">Sin reestructuración</mat-option>
        </mat-select>
    </div>
</div>