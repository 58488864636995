import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';

import { UserService, SolicitudService, AuthenticationService } from 'src/app/_services';
import { DatosAnalisis } from '../../../_models/Comite';
import { customPatterns } from '../../../_models/maskPattern';
import {
  COMPORTAMIENTO_PAGO,
  COMPORTAMIENTO_PAGO_CREDITO,
  MESES,
  Analisis,
  TIEMPO_ENTREGA
} from './Analisis';
import { saveAs } from 'file-saver';
import { InfoComponent } from 'src/app/_components/info/info.component';
import { DecimalPipe } from '@angular/common';
import { AnalisisPF } from './AnalisisPF';
import { PrecalificarContactoComponent } from 'src/app/_dialogs/precalificar-contacto/precalificar-contacto.component';
import { EditarContactoComponent } from 'src/app/_dialogs/editar-contacto/editar-contacto.component';
import { Console } from 'console';
import { AnalisisCreditoService } from 'src/app/_services/analisis-credito.service';
@Component({
  selector: 'app-analisis',
  templateUrl: './analisis.component.html',
  styleUrls: ['./analisis.component.scss']
})
export class AnalisisComponent implements OnInit {
  @Input() solicitud: any
  @Input() analisisPf: any;  
  @Output() analisisPfChange = new EventEmitter<any>();
  @Output() actualizarPromedios = new EventEmitter<any>();
  @Output() cambiarPuntuacion = new EventEmitter<any>();
  @Output() puntuacionCaracter = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPf && changes.analisisPf.currentValue) {
      console.log("Nuevo valor de analisisPf ANALISIS:", this.analisisPf);
      this.AnalisisPF = { ...this.analisisPf };
      
      this.AnalisisPF.capacidad_pago.comprobantes_gastos = Number(this.AnalisisPF.entrevista_general.mmr) + Number(this.AnalisisPF.entrevista_general.emd) + Number(this.AnalisisPF.entrevista_general.gastos_buro)
      this.AnalisisPF.capacidad_pago.efectivo_gastos = Number(this.AnalisisPF.capacidad_pago.comprobantes_gastos) + Number(this.AnalisisPF.entrevista_consultorio.monto_renta)
      this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos = Number(this.AnalisisPF.capacidad_pago.efectivo_gastos) + Number(this.AnalisisPF.cotizacion.mensualidad)
      this.AnalisisPF.capacidad_pago.comprobantes_factor = ((Number(this.AnalisisPF.capacidad_pago.comprobantes_gastos) / Number(this.AnalisisPF.capacidad_pago.ingresos_nomina)) * 100).toFixed(1)
      this.AnalisisPF.capacidad_pago.efectivo_factor =Number((( Number(this.AnalisisPF.capacidad_pago.efectivo_gastos) / Number(this.AnalisisPF.capacidad_pago.ingresos_efectivo)) * 100).toFixed(1))
      this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor =(( Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos) / Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos)) * 100).toFixed(1)
      this.ActualizarPromedios();
      this.AnalisisPF.capacidad_pago.factor_renta = ((Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos) - Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos)) / Number(this.AnalisisPF.cotizacion.mensualidad)).toFixed(2);
          // const PromedioFactor = (this.AnalisisPF.comprobantes_factor + this.AnalisisPF.efectivo_factor + this.AnalisisPF.extra_nuevo_equipo_factor) / this.AnalisisPF.historial_movimientos.length
          const PromedioFactor = Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor);
          this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = (PromedioFactor > 81) ? 0 
          : (PromedioFactor > 75) ? 25
          : (PromedioFactor > 70) ? 28
          : (PromedioFactor >= 66) ? 30 : 33 ;
          this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = (this.AnalisisPF.capacidad_pago.factor_renta > 2)
            ? this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago += 3
            : this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago;
      
            this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago > 33 ? 33 : this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago;
      this.AnalisisPF.perfil_solicitante.consejo_especialidad = this.AnalisisPF.entrevista_general.consejo_especialidad  ? 'SI' : 'NO';
      const arraigo_domicilio = this.AnalisisPF?.entrevista_general?.domicilio_antiguedad ? 
      (this.AnalisisPF.entrevista_general.domicilio_antiguedad.match(/\d+/)?.[0] ?? 0) : 0;
      const arraigo_empleo = this.AnalisisPF?.entrevista_consultorio?.antiguedad ? 
      (this.AnalisisPF.entrevista_consultorio.antiguedad.match(/\d+/)?.[0] ?? 0) : 0;
      this.AnalisisPF.perfil_solicitante.arraigo_domicilio = this.AnalisisPF.entrevista_general.domicilio_antiguedad || 0
      this.CambiarPuntuacionSolicitante()
      this.AnalisisPF.capacidad_pago.ingresos_efectivo = Number(this.AnalisisPF.entrevista_consultorio.ingresos_efectivo)
      this.AnalisisPF.capacidad_pago.ingresos_extra = Number(this.AnalisisPF.entrevista_consultorio.ingresos_extras_equipo)
      this.AnalisisPF.perfil_solicitante.arraigo_empleo = Number(arraigo_empleo)
      this.PuntuacionCaracter()
      this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral
      this.CambiarPuntuacionColateral()

      this.cdr.detectChanges();


      this.ActualizarComportamientoCredito()
      this.CambiarPuntuacionSolicitante()
      this.CambiarPuntuacionAval()
      this.calcularRentaAdicionalDisponible();

      
    }
  }

  customPattern = customPatterns;
  MESES = MESES;
  COMPORTAMIENTO_PAGO = COMPORTAMIENTO_PAGO;
  COMPORTAMIENTO_PAGO_CREDITO = COMPORTAMIENTO_PAGO_CREDITO;
  COMERCIAL_LIST = [
    'MELINA Z.',
    'NORMA G.',
    'ITZEL P.'
  ];
  TIEMPO_ENTREGA = TIEMPO_ENTREGA;

  paginas = [
    {name: 'Datos generales', short_name: 'Datos'},
    {name: 'Carácter', short_name: 'Carácter'},
    {name: 'Capacidad Pago', short_name: 'Capacidad Pago'},
    {name: 'Colateral y Condiciones', short_name: 'Colateral'}
  ];

  AnalisisPF:any = {};
  // DatosAnalisis = DatosAnalisis;
  actual = 1;
  folio = null;
  submit = false;
  analisisCompletado = '';
  complete = false;
  pdf = false;
  saldo_inicial = 0;
  cliente;
  loading = false;

  contactoAval = null
  contactoSolicitante = null

  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private solicitudService: SolicitudService,
              private authenticationService: AuthenticationService,
              private decimalPipe: DecimalPipe,
              private cdr: ChangeDetectorRef,
              private analisisCredito: AnalisisCreditoService
            ) { }

  ubicacionActivoCopy="";
  ngOnInit(): void {
    this.AnalisisPF = JSON.parse(JSON.stringify(AnalisisPF))
    this.AnalisisPF.perfil_aval.nombre = this.ObtenerNombre(true)

    console.log('init: ', Analisis.historial_credito.activas);
    console.log('init: ', this.AnalisisPF.historial_credito ? this.AnalisisPF.historial_credito.activas : null);
    
    const {folio, id_cliente, tipo_persona, cliente} = JSON.parse( localStorage.getItem('client_details'));
    this.cliente = cliente
    this.folio = folio;

    console.log("this folio", this.folio)
    this.AnalisisPF.caracteristica_activo.tipo_financiamient = "ARRENDAMIENTO PURO"
    this.AnalisisPF.caracteristica_activo.tipo_financiamiento_pts = 7
    this.ActualizarPromedios();
    this.CambiarPuntuacionColateral()
    this.CambiarPuntuacionSolicitante()
    this.PuntuacionCaracter()
    this.ActualizarComportamientoCredito()
    this.CambiarPuntuacionSolicitante()
    this.CambiarPuntuacionAval()


    // const actualizar_informacion = JSON.parse(localStorage.getItem('actualizar_informacion')) || null;
    // if ( actualizar_informacion !== null ) {
    //   console.log('Encontrado');
    //   this.AnalisisPF = actualizar_informacion.Analisis;
    //   this.DatosAnalisis = actualizar_informacion.DatosAnalisis;
    //   this.actual = actualizar_informacion.parte || 1;
    //   if (actualizar_informacion.parte == 3) {
    //     this.ActualizarPromedios();
    //   }
    //   return;
    // }

    // this.userService.getDatosAnalisis( Number(this.folio) ).subscribe( async (DatosAnalisis: any) => {
    //   console.log('ANALISIS GETDATOS: ', DatosAnalisis);

    //   // this.DatosAnalisis = DatosAnalisis;
    //   this.AnalisisPF.entrevista_economica.puesto = DatosAnalisis.puesto;
    //   this.AnalisisPF.cotizacion.fecha_cotizacion = DatosAnalisis.fecha_cotizacion.split('T')[0];
    //   this.AnalisisPF.aval.aval_parentezco = DatosAnalisis.aval_parentezco;
    //   this.AnalisisPF.aval.aval_ocupacion = DatosAnalisis.aval_ocupacion;
    //   this.AnalisisPF.capacidad_pago.ingresos_efectivo = DatosAnalisis.ingresos_efectivo;
    //   this.AnalisisPF.capacidad_pago.ingresos_extra = DatosAnalisis.ingresos_extras_equipo;
    //   this.AnalisisPF.colateral.pago_inicial = DatosAnalisis.pago_inicial;
    //   this.AnalisisPF.colateral.pago_inicialCopy = DatosAnalisis.pago_inicial;
    //   this.AnalisisPF.cotizacion.cotizacion = DatosAnalisis.cotizacion;

    //   const {mensualidad, renta_inicial = 0} = await <any> this.solicitudService.ObtenerMensualidad(DatosAnalisis.folio, DatosAnalisis.version, DatosAnalisis.plazo).then( mensualidad => mensualidad)
    //   this.AnalisisPF.cotizacion.mensualidad = mensualidad;
    //   const {residual} = await <any> this.solicitudService.ObtenerResidual(DatosAnalisis.folio, DatosAnalisis.version, DatosAnalisis.plazo).then( residual => residual)
    //   this.AnalisisPF.cotizacion.valor_residual = residual
    //   console.log("Valor residual: ", residual);
      

    //   this.AnalisisPF.cotizacion.monto_arrendar = DatosAnalisis.monto_arrendar;
    //   this.AnalisisPF.caracteristica_activo.descripcion_activo = DatosAnalisis.activo_arrendar;
    //   this.AnalisisPF.caracteristica_activo.tipo_activo_arrendar = DatosAnalisis.tipo_activo_arrendar;
    //   this.AnalisisPF.perfil_solicitante.bc_score = DatosAnalisis.bc_score;
    //   this.AnalisisPF.perfil_solicitante.arraigo_empleo = parseInt(DatosAnalisis.empleo_antiguedad);
    //   this.AnalisisPF.perfil_solicitante.arraigo_empleo_punt = parseInt(DatosAnalisis.empleo_antiguedad) >= 2 ? 2 : 1;
    //   this.AnalisisPF.perfil_aval.nombre = DatosAnalisis.aval_nombre;
    //   this.AnalisisPF.perfil_aval.parentezco = DatosAnalisis.aval_parentezco;
    //   this.AnalisisPF.perfil_aval.ocupacion = DatosAnalisis.aval_ocupacion;
    //   this.AnalisisPF.capacidad_pago.comprobantes_gastos = DatosAnalisis.gastos_comprobables;
    //   this.AnalisisPF.capacidad_pago.efectivo_gastos = DatosAnalisis.monto_renta_consultorio + DatosAnalisis.gastos_comprobables;
    //   this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos = this.AnalisisPF.efectivo_gastos + this.AnalisisPF.mensualidad;
    //   this.AnalisisPF.cotizacion.mar_total = DatosAnalisis.monto_arrendar - DatosAnalisis.pago_inicial;
    //   this.AnalisisPF.cotizacion.factor_porcentaje = DatosAnalisis.factor_porcentaje;
    //   this.AnalisisPF.cotizacion.plazo = parseInt(DatosAnalisis.plazo);
    //   this.AnalisisPF.cotizacion.tiempo_entrega = DatosAnalisis.tiempo_entrega;
    //   // this.AnalisisPF.rentas_anticipadas = ((DatosAnalisis.monto_arrendar * (DatosAnalisis.factor_porcentaje /100)) / 1.16).toFixed(2);
    //   this.AnalisisPF.cotizacion.rentas_anticipadas = (renta_inicial * 1.16).toFixed(2);
    //   this.AnalisisPF.colateral.factor = this.AnalisisPF.plazo * this.AnalisisPF.mensualidad;
    //   this.AnalisisPF.colateral.factorCopy = this.AnalisisPF.colateral.factor;
    //   console.log('Facot,', this.AnalisisPF.colateral.factor, DatosAnalisis);
    //   this.AnalisisPF.cotizacion.vendor = DatosAnalisis.perfil_responsable == 2 ? this.AnalisisPF.cotizacion.vendor : '';
    //   this.AnalisisPF.cotizacion.comercial = DatosAnalisis.perfil_responsable == 3 ? this.AnalisisPF.cotizacion.vendor : '';
    //   this.AnalisisPF.cotizacion.tipo_vendor = 'Interno';
    //   console.log("Analisis: ", this.AnalisisPF);
      
    //   this.AlmacenarLocalStorage(this.actual);
    //   // this.AnalisisPF.extra_nuevo_equipo_gastos = this.AnalisisPF.efectivo_gastos + DatosAnalisis.colateral.mensualidad

    //   // this.AnalisisPF.comprobantes_gastos = DatosAnalisis.capacidad_pago.gastos_comprobables
    //   // this.AnalisisPF.efectivo_gastos = DatosAnalisis.capacidad_pago.gastos_efectivo
    //   // this.AnalisisPF.ingresos_efectivo = DatosAnalisis.capacidad_pago.ingresos_efectivo
    //   // this.AnalisisPF.ingresos_extra = DatosAnalisis.capacidad_pago.ingresos_extra
    // });

    // const guardado = { id_cliente, folio, parte: 1, tipo_persona};
    // this.solicitudService.getDatosGenerales( {guardado} )
    // .subscribe( res => {
    //   console.log('DG: ', res);

    //   this.AnalisisPF.tipo_persona = tipo_persona == 1
    //   ? `${res.nombres} ${res.ap_paterno} ${res.ap_materno}`
    //   : res.razon_social;
    //   this.AnalisisPF.perfil_solicitante.consejo_especialidad = res.pertenece == 'true' && res.pertenece ? 'SI' : 'NO';
    //   console.log("Pertenece: ", this.AnalisisPF.perfil_solicitante.consejo_especialidad, res.pertenece);
      
    //   this.AlmacenarLocalStorage(this.actual);
    // });

    // guardado.parte = 2;
    // this.solicitudService.getDatosGenerales( {guardado} )
    // .subscribe( res => {
    //   console.log(res);
    //   this.AnalisisPF.perfil_solicitante.arraigo_domicilio = parseInt(res.tiempo_residencia);
    //   this.AnalisisPF.perfil_solicitante.arraigo_domicilio_punt = parseInt(res.tiempo_residencia) >= 2 ? 2 : 1;
    //   this.AlmacenarLocalStorage(this.actual);
    // });

    // guardado.parte = 3;
    // this.solicitudService.getDatosGenerales( {guardado} )
    // .subscribe( res => {
    //   console.log('residencia, especialidad', res);
    //   this.AnalisisPF.residencia_escuela = res.lugar_recidencia;
    //   this.AnalisisPF.especialidad = res.ocupacion_medica;
    //   this.AnalisisPF.perfil_solicitante.consejo_especialidad = res.pertenece == 'true' ? 'SI' : 'NO';
    //   this.AlmacenarLocalStorage(this.actual);
    // });
    this.AnalisisPF.caracteristica_activo.tipo_financiamient = "ARRENDAMIENTO PURO"
    this.AnalisisPF.caracteristica_activo.tipo_financiamiento_pts = 7
    
  }

  async NextPage() {
    if ( !this.userService.alertService.isOnline() ) { return }
    // this.submit = false;
    this.loading = true;

    const { puntuacion_comportamiento_pago, puntuacion_caracter, puntuacion_capacidad_pago, puntuacion_colateral } = this.AnalisisPF;
    // if ( this.actual == 1 && this.DatosGeneralesAnalisis() ) {this.CambiarPuntuacionSolicitante(); return this.AlmacenarLocalStorage(this.actual++); }
    // if ( this.actual == 2 && puntuacion_caracter && this.CaracterAnalisis() ) { return this.AlmacenarLocalStorage(this.actual++); }
    // if ( this.actual == 3 && /*this.AnalisisPF.promedio_depositos > 0 &&*/ this.AnalisisPF.comentarios_capacidad_pago) { return this.AlmacenarLocalStorage(this.actual++); }

    // this.submit = true;

    // if ( !this.AnalisisPF.vida_util ||
    //     !this.AnalisisPF.estimado_de_colocacion ||
    //     !this.AnalisisPF.ubicacion ||
    //     !this.AnalisisPF.tipo_financiamient ||
    //     !this.AnalisisPF.garantia ||
    //     !this.AnalisisPF.marca ||
    //     this.AnalisisPF.comercial == ''
    //   ) { return; }

    // if ( this.actual === this.paginas.length && puntuacion_colateral ) {
    //   this.dialog.open(MessageBoxComponent, {
    //     width: '500px',
    //     data: {
    //       text: this.analisisCompletado,
    //       type: 'success',
    //       icon: 'success'
    //     }
    //   });
    //   this.router.navigate(['/dashboard', 'comite', this.folio]);
    // }
    // return;
    const resGuardado: any = await this.solicitudService.GuardarAnalisisCredito(this.AnalisisPF);
    if (resGuardado.statusCode === 200) {
      this.loading = false;
      this.analisisPfChange.emit(this.AnalisisPF);
      window.scroll(0, 0);
      this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito',
          text2: 'Se han guardado la Analisis',
          // text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    } else {
      this.loading = false;
    }
  }

  CambiarComportamientoPago( id ) {
    this.AnalisisPF.caracter.puntuacion_comportamiento_pago = (this.AnalisisPF.solicitante && this.AnalisisPF.id_comportamiento_pago)
      ? this.COMPORTAMIENTO_PAGO.filter( cp => cp.id == id )[0].puntuacion
      : 0;
      console.log("gastos", this.AnalisisPF.cliente_l4u)
      !this.AnalisisPF.cliente_l4u ?  this.AnalisisPF.caracter.puntuacion_comportamiento_pago = 0 : this.AnalisisPF.caracter.puntuacion_comportamiento_pago = (this.AnalisisPF.solicitante && this.AnalisisPF.id_comportamiento_pago)
      ? this.COMPORTAMIENTO_PAGO.filter( cp => cp.id == id )[0].puntuacion
      : 0;
      this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral

  }

  CambiarComportamientoPagoCredito(tipo, idx) {
    const comportamiento = this.COMPORTAMIENTO_PAGO_CREDITO.find(cp => cp.id === this.AnalisisPF.historial_credito[tipo][idx].id_comportamiento_pago);
    if (comportamiento) {
      this.AnalisisPF.historial_credito[tipo][idx].puntuacion = comportamiento.puntuacion;
      this.AnalisisPF.historial_credito[tipo][idx].comp_pago = comportamiento.nombre;
    } else {
      console.error('Comportamiento de pago no encontrado');
    }
    this.ActualizarComportamientoCredito();
    this.PuntuacionCaracter(); // Actualizamos los puntos de la seccion Caracter
  }


  ActualizarComportamientoCredito() {
    this.AnalisisPF.puntos_credito_abierto = 0;
    this.AnalisisPF.puntos_credito_cerradas = 0;

    this.AnalisisPF.historial_credito.activas.forEach( activa => {
      if (activa.id_comportamiento_pago == 1) {
        activa.puntuacion = this.AnalisisPF.historial_credito.activas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 0
          ? 16
          : this.AnalisisPF.historial_credito.activas.length == 1 ? 8 : this.AnalisisPF.historial_credito.activas.length > 2 ? 2 : 4
      } else if (activa.id_comportamiento_pago == 2) {
        activa.puntuacion = this.AnalisisPF.historial_credito.activas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 0
          ? 8
          : this.AnalisisPF.historial_credito.activas.length == 1 ? 4 : this.AnalisisPF.historial_credito.activas.length > 2 ? 1 : 2
      } else {
        activa.puntuacion = this.AnalisisPF.historial_credito.activas.length == 1 && this.AnalisisPF.historial_credito.cerradas.length == 0
          ? 4
          : this.AnalisisPF.historial_credito.activas.length == 1 ? 2 : 0
      }
    });

    this.AnalisisPF.historial_credito.cerradas.forEach( activa => {
      if (activa.id_comportamiento_pago == 1) {
        activa.puntuacion = this.AnalisisPF.historial_credito.cerradas.length == 1 && this.AnalisisPF.historial_credito.activas.length == 0
          ? 16
          : this.AnalisisPF.historial_credito.cerradas.length == 1 ? 8 : this.AnalisisPF.historial_credito.cerradas.length > 2 ? 2 : 4
      } else if (activa.id_comportamiento_pago == 2) {
        activa.puntuacion = this.AnalisisPF.historial_credito.cerradas.length == 1 && this.AnalisisPF.historial_credito.activas.length == 0
          ? 8
          : this.AnalisisPF.historial_credito.cerradas.length == 1 ? 4 : this.AnalisisPF.historial_credito.cerradas.length > 2 ? 1 : 2
      } else {
        activa.puntuacion = this.AnalisisPF.historial_credito.cerradas.length == 1 && this.AnalisisPF.historial_credito.activas.length == 0
          ? 4
          : this.AnalisisPF.historial_credito.cerradas.length == 1 ? 2 : 0
      }
    });


    this.AnalisisPF.historial_credito.activas.forEach( activa => this.AnalisisPF.puntos_credito_abierto += activa.puntuacion);
    this.AnalisisPF.historial_credito.cerradas.forEach( activa => this.AnalisisPF.puntos_credito_cerradas += activa.puntuacion);

  }

  ActualizarPromedios() {
    this.AnalisisPF.promedio_depositos = 0;
    this.AnalisisPF.promedio_gastos = 0;
    this.AnalisisPF.promedio_saldo_final = 0;

    const saldo_inicial = this.AnalisisPF.saldo_inicial
    this.AnalisisPF.historial_movimientos.forEach( (mov, i) => {
      const saldo_anterior = (i > 0) ? parseFloat(this.AnalisisPF.historial_movimientos[i -1].saldo_final) : Number(saldo_inicial)

      mov.saldo_final = parseFloat(mov.depositos) - parseFloat(mov.gastos) + saldo_anterior

      this.AnalisisPF.promedio_depositos += (parseFloat(mov.depositos) / this.AnalisisPF.historial_movimientos.length);
      this.AnalisisPF.promedio_gastos += (parseFloat(mov.gastos) / this.AnalisisPF.historial_movimientos.length);
      this.AnalisisPF.promedio_saldo_final += (parseFloat(mov.saldo_final) / this.AnalisisPF.historial_movimientos.length);

    });

    // this.AnalisisPF.comprobantes_gastos = this.AnalisisPF.promedio_gastos

    this.AnalisisPF.capacidad_pago.comprobantes_ingresos = (this.AnalisisPF.promedio_depositos * 1) + (this.AnalisisPF.capacidad_pago.ingresos_nomina * 1);
    this.AnalisisPF.capacidad_pago.efectivo_ingresos = (this.AnalisisPF.entrevista_consultorio.ingresos_efectivo * 0.6) + this.AnalisisPF.capacidad_pago.comprobantes_ingresos;
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos = (this.AnalisisPF.capacidad_pago.ingresos_extra * 0.5) + this.AnalisisPF.capacidad_pago.efectivo_ingresos;


    this.AnalisisPF.capacidad_pago.comprobantes_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.comprobantes_gastos / this.AnalisisPF.capacidad_pago.comprobantes_ingresos));
    this.AnalisisPF.capacidad_pago.efectivo_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.efectivo_gastos / this.AnalisisPF.capacidad_pago.efectivo_ingresos));
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor = Math.trunc(100 * (this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos / this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos));
    // this.actualizarPromedios.emit()
    console.log(this.AnalisisPF.cotizacion.mensualidad);
    
    this.AnalisisPF.capacidad_pago.factor_renta = ((Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos) - Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos)) / Number(this.AnalisisPF.cotizacion.mensualidad.toFixed(2).replace(/[$,]/g, ''))).toFixed(2);
          // const PromedioFactor = (this.AnalisisPF.comprobantes_factor + this.AnalisisPF.efectivo_factor + this.AnalisisPF.extra_nuevo_equipo_factor) / this.AnalisisPF.historial_movimientos.length
          const PromedioFactor = Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor);
          this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = (PromedioFactor > 81) ? 0 
          : (PromedioFactor > 75) ? 25
          : (PromedioFactor > 70) ? 28
          : (PromedioFactor >= 66) ? 30 : 33 

            if (this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago < 33) {
              if (this.AnalisisPF.capacidad_pago.factor_renta > 2) {
                  this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago += this.AnalisisPF.capacidad_pago.factor_renta;
                  if(this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago > 33){
                    this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago = 33
                  }
              }
          }
        
          this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral

        
      
  }

  ActualizarPuntuacion(perfil) {
    if (perfil === 'perfil_aval') {
      this.AnalisisPF.perfil_aval.icc_punt = Number(this.AnalisisPF.perfil_aval.icc_punt);
      this.AnalisisPF.perfil_aval.ocupacion_punt = Number(this.AnalisisPF.perfil_aval.ocupacion_punt);
      this.AnalisisPF.perfil_aval.parentezco_punt = Number(this.AnalisisPF.perfil_aval.parentezco_punt);
    }
  
    const {
      bc_score_punt = 0,
      icc_punt = 0,
      arraigo_domicilio_punt = 0,
      arraigo_empleo_punt = 0,
      consejo_especialidad_punt = 0,
      documentacion_punt = 0,
      parentezco_punt = 0,
      ocupacion_punt = 0,
    } = this.AnalisisPF[perfil];
  
    const safeNumber = (val) => isNaN(Number(val)) ? 0 : Number(val);
  
    this.AnalisisPF[perfil].total = (
      safeNumber(bc_score_punt) +
      safeNumber(icc_punt) +
      safeNumber(arraigo_domicilio_punt) +
      safeNumber(arraigo_empleo_punt) +
      safeNumber(consejo_especialidad_punt) +
      safeNumber(documentacion_punt) +
      safeNumber(parentezco_punt) +
      safeNumber(ocupacion_punt)
    );
  
    this.PuntuacionCaracter(); // Actualizamos los puntos de la sección Caracter
  }
  

  PuntuacionCaracter() {
    const puntuacionCaracter = (
      (this.AnalisisPF.perfil_solicitante.total ?? 0) + 
      (this.AnalisisPF.perfil_aval.total ?? 0) + 
      (this.AnalisisPF.puntos_credito_abierto ?? 0) + 
      (this.AnalisisPF.puntos_credito_cerradas ?? 0)
    );

    this.AnalisisPF.puntuacion_caracter = puntuacionCaracter > 34 ? 34 : puntuacionCaracter;
    this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral

    // this.puntuacionCaracter.emit()
}

  PuntuacionColateral() {
    const {
      vida_util_pts,
      mercado_secundario_pts,
      estimado_de_colocacion_pts,
      ubicacion_pts,
      tipo_financiamiento_pts
    } = this.AnalisisPF.caracteristica_activo;

    this.AnalisisPF.colateral.puntuacion_colateral =
    ( Number(vida_util_pts) + Number(mercado_secundario_pts) + Number(estimado_de_colocacion_pts) + Number(ubicacion_pts) + Number(tipo_financiamiento_pts) );
    this.AnalisisPF.puntuacion_total = this.AnalisisPF.caracter.puntuacion_comportamiento_pago + this.AnalisisPF.puntuacion_caracter + this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago + this.AnalisisPF.colateral.puntuacion_colateral

  }

  DatosGeneralesAnalisis() {
    const { numero_comite, tipo_comite, caracteristica_activo: {tipo_activo_arrendar}, entrevista_general: {domicilio}, inicio_operaciones } = this.AnalisisPF;
    if ( !(numero_comite > 0) ||
      !tipo_comite ||
      !tipo_activo_arrendar ||
      !domicilio ||
      !inicio_operaciones ) { return false; }
    return true;
  }

  CaracterAnalisis() {
    if ( this.AnalisisPF.nombre_escuela_egresado &&
        // this.AnalisisPF.residencia_escuela &&
        // this.AnalisisPF.especialidad &&
        this.AnalisisPF.caracter.comentarios_caracter &&
        this.AnalisisPF.perfil_solicitante.bc_score &&
        this.AnalisisPF.perfil_aval.bc_score) { return true; }
    return false;
  }

  CambiarPuntuacionSolicitante() {
    this.AnalisisPF.perfil_solicitante.bc_score_punt =
      (this.AnalisisPF.perfil_solicitante.bc_score >= 630) ? 7
      : (this.AnalisisPF.perfil_solicitante.bc_score >= 615 ) ? 5
      : (this.AnalisisPF.perfil_solicitante.bc_score >= 600 ) ? 4 : 0;

    this.AnalisisPF.perfil_solicitante.arraigo_domicilio_punt =
      (this.AnalisisPF.perfil_solicitante.arraigo_domicilio >= 2) ? 2 : 
      (this.AnalisisPF.perfil_solicitante.arraigo_domicilio >= 1) ? 1 : 0;
    this.AnalisisPF.perfil_solicitante.arraigo_empleo_punt =
      (this.AnalisisPF.perfil_solicitante.arraigo_empleo >= 2) ? 2 : (this.AnalisisPF.perfil_solicitante.arraigo_empleo >= 1) ? 1 : 0;

    this.AnalisisPF.perfil_solicitante.consejo_especialidad_punt =
      (this.AnalisisPF.perfil_solicitante.consejo_especialidad == 'SI') ? 3 : 0;

    this.ActualizarPuntuacion('perfil_solicitante');
    // this.cambiarPuntuacion.emit()
  }

  CambiarPuntuacionAval() {
    this.AnalisisPF.perfil_aval.bc_score_punt =
      (this.AnalisisPF.perfil_aval.bc_score >= 630) ? 4
      : (this.AnalisisPF.perfil_aval.bc_score >= 615 ) ? 2
      : (this.AnalisisPF.perfil_aval.bc_score >= 600 ) ? 1 : 0;

    this.AnalisisPF.perfil_aval.documentacion_punt =
      (this.AnalisisPF.perfil_aval.documentacion) ? 1 : 0;

    this.ActualizarPuntuacion('perfil_aval');
  }

  CambiarPuntuacionColateral() {
    this.valorUbicacion(this.AnalisisPF.caracteristica_activo.ubicacion);
    const tiempo_arrendamiento = this.AnalisisPF.cotizacion.plazo / 12
    this.AnalisisPF.caracteristica_activo.vida_util_pts = Number(this.AnalisisPF.caracteristica_activo.vida_util) > tiempo_arrendamiento ? 7 : Number(this.AnalisisPF.caracteristica_activo.vida_util) == tiempo_arrendamiento ? 5 : 0;
    this.AnalisisPF.caracteristica_activo.mercado_secundario_pts =
      (this.AnalisisPF.caracteristica_activo.mercado_secundario == 'BUENO') ? 7
      : (this.AnalisisPF.caracteristica_activo.mercado_secundario == 'REGULAR') ? 5 : 3;
    this.AnalisisPF.caracteristica_activo.estimado_de_colocacion_pts =
      (Number(this.AnalisisPF.caracteristica_activo.estimado_de_colocacion) > 180) ? 3
      : (Number(this.AnalisisPF.caracteristica_activo.estimado_de_colocacion) >= 121) ? 5
      : (Number(this.AnalisisPF.caracteristica_activo.estimado_de_colocacion) >= 91) ? 6
      : (Number(this.AnalisisPF.caracteristica_activo.estimado_de_colocacion) >= 60) ? 7 : 0;
      
      // this.AnalisisPF.caracteristica_activo.ubicacion_pts = Number(this.AnalisisPF.caracteristica_activo.ubicacion)
    this.PuntuacionColateral();
  }

  AgregarCuenta(tipo) {
    const status = tipo == 'activas' ? true : false;
    console.log(status);

    this.AnalisisPF.historial_credito[tipo]
    .push({tipo_credito: '', monto: 0, mop_max: 0, status, id_comportamiento_pago: 0, comp_pago: '', puntuacion: 0});
    this.ActualizarComportamientoCredito();
  }

  BorrarCuenta(tipo, idx) {
    this.AnalisisPF.historial_credito[tipo].splice(idx, 1);
    this.ActualizarComportamientoCredito();
  }

  AgregarMovimiento() {
    this.AnalisisPF.historial_movimientos
    .push({mes: '', depositos: 0, gastos: 0, saldo_final: 0});
    this.ActualizarPromedios();
  }
  BorrarMovimiento(idx) {
    this.AnalisisPF.historial_movimientos.splice(idx, 1);
    this.ActualizarPromedios();
  }

  agregarComportamientoCuentas(tipoCuenta) {
    this.AnalisisPF.historial_credito[tipoCuenta].map( (element) => {
      element.comp_pago = element.id_comportamiento_pago > 0
        ? this.COMPORTAMIENTO_PAGO_CREDITO[element.id_comportamiento_pago - 1].nombre
        : 0;
    });
  }

  // GuardarAnalisis() {
  //   this.pdf = true;
  //   if ( this.actual === this.paginas.length && this.AnalisisPF.puntuacion_colateral ) {
  //     this.agregarComportamientoCuentas('activas');
  //     this.agregarComportamientoCuentas('cerradas');
  //     const t = this;
  //     return this.userService.GuardarAnalisis( {id_solicitud: this.AnalisisPF.id_solicitud, ...this.AnalisisPF} )
  //     .subscribe( ({message}) => {
  //       this.userService.changeStatus(this.folio, 4, 1).subscribe(() => {
  //         t.analisisCompletado = message;
  //         t.enviarEmail();
  //       });
  //     }, (err) => {
  //       console.log('Error: ', err);
  //       this.complete = false;
  //       this.pdf = false;
  //       this.dialog.open(MessageBoxComponent, {
  //         data: {
  //           text: 'Ocurrió un error al guardar el análisis, por favor vuelve a intentarlo.',
  //           text2: err,
  //           type: 'error',
  //           icon: 'error'
  //         }
  //       });
  //     });
  //   } else {
  //     this.submit = true;
  //     this.dialog.open(MessageBoxComponent, {
  //       width: '500px',
  //       data: {
  //         text: 'Por favor llena todos los campos.',
  //         type: 'error',
  //         icon: 'error'
  //       }
  //     });
	//   }
	//    this.pdf = false;
  // }
  formatearCifrasRequest() {
    this.AnalisisPF.monto_arrendarCopy = '$' + this.transformCifras(this.AnalisisPF.monto_arrendar);

    this.AnalisisPF.historial_credito.activasCopy = []
    this.AnalisisPF.historial_credito.cerradasCopy = []
    
    for (let a = 0; a < this.AnalisisPF.historial_credito.activas.length; a++) {
      this.AnalisisPF.historial_credito.activasCopy.push({
        tipo_credito: this.AnalisisPF.historial_credito.activas[a].tipo_credito,
        monto: '$' + this.transformCifras(this.AnalisisPF.historial_credito.activas[a].monto.toString()),
        mop_max: this.transformCifras(this.AnalisisPF.historial_credito.activas[a].mop_max.toString()),
        saldo_insoluto: this.transformCifras(this.AnalisisPF.historial_credito.activas[a].saldo_insoluto.toString()),
        status: this.AnalisisPF.historial_credito.activas[a].status,
        id_comportamiento_pago: this.AnalisisPF.historial_credito.activas[a].id_comportamiento_pago,
        comp_pago: this.AnalisisPF.historial_credito.activas[a].comp_pago,
        puntuacion: this.AnalisisPF.historial_credito.activas[a].puntuacion
      });
    }

    for (let a = 0; a < this.AnalisisPF.historial_credito.cerradas.length; a++) {
      this.AnalisisPF.historial_credito.cerradasCopy.push({
        tipo_credito: this.AnalisisPF.historial_credito.cerradas[a].tipo_credito,
        monto: '$' + this.transformCifras(this.AnalisisPF.historial_credito.cerradas[a].monto.toString()),
        mop_max: this.transformCifras(this.AnalisisPF.historial_credito.cerradas[a].mop_max.toString()),
        saldo_insoluto: this.transformCifras(this.AnalisisPF.historial_credito.cerradas[a].saldo_insoluto),
        status: this.AnalisisPF.historial_credito.cerradas[a].status,
        id_comportamiento_pago: this.AnalisisPF.historial_credito.cerradas[a].id_comportamiento_pago,
        comp_pago: this.AnalisisPF.historial_credito.cerradas[a].comp_pago,
        puntuacion: this.AnalisisPF.historial_credito.cerradas[a].puntuacion
      });
    }
    // for (let a = 0; a < this.AnalisisPF.historial_movimientos.length; a++) {
    //   this.AnalisisPF.historial_movimientos_copy.push({
    //     mes: this.AnalisisPF.historial_movimientos[a].mes,
    //     gastos: '$' + this.transformCifras(this.AnalisisPF.historial_movimientos[a].gastos),
    //     depositos: '$' + this.transformCifras(this.AnalisisPF.historial_movimientos[a].depositos),
    //     saldo_final: '$' + this.transformCifras(this.AnalisisPF.historial_movimientos[a].saldo_final)
    //   });
    // }

    this.AnalisisPF.promedio_depositosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.promedio_depositos));
    this.AnalisisPF.promedio_gastosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.promedio_gastos));
    this.AnalisisPF.promedio_saldo_finalCopy = '$' + this.transformCifras(Number(this.AnalisisPF.promedio_saldo_final));

    this.AnalisisPF.capacidad_pago.ingresos_nominaCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.ingresos_nomina));
    this.AnalisisPF.capacidad_pago.ingresos_extraCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.ingresos_extra));
    this.AnalisisPF.capacidad_pago.ingresos_efectivoCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.ingresos_efectivo));
    this.AnalisisPF.capacidad_pago.comprobantes_ingresosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.comprobantes_ingresos));
    this.AnalisisPF.capacidad_pago.comprobantes_gastosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.comprobantes_gastos));
    this.AnalisisPF.capacidad_pago.efectivo_ingresosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.efectivo_ingresos));
    this.AnalisisPF.capacidad_pago.efectivo_gastosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.efectivo_gastos));
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos));
    this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastosCopy = '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastos));

    this.AnalisisPF.cotizacion.monto_arrendarCopy = '$' + this.transformCifras(Number(this.AnalisisPF.cotizacion.monto_arrendar));
    this.AnalisisPF.cotizacion.rentas_anticipadasCopy = '$' + this.transformCifras(Number(this.AnalisisPF.cotizacion.rentas_anticipadas));
    this.AnalisisPF.colateral.pago_inicialCopy = this.AnalisisPF.colateral.pago_inicial;
    this.AnalisisPF.cotizacion.valor_residualCopy = this.AnalisisPF.cotizacion.valor_residual;
    this.AnalisisPF.cotizacion.mensualidadCopy = '$' + this.transformCifras(Number(this.AnalisisPF.cotizacion.mensualidad));
    this.AnalisisPF.colateral.factorCopy = '$' + this.transformCifras(Number(this.AnalisisPF.colateral.factor));
    this.AnalisisPF.cotizacion.mar_totalCopy = '$' + this.transformCifras(Number(this.AnalisisPF.cotizacion.mar_total));
  }
  enviarEmail() {
    console.log("ENVIAR PDF")
    this.loading = true
    const email = JSON.parse(localStorage.getItem('currentUser')).email;
    const cliente = JSON.parse(localStorage.getItem('client_details'));
    this.formatearCifrasRequest();
    const request = {
      email,
      opcion: 5,
      filename: 'analisis.pdf',
      subjec: 'Análisis',
      type: 19,
      params: {
        datosGral: {
          fecha_ingreso: this.AnalisisPF.fecha_creacion,
          tipo_comite: this.AnalisisPF.tipo_comite,
          numero_comite: this.AnalisisPF.numero_comite,
          fecha_cotizacion: this.AnalisisPF.cotizacion.fecha_cotizacion,
          cotizacion: this.AnalisisPF.cotizacion.cotizacion,
          nombre_cliente: this.AnalisisPF.solicitante,
          actividad_economica: this.AnalisisPF.actividad_economica,
          monto_arrendar: this.AnalisisPF.cotizacion.monto_arrendarCopy,
          activo_arrendar: this.AnalisisPF.caracteristica_activo.descripcion_activo,
          tipo_activo_arr: this.AnalisisPF.caracteristica_activo.tipo_activo_arrendar,
          ubicacion: this.AnalisisPF.caracteristica_activo.domicilio,
          inicio_op: this.AnalisisPF.inicio_operaciones,
          cliente_LFU: this.AnalisisPF.cliente_l4u ? 'Sí' : 'No',
          comportamiento_pago: this.AnalisisPF.cliente_l4u ? this.COMPORTAMIENTO_PAGO[this.AnalisisPF.id_comportamiento_pago - 1].nombre : '-',
          puntuacion_datos: this.AnalisisPF.caracter.puntuacion_comportamiento_pago
        },
        datosCaracter: {
          sol_bcscore: this.AnalisisPF.perfil_solicitante.bc_score,
          sol_icc: this.AnalisisPF.perfil_solicitante.icc,
          sol_dom: this.AnalisisPF.perfil_solicitante.arraigo_domicilio,
          sol_empleo: this.AnalisisPF.perfil_solicitante.arraigo_empleo,
          sol_espec: this.AnalisisPF.perfil_solicitante.consejo_especialidad,
          desc_sol_bcscore: this.AnalisisPF.perfil_solicitante.bc_score_descrip,
          desc_sol_icc: this.AnalisisPF.perfil_solicitante.icc_descrip,
          desc_sol_dom: this.AnalisisPF.perfil_solicitante.arraigo_domicilio_descrip,
          desc_sol_empleo: this.AnalisisPF.perfil_solicitante.arraigo_empleo_descrip,
          desc_sol_espec: this.AnalisisPF.perfil_solicitante.consejo_especialidad_descrip,
          punt_sol_bcscore: this.AnalisisPF.perfil_solicitante.bc_score_punt,
          punt_sol_icc: this.AnalisisPF.perfil_solicitante.icc_punt,
          punt_sol_dom: this.AnalisisPF.perfil_solicitante.arraigo_domicilio_punt,
          punt_sol_empleo: this.AnalisisPF.perfil_solicitante.arraigo_empleo_punt,
          punt_sol_espec: this.AnalisisPF.perfil_solicitante.consejo_especialidad_punt,
          total_punt_sol: this.AnalisisPF.perfil_solicitante.total,
          nombre_aval: this.AnalisisPF.perfil_aval.nombre,
          aval_bcscore: this.AnalisisPF.perfil_aval.bc_score,
          aval_icc: this.AnalisisPF.perfil_aval.icc,
          aval_doc: this.AnalisisPF.perfil_aval.documentacion,
          aval_parent: this.AnalisisPF.aval.aval_parentezco,
          aval_ocup: this.AnalisisPF.aval.aval_ocupacion,
          desc_av_bcscore: this.AnalisisPF.perfil_aval.bc_score_descrip,
          desc_av_icc: this.AnalisisPF.perfil_aval.icc_descrip,
          desc_av_doc: this.AnalisisPF.perfil_aval.documentacion_descrip,
          desc_av_parent: this.AnalisisPF.perfil_aval.parentezco_descrip,
          desc_av_ocup: this.AnalisisPF.perfil_aval.ocupacion_descrip,
          punt_av_bcscore: this.AnalisisPF.perfil_aval.bc_score_punt,
          punt_av_icc: this.AnalisisPF.perfil_aval.icc_punt,
          punt_av_doc: this.AnalisisPF.perfil_aval.documentacion_punt,
          punt_av_parent: this.AnalisisPF.perfil_aval.parentezco_punt,
          punt_av_ocup: this.AnalisisPF.perfil_aval.ocupacion_punt,
          total_punt_av: this.AnalisisPF.perfil_aval.total,
          cuentasActivas: this.AnalisisPF.historial_credito.activasCopy,
          cuentasCerradas: this.AnalisisPF.historial_credito.cerradasCopy,
          punt_cuentas_activas: this.AnalisisPF.puntos_credito_abierto,
          punt_cuentas_cerradas: this.AnalisisPF.puntos_credito_cerradas,
          egresado_escuela: this.AnalisisPF.nombre_escuela_egresado,
          especialidad: this.AnalisisPF.especialidad,
          residencia: this.AnalisisPF.residencia_escuela,
          puntuacion_caracter: this.AnalisisPF.puntuacion_caracter,
          comentarios_credito: this.AnalisisPF.comentarios_caracter
        },
        datosCapacidad: {
          saldo_inicial: this.AnalisisPF.saldo_inicial,
          mesesInfo: this.AnalisisPF.historial_movimientos_copy,
          promedio_depositos: this.AnalisisPF.promedio_depositosCopy,
          promedio_gastos: this.AnalisisPF.promedio_gastosCopy,
          promedio_saldo_final: this.AnalisisPF.promedio_saldo_finalCopy,
          ingresos_nomina: this.AnalisisPF.ingresos_nominaCopy,
          ingresos_extra: this.AnalisisPF.capacidad_pago.ingresos_extraCopy,
          ingresos_efectivo: this.AnalisisPF.capacidad_pago.ingresos_efectivoCopy,
          comprobantes_ingresos: this.AnalisisPF.capacidad_pago.comprobantes_ingresosCopy,
          comprobantes_gastos: this.AnalisisPF.capacidad_pago.comprobantes_gastosCopy,
          comprobantes_factor: this.AnalisisPF.capacidad_pago.comprobantes_factor,
          efectivo_ingresos: this.AnalisisPF.capacidad_pago.efectivo_ingresosCopy,
          efectivo_gastos: this.AnalisisPF.capacidad_pago.efectivo_gastosCopy,
          efectivo_factor: this.AnalisisPF.capacidad_pago.efectivo_factor,
          extra_nuevo_equipo_ingresos: this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresosCopy,
          extra_nuevo_equipo_gastos: this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_gastosCopy,
          extra_nuevo_equipo_factor: this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_factor,
          maximo_factor: this.AnalisisPF.capacidad_pago.maximo_factor,
          factor_renta: this.AnalisisPF.capacidad_pago.factor_renta,
          puntuacion_capacidad: this.AnalisisPF.capacidad_pago.puntuacion_capacidad_pago,
          comentarios_capacidad: this.AnalisisPF.capacidad_pago.comentarios_capacidad_pago,
          renta_adicional_disponible: '$' + this.transformCifras(Number(this.AnalisisPF.capacidad_pago.renta_adicional_disponible))
        },
        datosColateral: {
          activo_arrendar: this.AnalisisPF.caracteristica_activo.descripcion_activo,
          monto_arrendar: this.AnalisisPF.cotizacion.monto_arrendarCopy,
          tipo_vendor: this.AnalisisPF.cotizacion.tipo_vendor,
          vendor: this.AnalisisPF.cotizacion.vendor,
          comercial: this.AnalisisPF.cotizacion.comercial,
          marca: this.AnalisisPF.cotizacion.marca,
          plazo: this.AnalisisPF.cotizacion.plazo,
          factor_cotizacion: this.transformCifras(Number(this.AnalisisPF.cotizacion.factor_porcentaje)),
          garantia: this.AnalisisPF.cotizacion.garantia,
          tiempo_entrega: this.TIEMPO_ENTREGA[this.AnalisisPF.cotizacion.tiempo_entrega].tiempo,
          rentas_anticipadas: this.AnalisisPF.cotizacion.rentas_anticipadas,
          total_pago_inicial: this.AnalisisPF.colateral.pago_inicialCopy,
          valor_residual: this.AnalisisPF.cotizacion.valor_residualCopy,
          mensualidad:  this.AnalisisPF.cotizacion.mensualidadCopy,
          valor_futuro: this.AnalisisPF.colateral.factorCopy,
          mar_total: this.AnalisisPF.cotizacion.mar_totalCopy,
          vida_util: this.AnalisisPF.caracteristica_activo.vida_util,
          vida_util_pts: this.AnalisisPF.caracteristica_activo.vida_util_pts,
          mercado_secundario: this.AnalisisPF.caracteristica_activo.mercado_secundario,
          mercado_secundario_pts: this.AnalisisPF.caracteristica_activo.mercado_secundario_pts,
          estimado_de_colocacion: this.AnalisisPF.caracteristica_activo.estimado_de_colocacion,
          estimado_de_colocacion_pts: this.AnalisisPF.caracteristica_activo.estimado_de_colocacion_pts,
          ubicacion: this.ubicacionActivoCopy,
          ubicacion_pts: this.AnalisisPF.caracteristica_activo.ubicacion_pts,
          tipo_financiamiento: this.AnalisisPF.caracteristica_activo.tipo_financiamient,
          tipo_financiamiento_pts: this.AnalisisPF.caracteristica_activo.tipo_financiamiento_pts,
          puntos_equipo: this.AnalisisPF.colateral.puntuacion_colateral,
          puntacion_colateral: this.AnalisisPF.puntuacion_total,
          comentarios_colateral: this.AnalisisPF.colateral.comentarios_colateral
        },infoSolicitante:{
          telefon_casa:this.AnalisisPF.tel_casa,
          renta_mensual:'$' + this.transformCifras(Number( this.AnalisisPF.entrevista_general.mmr)),
          dependientes_economicos:this.AnalisisPF.entrevista_general.dependientes,
          telefono_movil: this.AnalisisPF.tel_cel,
          egreso_mensual_dependientes:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_general.emd)),
          gatos_buro:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_general.gastos_buro)),
          rfc:this.AnalisisPF.rfc,
          consejo_especialidad:this.AnalisisPF.entrevista_general.consejo_especialidad ? 'Sí' : 'No',
          domicilio_particular:this.AnalisisPF.entrevista_general.domicilio,
          domicilio_porpio:this.AnalisisPF.entrevista_general.domicilio_propio ? 'Sí': 'No',
          antiguedad:this.AnalisisPF.entrevista_general.domicilio_antiguedad
      },datosActividadEconomica:{
          sector_publico:this.AnalisisPF.entrevista_economica.sector_publico ? 'Sí':'No',
          hospital_clinica:this.AnalisisPF.entrevista_economica.hospital_clinica,
          puesto:this.AnalisisPF.entrevista_economica.puesto,
          tipo_contrato:this.AnalisisPF.entrevista_economica.tipo_contrato,
          antiguedad:this.AnalisisPF.entrevista_economica.puesto_antiguedad,
          ingreso_mensual:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_economica.ingreso_neto_mensual)),
          ubicacion:this.AnalisisPF.entrevista_economica.puesto_domicilio
      },consultorio:{
          propio:this.AnalisisPF.entrevista_consultorio.propio ? 'Sí':'No',
          antiguedad:this.AnalisisPF.entrevista_consultorio.antiguedad,
          monto_renta:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.monto_renta)),
          telefono:this.AnalisisPF.entrevista_consultorio.telefono,
          ubicacion:this.AnalisisPF.entrevista_consultorio.domicilio,
          pacientes:{
            numero:this.AnalisisPF.entrevista_consultorio.pacientes_por_mes,
            precio_consulta:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.precio_consulta)),
            ingreso_aprox:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_pacientes))
          },
          estudios:{
            numero:this.AnalisisPF.entrevista_consultorio.estudios_por_mes,
            precio:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.precio_estudio)),
            ingreso_aprox:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_estudios))
          },
          cirugias:{
            numero:this.AnalisisPF.entrevista_consultorio.cirugias_por_mes,
            precion:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.precio_cirugia)),
            ingreso:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_cirugias))
          },
          ingresos_efectivo:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_efectivo)),
          ingreso_mensual_aprox:this.AnalisisPF.entrevista_consultorio.ingresos_aprox_mensuales,
          equipo:{
            tipo_equipo:this.AnalisisPF.entrevista_consultorio.tipo_equipo,
            tipo_estudios:this.AnalisisPF.entrevista_consultorio.tipo_estudios,
            ingresos_extras:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_extras_equipo))
          },
          equipo_estudios:{
            nomero:this.AnalisisPF.entrevista_consultorio.estudios_por_mes_equipo,
            precio:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.precio_estudio_equipo)),
            ingreso_aprox:'$' + this.transformCifras(Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_estudios_equipo))
          }
      },aval:{
          nombre:this.AnalisisPF.aval.aval_nombre,
          ocupacion:this.AnalisisPF.aval.aval_ocupacion,
          tiempo_conocerlo:this.AnalisisPF.aval.tiempo_conocerlo,
          parentesco:this.AnalisisPF.aval.aval_parentezco,
          telefono:this.AnalisisPF.aval.aval_telefono,
          direccion:this.AnalisisPF.aval.aval_domicilio,
          lorecomienda:this.AnalisisPF.aval.lo_recomienda ? 'Sí': 'No'
      },referencias:
          [{
            contenido: this.AnalisisPF.referencias[0].nombre.trim().length > 0,
            nombre:this.AnalisisPF.referencias[0].nombre,
            ocupacion:this.AnalisisPF.referencias[0].ocupacion,
            direccion:this.AnalisisPF.referencias[0].domicilio,
            telefono:this.AnalisisPF.referencias[0].telefono,
            tiempo_conocerlo:this.AnalisisPF.referencias[0].tiempo_conocerlo,
            parentesco:this.AnalisisPF.referencias[0].parentezco,
            lorecomienda: this.AnalisisPF.referencias[0].lo_recomienda1 ? 'Sí' : 'No'
          },
          {
            contenido: this.AnalisisPF.referencias[1].nombre.trim().length > 0,
            nombre:this.AnalisisPF.referencias[1].nombre,
            ocupacion:this.AnalisisPF.referencias[1].ocupacion,
            direccion:this.AnalisisPF.referencias[1].domicilio,
            telefono:this.AnalisisPF.referencias[1].telefono,
            tiempo_conocerlo:this.AnalisisPF.referencias[1].tiempo_conocerlo,
            parentesco:this.AnalisisPF.referencias[1].parentezco,
            lorecomienda: this.AnalisisPF.referencias[1].lo_recomienda2 ? 'Sí' : 'No'
          },
          {
            contenido: this.AnalisisPF.referencias[2].nombre.trim().length > 0,
            nombre:this.AnalisisPF.referencias[2].nombre,
            ocupacion:this.AnalisisPF.referencias[2].ocupacion,
            direccion:this.AnalisisPF.referencias[2].domicilio,
            telefono:this.AnalisisPF.referencias[2].telefono,
            tiempo_conocerlo:this.AnalisisPF.referencias[2].tiempo_conocerlo,
            parentesco:this.AnalisisPF.referencias[2].parentezco,
            lorecomienda: this.AnalisisPF.referencias[2].lo_recomienda3 ? 'Sí' : 'No'
          }]

            ,
        nombreSolicitante: cliente.cliente,
        activoArrendar: cliente.activo_nombre,
        folio: cliente.folio,
        vendor: cliente.vendor.toUpperCase(),
      }
    };
    console.log("PDF", request)

    this.solicitudService.sendMailCommentaries(request)
    .subscribe( (res: any) => {
      console.log('res: ', res);
      const file = new Blob([res], {type: 'application/pdf'});
      saveAs(file, request.filename);
      this.AnalisisPF.historial_credito.activasCopy = []
      this.AnalisisPF.historial_credito.cerradasCopy = []

      this.complete = true;
      this.loading = false
      const dialogref = this.dialog.open(MessageBoxComponent, {
        width: '500px',
        data: {
          text: 'Éxito, se ha guardado la información.',
          text3: 'Revisa tu correo para descargar el documento',
          type: 'success',
          icon: 'success'
        }
      });
    }, (err) => {
      console.log('error: ', err);
      this.loading = false
      this.dialog.open(MessageBoxComponent, {
        data: {
          text: 'El servicio no está disponible.',
          text3: 'Vuelve a intentarlo',
          type: 'error',
          icon: 'error'
        }
      });
    });
  }

  AlmacenarLocalStorage( parte ) {
    const persistence: any = JSON.parse(localStorage.getItem('actualizar_informacion')) || {};
    persistence.DatosAnalisis = this.AnalisisPF;
    persistence.Analisis = this.AnalisisPF;
    persistence.parte = this.actual;

    localStorage.setItem('actualizar_informacion', JSON.stringify(persistence));
  }

  InfoBuro() {
    this.dialog.open(InfoComponent, {
      width: '400px',
      data: {
        text: `Consulta a buró realizada el: ${new Date(this.AnalisisPF.ultima_consulta_buro).toLocaleDateString()}`
      }
    });
  }
  transformCifras(num) {
    return this.decimalPipe.transform(num, '1.2-2');
  }

  ObtenerNombre(isAval = false) {
    const {aval, contacto, direccion} = this.solicitud

    this.contactoSolicitante = {...contacto, direccion}
    this.contactoAval = aval

    if (isAval) {
      return aval ? `${aval.nombreCompleto} ${aval.apellidoPaterno} ${aval.apellidoMaterno}`.trim().toUpperCase() : ''
    }
    return contacto ? `${contacto.nombreCompleto} ${contacto.apellidoPaterno} ${contacto.apellidoMaterno}`.trim().toUpperCase() : ''
  }

  Editar(contacto, aval = false) {
    console.log({contacto});
    console.log(`Aval? ${aval}`);
    const direccion = contacto.direccion
    delete contacto.direccion
    this.dialog.open(EditarContactoComponent, {
      width: '640px',
      data: {
        folio: this.folio,
        contacto,
        direccion,
        aval
      }
    }).afterClosed().subscribe(res => {
      this.onUpdate.emit(true)
    })
  }

  Precalificar(contacto) {
    console.log('PRECALIFICAR');
    console.log({contacto});
    
    const dialogRef = this.dialog.open(PrecalificarContactoComponent,{
      width: '640px',
      data: {
        folio: this.folio,
        contacto
      }
    }).afterClosed().subscribe(res => {
      console.log('closed');
      this.onUpdate.emit(true)
      this.CambiarPuntuacionAval()
    })
  }

  valorUbicacion(type){
    console.log("Ubicacion:", type)
    switch (type){
      case '2':
        this.ubicacionActivoCopy = "Consultorio, clínica, hospital o gabinete";
        this.AnalisisPF.caracteristica_activo.ubicacion_pts = 5;
        break;
      case '3':
        this.ubicacionActivoCopy = "Portátil";
        this.AnalisisPF.caracteristica_activo.ubicacion_pts = 3;
        break;
      case '4':
        this.ubicacionActivoCopy = "Automóvil";
        this.AnalisisPF.caracteristica_activo.ubicacion_pts = 5;
        break;
      case '5':
        this.ubicacionActivoCopy = "Equipo de Cómputo";
        this.AnalisisPF.caracteristica_activo.ubicacion_pts = 5;
        break;
      default:
        this.AnalisisPF.caracteristica_activo.ubicacion_pts = 0;
        break; 
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
  calcularRentaAdicionalDisponible(){
    if (this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos!=0){
      const buscarObjetivo = this.analisisCredito.buscarObjetivoG53(0.65,0,0.00001,Number(this.AnalisisPF.capacidad_pago.efectivo_gastos),Number(this.AnalisisPF.capacidad_pago.extra_nuevo_equipo_ingresos));
      this.AnalisisPF.capacidad_pago.renta_adicional_disponible =(buscarObjetivo-Number(this.AnalisisPF.cotizacion.mensualidad)).toFixed(2);
    }
  }
}
