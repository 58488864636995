import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ModalPoliticasComponent } from "src/app/_dialogs/modal-politicas/modal-politicas.component";
import { BuroService, SolicitudService } from "src/app/_services";
import { MejorarCotizacionService } from "src/app/_services/mejorar-cotizacion.service";

@Component({
  selector: "app-pre-calificar",
  templateUrl: "./pre-calificar.component.html",
  styleUrls: ["./pre-calificar.component.scss"],
})
export class PreCalificarComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  @Output() siguiente = new EventEmitter();
  @Output() anterior = new EventEmitter();

  code = "";
  codeValido = "";
  isValid = false;
  invalidCode = false;
  loading = false;

  frmPhone = this.formBuilder.group({
    phone: [
      "",
      [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
    ],
    phone_code: ['', Validators.required],

  });
  initialTime = 30;
  instructions =
    "Por favor, ingrese su número de teléfono móvil. Recibirá un código de verificación para acceder al Buró de Crédito una vez que lo ingrese correctamente.";
  messageError = false;
  validForm = false;

  constructor(
    private mejorarCotizacion: MejorarCotizacionService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private buroService: BuroService,
    private solicitudService: SolicitudService,

  ) {}

  ngOnInit(): void {
    if(this.data.phone){
      this.frmPhone.patchValue({phone: this.data.phone})
    }
    this.frmPhone.valueChanges.subscribe(() => {
      this.validForm = this.frmPhone.valid;
    });
  }
  getErrorMessage() {
    const phoneFormControl = this.frmPhone.get("phone");

    if (phoneFormControl && phoneFormControl.hasError("required")) {
      return "El teléfono es requerido";
    }
    if (
      (phoneFormControl && phoneFormControl.hasError("minlength")) ||
      (phoneFormControl && phoneFormControl.hasError("maxlength"))
    ) {
      return "Ingresa un teléfono válido";
    }
    return "";
  }

  onFullFill(dataCode: any) {
    if (dataCode.length === 4) {
      this.code = dataCode;
      this.frmPhone.get("phone_code").setValue(dataCode);
      this.isValid = true;
      this.invalidCode = false;

      this.data = { ...this.data, code_phone: this.code, phone: this.frmPhone.get("phone").value };
      this.datosActualizados.emit(this.data);
    }
  }
  async SolicitarPinPhone() {
    const phoneFormControl = this.frmPhone.get("phone");

    if (phoneFormControl.value != "") {
      const phone = {
        phone: phoneFormControl.value,
      };
      try {
        const response = await this.mejorarCotizacion.EnviarPinPhone(phone);
        this._snackBar.open("Se ha enviado correctamente el código", "Ok", {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["success-snackbar"],
        });
      } catch (err) {
        this._snackBar.open(
          "Ha ocurrido un error, por favor revisa que tu teléfono sea el correcto. ",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
      }
    } else {
      this.messageError = true;
    }
  }
  openPolitics() {
    this.dialog.open(ModalPoliticasComponent, {
      width: "500px",
      height: "500px",
    });
  }
  atras() {
    this.datosActualizados.emit(this.data);
    this.anterior.emit();
  }
   formatFecha(fecha) {
    let parts = fecha.split(/[-\/]/);

    if (parts[0].length === 4) {
        fecha = `${parts[2]}/${parts[1]}/${parts[0]}`;
    } else {
        fecha = `${parts[0]}/${parts[1]}/${parts[2]}`;
    }

    return fecha.replace(/[/-]/g, '');
}
  async enviarDatos() {
    this.loading = true
    this.datosActualizados.emit(this.data);
    const body = {
      "ApellidoPaterno": this.data.first_lastname,
      "ApellidoMaterno": this.data.second_lastname,
      "PrimerNombre": this.data.first_name,
      "SegundoNombre": this.data.second_name || " ",
      "FechaNacimiento": this.formatFecha(this.data.birthday),
      "RazonSocial": "",
      "RFC": this.data.rfc,
      "Nacionalidad": "MX",
      // "Sexo": genero.value,
      "correo": this.data.email,
      "email": this.data.email,
      "id_responsable": 79,
      "tipo_persona": 1,
      "Domicilio": {
        "Direccion1": this.data.direccion.street,
        "ColoniaPoblacion": this.data.direccion.suburb,
        "DelegacionMunicipio": this.data.direccion.town_hall,
        "Ciudad": this.data.direccion.city
        .normalize("NFD") 
        .replace(/[\u0300-\u036f]/g, "") 
        .toUpperCase(),
        "Estado": this.data.direccion.state,
        "CP": this.data.direccion.zip,
        "CodPais": "MX",
        "Numero": this.data.direccion.number
      },
      "Authorization": {"type": 2, "target": this.data.phone, "code": this.data.code_phone},
      "ine_auth": true
    };
    const resBuro = (await this.buroService.consultaBuroConAutorizacion(body)).subscribe(async(res) =>{
      if(res.score){
        this.loading = false
        const infoComplementaria = {
          telCel: this.frmPhone.get("phone").value
        }
  
        const resGuardarSolicitud = await this.solicitudService.GuardarSolicitud(this.data.folio, {infoComplementaria})
        if(resGuardarSolicitud){
          this.siguiente.emit();
        }
      }else{
        //agregar error
        this._snackBar.open(
          "Ha ocurrido un error, por favor revisa que el código sea el correcto. ",
          "Ok",
          {
            duration: 10000,
            horizontalPosition: "center",
            verticalPosition: "top",
            panelClass: ["warning-snackbar"],
          }
        );
        this.loading = false

        // this.siguiente.emit()
      }
    })
   
    // this.siguiente.emit();
  }
}
