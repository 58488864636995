import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EntrevistaDatosGenerales, EntrevistaActEconomica, EntrevistaConsultorio, EntrevistaAval, EntrevistaReferencias } from './Entrevista';
import { UserService, SolicitudService, AuthenticationService } from 'src/app/_services';

import { customPatterns } from '../../../_models/maskPattern'
import { Analisis } from '../analisis/Analisis';
import { AnalisisPF } from '../analisis/AnalisisPF';
import { IndicarDireccionComponent } from 'src/app/_dialogs/indicar-direccion/indicar-direccion.component';
import { EditarContactoComponent } from 'src/app/_dialogs/editar-contacto/editar-contacto.component';
@Component({
  selector: 'app-entrevista',
  templateUrl: './entrevista.component.html',
  styleUrls: ['./entrevista.component.scss']
})
export class EntrevistaComponent implements OnInit {
  @Input() solicitud: any;  
  @Input() analisisPf: any;  
  @Output() analisisPfChange = new EventEmitter<any>();
  @Output() onUpdate = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.analisisPf && changes.analisisPf.currentValue) {
      console.log("Nuevo valor de analisisPf ENTREVISTA:", this.analisisPf);
      this.AnalisisPF = { ...this.analisisPf };
      // console.log("Nuevo valor de analisisPf ENTREVISTA:", this.AnalisisPF);
      
    }
  }

  Referencias = EntrevistaReferencias
  online = false
  customPattern = customPatterns
  AnalisisPF: any = {};


  paginas = [
    {name: 'Datos generales', short_name: 'Datos'},
    {name: 'Actividad económica', short_name: 'actividad'},
    {name: 'Consultorio', short_name: 'Consultorio'},
    {name: 'Aval', short_name: 'Aval'},
    {name: 'Referencias', short_name: 'Referencias'}
  ]

  EntrevistaDatos:any = {}
  actual = 1
  folio = null
  id_cliente = null
  submit = false
  referencias_error = true
  loading = false

  tipo_persona = 0

  DatosGeneralesTres: any = {}
  DatosGeneralesDos: any = {}

  constructor(private dialog: MatDialog,
    private router:Router,
    private userService: UserService,
    private solicitudService:SolicitudService,
    private authenticationService:AuthenticationService ) { }
  

    async ngOnInit() {
      const { tipo_persona, folio} = JSON.parse( localStorage.getItem('client_details'))
      this.tipo_persona = tipo_persona
      this.folio = folio
      
      // this.analisisPf = {
      //   ...AnalisisPF
      // }

      // this.AnalisisPF = JSON.parse(JSON.stringify(AnalisisPF));


   
    }
    actualizarAnalisisPf(nuevoValor: any) {
      this.analisisPf = nuevoValor;
    }


 
    async NextPage() {   
      console.log("GUARDAR ENTREVISTA");
      this.loading = true;
    
      if (!this.userService.alertService.isOnline()) {
        this.loading = false;
        return;
      }
    
      try {
        await this.GuardarReferencias();
        
        // const Entrevista = {
        //   folio: this.folio,
        //   id_cliente: this.id_cliente,
        //   tipo_persona: this.tipo_persona,
        //   datos_generales: null,
        //   act_economica: this.AnalisisPF.entrevista_economica,
        //   consultorio: this.AnalisisPF.entrevista_consultorio,
        //   aval: this.AnalisisPF.aval,
        //   referencias: this.Referencias 
        // };
    
        // const entrevistaRes = await this.userService.GuardarEntrevista(Entrevista).toPromise();
        // console.log("Entrevista guardada: ", entrevistaRes);
    
        const resGuardado: any = await this.solicitudService.GuardarAnalisisCredito(this.AnalisisPF);
        if (resGuardado.statusCode === 200) {
          this.loading = false;
          this.analisisPfChange.emit(this.AnalisisPF);
          console.log("ENVIA", this.AnalisisPF)
          window.scroll(0, 0);
          this.dialog.open(MessageBoxComponent, {
            width: '500px',
            data: {
              text: 'Éxito',
              text2: 'Se han guardado la Entrevista',
              // text3: 'Revisa tu correo para descargar el documento',
              type: 'success',
              icon: 'success'
            }
          }).afterClosed().subscribe(async ()=>{
            await this.userService.changeStatus(this.folio, 1, 3).toPromise()
          })
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.error('Error:', error);
        this.loading = false;
        this.dialog.open(MessageBoxComponent, {
          width: '500px',
          data: {
            text: "El servicio no está disponible por el momento",
            text3: "Vuelve a intentarlo.",
            type: "error",
            icon: "error"
          }
        });
      }
    }
    

  isValidReferencias() {
    // for(let i=0; i < this.Referencias.length; i++){
      if (this.Referencias[0].nombre &&
            this.Referencias[0].parentezco &&
            this.Referencias[0].ocupacion &&
            this.Referencias[0].domicilio &&
            this.Referencias[0].telefono &&
            this.Referencias[0].tiempo_conocerlo &&
            this.Referencias[0].lo_recomienda){
              this.referencias_error = false
              return true
            }
    // }
    // if( !(this.Referencias[0].nombre &&
      //     this.Referencias[0].parentezco &&
      //     this.Referencias[0].ocupacion &&
      //     this.Referencias[0].domicilio &&
      //     this.Referencias[0].telefono && 
      //     this.Referencias[0].tiempo_conocerlo &&
      //     this.Referencias[0].lo_recomienda) ||

      //     !(this.Referencias[1].nombre &&
      //     this.Referencias[1].parentezco &&
      //     this.Referencias[1].ocupacion &&
      //     this.Referencias[1].domicilio &&
      //     this.Referencias[1].telefono &&
      //     this.Referencias[1].tiempo_conocerlo &&
      //     this.Referencias[1].lo_recomienda) ||

      //     !(this.Referencias[2].nombre &&
      //     this.Referencias[2].parentezco &&
      //     this.Referencias[2].ocupacion &&
      //     this.Referencias[2].domicilio &&
      //     this.Referencias[2].telefono &&
      //     this.Referencias[2].tiempo_conocerlo ||
      //     this.Referencias[2].lo_recomienda)
      //   ) return false
    this.referencias_error = true
    return false
  }


  CambiarAproximado(total_aprox, por_mes, precio){
    
    this.AnalisisPF.entrevista_consultorio[total_aprox] = (this.AnalisisPF.entrevista_consultorio[por_mes] * this.AnalisisPF.entrevista_consultorio[precio]).toFixed(2)

    this.AnalisisPF.entrevista_consultorio.ingresos_aprox_mensuales = (Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_pacientes) + Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_estudios) + Number(this.AnalisisPF.entrevista_consultorio.ingresos_aprox_cirugias)).toFixed(2)
  }

  async GuardarDatosGeneralesTres() {
    const client_details = JSON.parse(localStorage.getItem('client_details'));
    localStorage.setItem('update', 'true')
    this.DatosGeneralesTres.pertenece = `${this.AnalisisPF.entrevista_general.consejo_especialidad}`
    await this.solicitudService.saveDatosGenerales(this.DatosGeneralesTres, 3, client_details.tipo_persona, 2)
    .then( res => {
      console.log('Actualizado: ', res)
      localStorage.removeItem('update');
      localStorage.removeItem('solicitud');
    })
  }

  async GuardarDatosGeneralesDos() {
    const client_details = JSON.parse(localStorage.getItem('client_details'));
    localStorage.setItem('update', 'true')
    this.DatosGeneralesDos.pago_mensual = Number(this.AnalisisPF.entrevista_general.mmr) || 0.001;
    // this.DatosGeneralesDos.tipo_vivienda = this.frmDatosGenerales.controls['domicilio_propio'].value ? 'PROPIA' : 'FAMILIAR';
    this.DatosGeneralesDos.tiempo_residencia = this.AnalisisPF.entrevista_general.domicilio_antiguedad
    await this.solicitudService.saveDatosGenerales(this.DatosGeneralesDos, 2, client_details.tipo_persona, 1)
    .then( res => {
      console.log('Actualizado: ', res)
      localStorage.removeItem('update');
      localStorage.removeItem('solicitud');
    })
  }
   convertToFormData(referencias) {
    return {
      rp_nombre1: referencias[0].nombre,
      rp_nombre2: referencias[1].nombre,
      rp_nombre3: referencias[2].nombre,
      ocupacion1: referencias[0].ocupacion,
      ocupacion2: referencias[1].ocupacion,
      ocupacion3: referencias[2].ocupacion,
      rp_direccion1: referencias[0].domicilio,
      rp_direccion2: referencias[1].domicilio,
      rp_direccion3: referencias[2].domicilio,
      rp_telefono1: referencias[0].telefono,
      rp_telefono2: referencias[1].telefono,
      rp_telefono3: referencias[2].telefono,
      rp_parentesco1: referencias[0].parentezco,
      rp_parentesco2: referencias[1].parentezco,
      rp_parentesco3: referencias[2].parentezco
    };
  }
  

  async GuardarReferencias() {
    // const data = this.convertToFormData(this.EntrevistaReferencias);
    const referencias = this.AnalisisPF.referencias.map((ref, i) => ({
      [`rp_nombre${i + 1}`]: ref.nombre,
      [`rp_direccion${i + 1}`]: ref.domicilio,
      [`rp_telefono${i + 1}`]: ref.telefono,
      [`rp_parentesco${i + 1}`]: ref.parentezco,
      [`lo_recomienda${i + 1}`]: ref[`lo_recomienda${i + 1}`],
      [`ocupacion${i + 1}`]: ref.ocupacion,
      [`rp_tiempo_conocerlo${i + 1}`]: ref.tiempo_conocerlo
    }));
  
    const data = {
      folio: this.folio,
      cliente: this.id_cliente,
      tipo_persona: this.tipo_persona,
      referencias
    };
    localStorage.setItem('update', 'true')
    await this.solicitudService.saveReferencias(data, 2, this.tipo_persona,  2).subscribe(
      async (res: any) => {
        console.log(res);
        localStorage.removeItem('update')
      },
      (err: any) => {
        console.error(err);
        localStorage.removeItem('update')
      }
    );
  }
  getLoRecomienda(ref: any, index: number): boolean {
    return ref[`lo_recomienda${index + 1}`];
  }
  
  setLoRecomienda(ref: any, index: number, value: boolean): void {
    ref[`lo_recomienda${index + 1}`] = value;
  }
  

  // validarActividad() {
  //   if (this.frmActEconomica.controls['sector_publico'].value) {
  //     return this.frmActEconomica.valid
  //   }
  //   return true
  // }

  EditarContacto(contacto, aval = false) {
    console.log({contacto});
    console.log(`Aval? ${aval}`);
    const direccion = contacto.direccion
    delete contacto.direccion
    this.dialog.open(EditarContactoComponent, {
      width: '640px',
      data: {
        folio: this.folio,
        contacto,
        direccion,
        aval
      }
    }).afterClosed().subscribe(res => {
      console.log({res});
      
    })
  }

  CambiarDireccionActivo() {
    console.log('CambiarDireccionActivo');
    this.dialog.open(IndicarDireccionComponent,{
      width: '580px',
      data: {
        folio: this.folio
      }
    }).afterClosed().subscribe((res) => {
      console.log({res});
      this.AnalisisPF.entrevista_consultorio.domicilio = res.direccionCompleta
    })
  }

  EditarArrendatario(_aval = false) {
    const {aval, contacto, direccion} = this.solicitud

    const contactoPF = {...contacto, direccion}
    console.log({contactoPF, direccion});
    const direccionPF = contactoPF.direccion
    delete contactoPF.direccion
    this.dialog.open(EditarContactoComponent, {
      width: '640px',
      data: {
        folio: this.folio,
        contacto: _aval ? aval : contactoPF,
        direccion: _aval ? aval.direccion : direccionPF,
        aval: _aval
      }
    }).afterClosed().subscribe(res => {
      if(_aval){
        console.log('==========',res.aval.direccion.direccionCompleta);
        this.AnalisisPF.aval.aval_domicilio=res.aval.direccion.direccionCompleta;
      }else{
        console.log('Valor devuelto :==========',res.direccion.direccionCompleta)
      this.AnalisisPF.entrevista_general.domicilio=res.direccion.direccionCompleta;
      }
      this.onUpdate.emit(true)
    })
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
