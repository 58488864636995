<mat-drawer-container class="menu" [hasBackdrop]="true">
    <mat-drawer *ngIf="filterType" #drawerFilter mode="over" position="end">
        <div class="filterSide">
            <div style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
                <h3 style="flex-grow: 1;">Filtro</h3>
                <mat-icon (click)="ShowHideFilter()">X</mat-icon>
            </div>
            <div class="filterContent" [ngSwitch]="filterType">
                <app-cartera-filter *ngSwitchCase="'cartera'" (onFilter)="setFilters($event)" [setFilter]="filtros"></app-cartera-filter>
            </div>
            <button class="btn-primary-new" (click)="AplicarFiltro()">Aplicar</button>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="main-content">
            <header>
                <div class="logo">
                    <img src="/assets/icons/menu.png" alt="" style="width: 20px; height: 20px;" (click)="shortMenu = !shortMenu">
                    <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/NewLogo-L4U.png" alt="Lease for U" [routerLink]="['/dashboard']" style="height: 42px;">
                </div>
                <div class="search-bar" [ngClass]="{buscador: buscador}">
                    <div class="input-search">
                        <img src="/assets/icons/search.png" alt="">
                        <input type="text" [placeholder]="placeholderSearch" [(ngModel)]="filterText" (input)="Filtrar($event.target.value)">
                        <button *ngIf="filtersAvailable" mat-icon-button (click)="emitOpenFiltersEvent(); ShowHideFilter();">
                            <img src="/assets/icons/ajustes.png" alt="Ajustes">
                        </button>
                    </div>
                </div>
                
                <div class="icons">
                    <button mat-icon-button  [matMenuTriggerFor]="menuReporte"  *ngIf="puede_reporte_solicitudes">
                        <img [src]="'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/reporte.svg'" alt="">
                    </button>
                    <mat-menu #menuReporte="matMenu" >
                        <button mat-menu-item matTooltip="Reporte de Estatus de Solicitudes" matTooltipPosition="before" (click)="DescargarReporteSolicitudes()" >
                            <mat-icon>
                                <img [src]="'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/reporte_solicitudes.svg'" alt="">
                            </mat-icon>
                            <span>Reporte de Estatus de Solicitudes</span>
                        </button>
                        <!-- <button mat-menu-item>Reporte de Trámites</button> -->
                    </mat-menu>
                    <button mat-icon-button  (click)="goIncidenciasTicket()">
                        <img [src]="'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/ticket.svg'" alt="">
                    </button>
                    <button mat-icon-button  (click)="emitOpenNotificationEvent()">
                    <img [src]="commentsUnreadLength != 0 ? 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bell_notification.svg' : 'https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/bell.svg'" alt="">
                    </button>

                    <!-- <img src="/assets/icons/grid-dot.png" alt=""> -->
                </div>
                <div class="username-profile" (click)="EditarPerfil()">
                    <div class="username">
                        <div class="username-name">{{nombre_completo}}</div>
                        <span class="username-profile">{{tipo_dashboard}}</span>
                    </div>
                    <div class="avatar"><img [src]="user.url_image" alt="" style="border-radius: 180px;"></div>
                </div>
            </header>
            <div class="sidenav-v2" style="height: 100%;">
                <div class="menu-lateral" [ngClass]="{short: shortMenu}" (mouseenter)="showText = true" (mouseleave)="showText = false">
                    <!-- <div class="header">
                        <span style="padding: 6px;">
                            <img src="/assets/icons/menu.png" alt="menu" width="36" height="36">
                        </span>
                        <span style="padding: 8px;">
                            <img src="/assets/images/logo.png" alt="menu" height="36">
                        </span>
                        <div style="width: 36px;"></div>
                    </div> -->
                    <div class="menu">

                        <ul>
                            <div style="margin-bottom: 32px;">
                            <button *ngIf="shortMenu && !showText" mat-icon-button class="btn-icon">
                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/plus.svg" alt="Cotizar" />
                            </button>
                            <button *ngIf="!shortMenu || showText" mat-raised-button (click)="To('crear_cotizacion')" class="btn-icon-raised">
                                <img src="https://leaseforu-assets.s3.us-east-2.amazonaws.com/assets/images/plus.svg" alt="Cotizar" />
                                <span *ngIf="showText || !shortMenu">Cotizar</span>
                            </button>
                            </div>

                            
                            
                            <li *ngFor="let option of menu; let i = index;" [ngClass]="{active: curLocation == option.url, disabled: option.disabled, 'penultimate': penultimateIndex === i, 'ultimate': ultimateIndex === i, 'separator': option.separator }">
                                <img *ngIf="option.icon != null" [src]="option.icon" alt="">
                                <a *ngIf="option.url != null" [routerLink]="option.url" (click)="option.title === 'Cerrar Sesión' ? logout() : null">{{ !shortMenu || (shortMenu && showText) ? option.title : '' }}</a>
                            </li>
                            
                            
                        </ul>
                        
                        
                    </div>
                </div>
                <ng-content></ng-content>
            </div>
        </div>
        <div *ngIf="showNotification" class="notifications" [ngClass]="{'success': typeNotification == 'success','error': typeNotification == 'error','warning': typeNotification == 'warning','info': typeNotification == 'info', 'show': true, 'hide': hideNotification}">
            <div></div>
            <div>{{ messageNotification }}</div>
            <div><span class="closeNotification" (click)="CloseNotification()">X</span></div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
