import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BuroService, SolicitudService } from 'src/app/_services';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
  selector: 'app-editar-contacto',
  templateUrl: './editar-contacto.component.html',
  styleUrls: ['./editar-contacto.component.scss']
})
export class EditarContactoComponent implements OnInit {
  tipoContacto = 'Solicitante'
  contacto
  direccion
  esAval = false
  loading = false

  guardar

  coloniasDisponibles = []

  direccionFiscal = {
		direccion: '',
		direccionGeografica: '',
		calle: '',
		numExterior: '',
		numInterior: '',
		colonia: '',
		ciudad: '',
		municipio: '',
		estado: '',
		codigoPostal: '',
	}

  constructor(
    private buroService: BuroService,
    private solicitudService: SolicitudService,
    private dialogRef: MatDialogRef<EditarContactoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (this.data.aval) {
      this.esAval = true
      this.tipoContacto = 'Aval'
    }

    this.contacto = this.data.contacto
    this.direccion = this.data.direccion

    const solicitud = this.solicitudService.getReferencia();
    const tipo_persona = JSON.parse(localStorage.getItem('client_details')).tipo_persona;
    const parte = 2;

    const guardado = { ...solicitud, tipo_persona, parte}
    
		this.solicitudService.getDatosGenerales( {guardado} ).subscribe(async res => {
			console.log(":GET: ", res);
      await this.construirDireccion(res);
    });

    this.BuscarDireccion()
  }

  async Actualizar() {
    this.loading = true
    this.direccion.direccionCompleta = `${this.direccion.calle} ${this.direccion.numeroExterior} ${this.direccion.numeroInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, C.P.: ${this.direccion.cp}`.trim()
    
    const domicilio = this.construirDireccionRaiz();

    
    if (this.esAval) {
      this.contacto.direccion = this.direccion
    }
    const data = this.esAval ? {aval: this.contacto} : {contacto: this.contacto, direccion: this.direccion}
    console.log(this.data.folio, data);
    await this.solicitudService.GuardarSolicitud(this.data.folio, data)
    await this.guardarDireccion(domicilio);
    this.loading = false
    this.dialogRef.close(data)
    this.dialog.open(MessageBoxComponent,{
      width: "500px",
			  data: {
				text: "Editar contacto",
				text2: "El contacto se ha actualizado con éxito",
				icon: "success",
				type: "success",
			  },
    })
  }

  async BuscarDireccion() {
    const cp = this.direccion.cp

		if (cp.length == 5) {
			console.log(`Buscando info: ${cp}`);
			const res: any = await this.buroService.buscarEstado(cp)
			this.guardarColonias(res)
			console.log(res);
			if (res[0].error == false) {
				const { asentamiento, municipio, estado, ciudad } = res[0].response

				console.log({municipio, estado, ciudad});
				this.direccion.ciudad = ciudad
				this.direccion.municipio = municipio
				this.direccion.estado = estado

				console.log(this.buroService.obtenerCodigoEstado(estado))
			}
			
		}

	}

	guardarColonias(direcciones) {
    this.coloniasDisponibles = []
		direcciones.forEach(element => {
			const { response: {asentamiento} } = element
			this.coloniasDisponibles.push( asentamiento )
		});
	}

  Cerrar() {
    this.dialogRef.close()
  }

  construirDireccion(res) : void {
    const dir = `${this.direccion.calle} ${this.direccion.numeroExterior} ${this.direccion.numeroInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, C.P.: ${this.direccion.cp}`.trim()

    /******************* */

    let direcciones = res.domicilio.split('&');

			const _direccionRaw = direcciones[0]
			const _direccionFiscalRaw = direcciones[1]

			const _direccion = _direccionRaw.split('|')
			const _direccionFiscal = _direccionFiscalRaw.split('|')

			const calles = document.getElementsByName('calle')
			const numExterior = document.getElementsByName('numExterior')
			const numInterior = document.getElementsByName('numInterior')
			const codigoPostal = document.getElementsByName('codigoPostal')
			const colonia = document.getElementsByName('colonia')
			const municipio = document.getElementsByName('municipio')
			const ciudad = document.getElementsByName('ciudad')
			const estado = document.getElementsByName('estado')

			this.direccion.calle = _direccion[0]
			this.direccion.numExterior = _direccion[1]
			this.direccion.numInterior = _direccion[2]
			this.direccion.codigoPostal = _direccion[7]
			this.direccion.colonia = _direccion[3]
			this.direccion.municipio = _direccion[4]
			this.direccion.ciudad = _direccion[6]
			this.direccion.estado = _direccion[5]

			this.direccionFiscal.calle = _direccionFiscal[0]
			this.direccionFiscal.numExterior = _direccionFiscal[1]
			this.direccionFiscal.numInterior = _direccionFiscal[2]
			this.direccionFiscal.codigoPostal = _direccionFiscal[7]
			this.direccionFiscal.colonia = _direccionFiscal[3]
			this.direccionFiscal.municipio = _direccionFiscal[4]
			this.direccionFiscal.ciudad = _direccionFiscal[6]
			this.direccionFiscal.estado = _direccionFiscal[5]

    /****************** */

    const direccionRaiz = `${this.direccion.calle } |${this.direccion.numExterior }|${this.direccion.numInterior}|${this.direccion.colonia }|${this.direccion.municipio }|${this.direccion.estado }|${this.direccion.ciudad }|${this.direccion.codigoPostal }&${this.direccionFiscal.calle}|${this.direccionFiscal.numExterior}|${this.direccionFiscal.numInterior}|${this.direccionFiscal.colonia}|${this.direccionFiscal.municipio}|${this.direccionFiscal.estado}|${this.direccionFiscal.ciudad}|${this.direccionFiscal.codigoPostal}`
		const direccion = `${this.direccion.calle} ${this.direccion.numExterior}-${this.direccion.numInterior}, ${this.direccion.colonia}, ${this.direccion.municipio}, ${this.direccion.estado}, ${this.direccion.ciudad}, C.P.: ${this.direccion.codigoPostal}`
		const direccionFiscal = `${this.direccionFiscal.calle} ${this.direccionFiscal.numExterior}-${this.direccionFiscal.numInterior}, ${this.direccionFiscal.colonia}, ${this.direccionFiscal.municipio}, ${this.direccionFiscal.estado}, ${this.direccionFiscal.ciudad}, C.P.: ${this.direccionFiscal.codigoPostal}`
  
  }

  async guardarDireccion(direcciones){
    const solicitud = this.solicitudService.getReferencia();
    const tipo_persona = JSON.parse(localStorage.getItem('client_details')).tipo_persona;
    const tipo_guardado=1
    const parte = 2;
    const update = true;
    const guardado ={
      ...solicitud, parte,tipo_guardado,tipo_persona,update
    }
    const data ={
      domicilio: direcciones,
      guardado: guardado
    };
    //console.log('valor----data',data);
  
    if(this.esAval){
      console.log('Direccion######:',direcciones);
      const data ={
        aval :{
           aval_domicilio : direcciones,
        guardado: guardado
        }
      };
      (await this.solicitudService.saveDatosGenerales(data, 1, tipo_persona, 1)).subscribe(async (res: any) => {
        console.log('VALOR DE GUARDAR',res);
      })

    }else{
      const data ={
        domicilio: direcciones,
        guardado: guardado
      };
      (await this.solicitudService.saveDatosGenerales(data, 2, tipo_persona, 1)).subscribe(async (res: any) => {
        console.log('VALOR DE GUARDAR',res);
      })
    }
  }

  construirDireccionRaiz(){

    if(this.esAval){
      return `${this.direccion.calle } ${this.direccion.numExterior } ${this.direccion.numInterior},${this.direccion.colonia },${this.direccion.municipio },${this.direccion.estado },${this.direccion.ciudad },C.P.: ${this.direccion.codigoPostal}`.trim();
    }else{
      return `${this.direccion.calle } |${this.direccion.numExterior }|${this.direccion.numInterior}|${this.direccion.colonia }|${this.direccion.municipio }|${this.direccion.estado }|${this.direccion.ciudad }|${this.direccion.codigoPostal }&${this.direccionFiscal.calle}|${this.direccionFiscal.numExterior}|${this.direccionFiscal.numInterior}|${this.direccionFiscal.colonia}|${this.direccionFiscal.municipio}|${this.direccionFiscal.estado}|${this.direccionFiscal.ciudad}|${this.direccionFiscal.codigoPostal}`
    }
		
  }
}
