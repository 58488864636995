import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WsService {
  private socket!: WebSocket;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 2000; // 2 segundos
  private url: string = 'wss://dev1.api.leaseforu.com/ws';

  private messagesSubject: Subject<any> = new Subject<any>();

  constructor() {
    this.connect()
  }

  private connect(): void {
    this.socket = new WebSocket(this.url);

    this.socket.onopen = (event) => {
      console.log('Conectado al WebSocket');
      this.reconnectAttempts = 0;
    };

    this.socket.onmessage = (event) => {
      this.messagesSubject.next(event.data);
    };

    this.socket.onclose = (event) => {
      console.error('Conexión cerrada. Intentando reconectar...');
      this.reconnect();
    };

    this.socket.onerror = (event) => {
      console.error('Error en WebSocket', event);
      this.reconnect();
    };
  }

  private reconnect(): void {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      setTimeout(() => {
        console.log(`Intento de reconexión #${this.reconnectAttempts}`);
        this.connect();
      }, this.reconnectDelay);
    } else {
      console.error('Máximo número de intentos de reconexión alcanzado');
    }
  }

  sendMessage(message: any): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error('No se pudo enviar el mensaje, WebSocket desconectado');
    }
  }

  get messages$(): Observable<any> {
    return this.messagesSubject.asObservable();
  }
}
