import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-msi-table',
  templateUrl: './msi-table.component.html',
  styleUrls: ['./msi-table.component.scss']
})
export class MsiTableComponent implements OnInit {
  @Output() costo_financiero = new EventEmitter
  @Input() value: any = {}

  porcentaje_pago_inicial = 0
  aumentar_valor_factura = false
  disminuir_valor_factura = false
  // porcentajes = [0, 10,15,20,25,27.8,28,30,35,40,45,50]
  porcentajes = []
  porcentaje_seleccionado = 0
  plazo = 0
  periodo_gracia = 0
  plazos_seleccionados :any = []
  pagos_iniciales_seleccionados: any = {}
  // _porcentajes = [{"plazo": "plazo6","label": "6","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo9","label": "9","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo12","label": "12","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo15","label": "15","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo18","label": "18","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo21","label": "21","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo24","label": "24","porcentajes": [10,15,20,25,30,35,40,45,50]}]
  // _pago_inicial = {"gracia0": {"plazo6": [6.92,6.53,6.15,5.76,5.38,4.99,4.61,4.23,3.84],"plazo9": [9.66,9.12,8.59,8.05,7.51,6.98,6.44,5.90,5.37],"plazo12": [12.28,11.60,10.92,10.24,9.55,8.87,8.19,7.51,6.82],"plazo15": [14.79,13.97,13.15,12.33,11.51,10.68,9.86,9.04,8.22],"plazo18": [17.19,16.24,15.28,14.33,13.37,12.42,11.46,10.51,9.55],"plazo21": [19.48,18.40,17.32,16.24,15.15,14.07,12.99,11.91,10.82],"plazo24": [21.68,20.48,19.27,18.07,16.86,14.91,14.45,13.25,12.04]},"gracia1": {"plazo6": [8.81,8.32,7.83,7.34,6.85,6.36,5.87,5.38,4.89],"plazo9": [11.49,10.85,10.22,9.58,8.94,8.30,7.66,7.02,6.38],"plazo12": [14.06,13.28,12.49,11.71,10.93,10.15,9.37,8.59,7.81],"plazo15": [16.51,15.59,14.67,13.76,12.84,11.92,11.01,10.09,9.17],"plazo18": [18.85,17.80,16.76,15.71,14.66,13.61,12.57,11.52,10.47],"plazo21": [21.09,19.92,18.75,17.58,16.41,15.23,14.06,12.89,11.72],"plazo24": [23.24,21.95,20.66,19.36,18.07,16.78,15.49,14.20,12.91]},"gracia2": {"plazo6": [10.66,10.07,9.48,8.88,8.29,7.70,7.11,6.52,5.92],"plazo9": [13.28,12.54,11.81,11.07,10.33,9.59,8.86,8.12,7.38],"plazo12": [15.79,14.91,14.03,13.16,12.28,11.40,10.53,9.65,8.77],"plazo15": [18.18,17.17,16.16,15.15,14.14,13.13,12.12,11.11,10.10],"plazo18": [20.47,19.34,18.20,17.06,15.92,14.79,13.65,12.51,11.37],"plazo21": [22.66,21.40,20.15,18.89,17.63,16.37,15.11,13.85,12.59],"plazo24": [24.76,23.38,22.01,20.63,19.26,17.88,16.51,15.13,13.76]}}
  // _porcentajes_HYDRA = [{"plazo": "plazo6","label": "6","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo9","label": "9","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo12","label": "12","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo15","label": "15","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo18","label": "18","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo21","label": "21","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]},{"plazo": "plazo24","label": "24","porcentajes": [10,15,20,25,27.8,28,30,35,40,45,50]}]
  // _pago_inicial_HYDRA = {"gracia0": {"plazo6": [6.92,6.53,6.15,5.76,0,0,5.38,4.99,4.61,4.23,3.84],"plazo9": [9.66,9.12,8.59,8.05,0,0,7.51,6.98,6.44,5.90,5.37],"plazo12": [12.28,11.60,10.92,10.24,0,0,9.55,8.87,8.19,7.51,6.82],"plazo15": [14.79,13.97,13.15,12.33,0,0,11.51,10.68,9.86,9.04,8.22],"plazo18": [17.19,16.24,15.28,14.33,15.1,11.5,13.37,12.42,11.46,10.51,9.55],"plazo21": [19.48,18.40,17.32,16.24,0,0,15.15,14.07,12.99,11.91,10.82],"plazo24": [21.68,20.48,19.27,18.07,0,0,16.86,14.91,14.45,13.25,12.04],},"gracia1": {"plazo6": [8.81,8.32,7.83,7.34,0,0,6.85,6.36,5.87,5.38,4.89],"plazo9": [11.49,10.85,10.22,9.58,0,0,8.94,8.30,7.66,7.02,6.38],"plazo12": [14.06,13.28,12.49,11.71,0,0,10.93,10.15,9.37,8.59,7.81],"plazo15": [16.51,15.59,14.67,13.76,0,0,12.84,11.92,11.01,10.09,9.17],"plazo18": [18.85,17.80,16.76,15.71,0,0,14.66,13.61,12.57,11.52,10.47],"plazo21": [21.09,19.92,18.75,17.58,0,0,16.41,15.23,14.06,12.89,11.72],"plazo24": [23.24,21.95,20.66,19.36,0,0,18.07,16.78,15.49,14.20,12.91],},"gracia2": {"plazo6": [10.66,10.07,9.48,8.88,0,0,8.29,7.70,7.11,6.52,5.92],"plazo9": [13.28,12.54,11.81,11.07,0,0,10.33,9.59,8.86,8.12,7.38],"plazo12": [15.79,14.91,14.03,13.16,0,0,12.28,11.40,10.53,9.65,8.77],"plazo15": [18.18,17.17,16.16,15.15,0,0,14.14,13.13,12.12,11.11,10.10],"plazo18": [20.47,19.34,18.20,17.06,0,0,15.92,14.79,13.65,12.51,11.37],"plazo21": [22.66,21.40,20.15,18.89,0,0,17.63,16.37,15.11,13.85,12.59],"plazo24": [24.76,23.38,22.01,20.63,0,0,19.26,17.88,16.51,15.13,13.76]}}
  // _porcentajes_BTL = [{"plazo": "plazo6","label": "6","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo9","label": "9","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo12","label": "12","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo15","label": "15","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo18","label": "18","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo21","label": "21","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo24","label": "24","porcentajes": [10,15,20,25,30,35,40,45,50]},{"plazo": "plazo30","label": "30","porcentajes": [10,15,20,25,30,35,40,45,50]}]
  // _pago_inicial_BTL = {"gracia0": {"plazo6": [6.92,6.53,6.15,5.76,5.38,4.99,4.61,4.23,3.84],"plazo9": [9.66,9.12,8.59,8.05,7.51,6.98,6.44,5.90,5.37],"plazo12": [12.28,11.60,10.92,10.24,9.55,8.87,8.19,7.51,6.82],"plazo15": [14.79,13.97,13.15,12.33,11.51,10.68,9.86,9.04,8.22],"plazo18": [17.19,16.24,15.28,14.33,13.37,12.42,11.46,10.51,9.55],"plazo21": [19.48,18.40,17.32,16.24,15.15,14.07,12.99,11.91,10.82],"plazo24": [21.68,20.48,19.27,18.07,16.86,14.91,14.45,13.25,12.04],"plazo30": [27.1,0,0,0,21.8,0,0,0,0]},"gracia1": {"plazo6": [8.81,8.32,7.83,7.34,6.85,6.36,5.87,5.38,4.89],"plazo9": [11.49,10.85,10.22,9.58,8.94,8.30,7.66,7.02,6.38],"plazo12": [14.06,13.28,12.49,11.71,10.93,10.15,9.37,8.59,7.81],"plazo15": [16.51,15.59,14.67,13.76,12.84,11.92,11.01,10.09,9.17],"plazo18": [18.85,17.80,16.76,15.71,14.66,13.61,12.57,11.52,10.47],"plazo21": [21.09,19.92,18.75,17.58,16.41,15.23,14.06,12.89,11.72],"plazo24": [23.24,21.95,20.66,19.36,18.07,16.78,15.49,14.20,12.91],"plazo30": [0,0,0,0,0,0,0,0,0]},"gracia2": {"plazo6": [10.66,10.07,9.48,8.88,8.29,7.70,7.11,6.52,5.92],"plazo9": [13.28,12.54,11.81,11.07,10.33,9.59,8.86,8.12,7.38],"plazo12": [15.79,14.91,14.03,13.16,12.28,11.40,10.53,9.65,8.77],"plazo15": [18.18,17.17,16.16,15.15,14.14,13.13,12.12,11.11,10.10],"plazo18": [20.47,19.34,18.20,17.06,15.92,14.79,13.65,12.51,11.37],"plazo21": [22.66,21.40,20.15,18.89,17.63,16.37,15.11,13.85,12.59],"plazo24": [24.76,23.38,22.01,20.63,19.26,17.88,16.51,15.13,13.76],"plazo30": [0,0,0,0,0,0,0,0,0]}}
  // _porcentajes_MOONS = [{"plazo": "plazo36","label": "36","porcentajes": [0]}]
  // _pago_inicial_MOONS = {"gracia0": {"plazo36": [29.9155]},"gracia1": {"plazo36": [29.9155]},"gracia2": {"plazo36": [29.9155]}}
  // _porcentajes_HERGOM = [{"plazo": "plazo6","label": "6","porcentajes": [0]},{"plazo": "plazo9","label": "9","porcentajes": [0]},{"plazo": "plazo12","label": "12","porcentajes": [0]},{"plazo": "plazo15","label": "15","porcentajes": [0]},{"plazo": "plazo18","label": "18","porcentajes": [0]},{"plazo": "plazo24","label": "24","porcentajes": [0]}]
  // _pago_inicial_HERGOM = {"gracia0": {"plazo6": [6],"plazo9": [9],"plazo12": [12],"plazo15": [15],"plazo18": [18],"plazo24": [24]},"gracia1": {"plazo6": [6],"plazo9": [9],"plazo12": [12],"plazo15": [15],"plazo18": [18],"plazo24": [24]},"gracia2": {"plazo6": [6],"plazo9": [9],"plazo12": [12],"plazo15": [15],"plazo18": [18],"plazo24": [24]}}

  pago_inicial = {}

  tabla_pago_inicial: any = {}
  tabla_pago_inicial2: any = {}
  vendors_special_msi = [71]
  hide_column_indices = [4,5]

  vendors_special_30msi = [42]
  vendors_special_36msi = [7228]

  Vendor: any
  currentUser: any
  id_vendor= 0
  producto: any
  constructor() { }

  

  ngOnInit(): void {
    this.CargarTabla()
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.producto = JSON.parse(localStorage.getItem('producto'))
    this.Vendor = JSON.parse(localStorage.getItem('vendor'))
    console.log(this.Vendor, this.currentUser)
    this.porcentajes = JSON.parse(this.Vendor.porcentajes_msi)
    this.pago_inicial = JSON.parse(this.Vendor.pago_inicial_msi)
    console.log("PORCENTAJES", this.porcentajes)
    // console.log("INICIAL", this.pago_inicial)
      if (this.Vendor && this.Vendor.id) {
        this.id_vendor = Number(this.Vendor.id);

    } else if (this.producto && this.producto.vendor_id) {
        this.id_vendor = this.producto.vendor_id;

    } else {
        this.id_vendor = this.currentUser.id;
    }
    console.log("ID VENDOR", this.id_vendor)
    console.log("ID VENDOR", this.vendors_special_36msi)
    
    if (Object.keys(this.value).length) {
      console.log('cargando', this.value, Object.keys(this.value));
     

      this.periodo_gracia = this.value.periodo_gracia || 0

      this.CargarTabla()
      // this.CargarTabla2()

      // console.log('plazos_seleccionados: ', this.value.plazos_seleccionados);
      
      for (const item of this.value.plazos_seleccionados) {
        console.log('item: ', item);
      
        const seleccion = this.porcentajes.find(p => p.label == item.plazo);
        
        if (seleccion) {
          console.log('seleccion: ', seleccion);
      
          const i = seleccion.porcentajes.indexOf(item.porcentaje);
          
          if (i !== -1) {
            this.pagos_iniciales_seleccionados[`plazo${item.plazo}_${i}`] = item;
            console.log("ITEM", this.pagos_iniciales_seleccionados);
          } else {
            console.error(`Porcentaje ${item.porcentaje} no encontrado en seleccion.porcentajes`);
          }
        } else {
          console.error(`Plazo ${item.plazo} no encontrado en this.porcentajes`);
        }
      }
      

      this.porcentaje_pago_inicial = this.value.porcentaje_pago_inicial,
      this.aumentar_valor_factura = this.value.aumentar_valor_factura
      this.disminuir_valor_factura = this.value.disminuir_valor_factura
    }
  }

  CargarTabla() {
    console.log("CARGANDO tabla")
    this.tabla_pago_inicial = this.pago_inicial[`gracia${this.periodo_gracia}`]
    this.pagos_iniciales_seleccionados = {}
    this.porcentaje_pago_inicial = 0

  }
  // CargarTabla2() {
  //   console.log("CARGANDO tabla")
  //   this.tabla_pago_inicial2 = this._pago_inicial[`gracia${this.periodo_gracia}`]
  //   this.pagos_iniciales_seleccionados = {}
  //   this.porcentaje_pago_inicial = 0
  // }

  Agregar(i, plazo, value) {
    const item = `plazo${plazo}_${i}`;
    console.log({ i, plazo, value });
  
    console.log(this.pagos_iniciales_seleccionados);
    console.log(this.pagos_iniciales_seleccionados[item]);
  
    const num_plazos = Object.keys(this.pagos_iniciales_seleccionados).length;
    console.log(num_plazos);
  
    const seleccion = this.porcentajes.find(p => p.label == plazo);
    
    if (seleccion) {
      console.log("SELECCION", seleccion.porcentajes[i]);
    } else {
      console.error("Plazo no encontrado", plazo);
      return; // Salir si el plazo no existe
    }
  
    if (num_plazos < 6 && this.pagos_iniciales_seleccionados[item] == undefined) {
      this.pagos_iniciales_seleccionados[item] = {
        value,
        plazo,
        porcentaje: seleccion.porcentajes[i],
        periodo_gracia: Number(this.periodo_gracia),
      };
      this.porcentaje_pago_inicial = 0;
      console.log("SELECCIONADOS", this.pagos_iniciales_seleccionados)
    } else {
      delete this.pagos_iniciales_seleccionados[item];
      this.porcentaje_pago_inicial = 0;
    }
  
    // Procesar los plazos seleccionados
    const Keys = Object.keys(this.pagos_iniciales_seleccionados);
    this.plazos_seleccionados = [];
    console.log("SELECCIONADOS", this.pagos_iniciales_seleccionados)
    console.log("SELECCIONADOS", Keys)

  
    for (const itemKey of Keys) {
      this.plazos_seleccionados.push(this.pagos_iniciales_seleccionados[itemKey]);
  
      if (this.pagos_iniciales_seleccionados[itemKey].value > this.porcentaje_pago_inicial) {
        this.porcentaje_seleccionado = this.pagos_iniciales_seleccionados[itemKey].porcentaje;
        this.plazo = this.pagos_iniciales_seleccionados[itemKey].plazo;
        this.porcentaje_pago_inicial = this.pagos_iniciales_seleccionados[itemKey].value;
      }
    }
  
    // Emitir la selección final
    this.EmitSeleccion();
  }
  isPlazoSeleccionado(item, porcentaje): boolean {
    return this.pagos_iniciales_seleccionados.find(p => p.plazo == item.label && p.porcentaje == porcentaje) !== undefined;
  }
  

  EmitSeleccion(type = null) {
    if (type == 'plus') {
      this.disminuir_valor_factura = this.disminuir_valor_factura ? false : false
    }

    if (type == 'minus') {
      this.aumentar_valor_factura = this.aumentar_valor_factura ? false : false
    }


    this.costo_financiero.emit({
      aumentar_valor_factura: this.aumentar_valor_factura,
      disminuir_valor_factura: this.disminuir_valor_factura,
      porcentaje_seleccionado: this.porcentaje_seleccionado,
      plazo: this.plazo,
      porcentaje_pago_inicial: this.porcentaje_pago_inicial,
      periodo_gracia: this.periodo_gracia,
      plazos_seleccionados: this.plazos_seleccionados || []
    })
  }
  // Filtra elementos visibles para un plazo específico
getVisibleItems(plazo: number): any[] {
  const items = this.tabla_pago_inicial[`plazo${plazo}`];
  return items.filter((item, index) => 
      !this.hide_column_indices.includes(index) ||
      this.vendors_special_msi.includes(this.id_vendor));
}

}
