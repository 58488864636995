import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-referencias-nueva-solicitud',
  templateUrl: './referencias-nueva-solicitud.component.html',
  styleUrls: ['./referencias-nueva-solicitud.component.scss']
})
export class ReferenciasNuevaSolicitudComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();
  frmRef: FormGroup;
  numRefArray =[];
  datos_conyugue= false;
  datos_aval= false;
  constructor(
    private formBuilder: FormBuilder,

  ) { }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.frmRef.valueChanges.subscribe(() => {
        if (this.frmRef.valid) {
          this.data = { ...this.data, referencias: this.frmRef.value };
          this.datosActualizados.emit(this.data);
        }
      });
      // if(this.data.referencias){
      //   this.frmRef.patchValue({
      //     rp_nombre1: this.data.referencias.rp_nombre1 || '',
      //     rp_parentesco1: this.data.referencias.rp_parentesco1 || '',
      //     rp_direccion1: this.data.referencias.rp_direccion1 || '',
      //     ocupacion1: this.data.referencias.ocupacion1 || '',
      //     rp_telefono1: this.data.referencias.rp_telefono1 || ''
      //   });
      // }
    }
  }

  ngOnInit(): void {
    this.frmRef = this.formBuilder.group({
      rp_nombre1: ['', Validators.required],
      rp_direccion1: ['', Validators.required],
      ocupacion1: ['', Validators.required],
      rp_parentesco1: ['', Validators.required],
      rp_telefono1: ['', [Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
    })
    this.validarDatos()
  }

  validarDatos(){

    if (this.datos_aval && this.datos_conyugue) {
      this.numRefArray = [0];
    } else if (this.datos_conyugue) {
      this.numRefArray = [0, 1]; 
    } else {
      this.numRefArray = [0, 1, 2]; 
    }

  }
  getErrorMessage() {
    const phoneFormControl = this.frmRef.get(`rp_telefono1`);

    if (phoneFormControl && phoneFormControl.hasError("required")) {
      return "El teléfono es requerido";
    }
    if (
      (phoneFormControl && phoneFormControl.hasError("minlength")) ||
      (phoneFormControl && phoneFormControl.hasError("maxlength"))
    ) {
      return "Ingresa un teléfono válido";
    }
    return "";
  }



}
