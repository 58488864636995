<form [formGroup]="frmDatosPersonales" class="container-form-principal">
  <app-input-validation
    type="text"
    [formGroup]="frmDatosPersonales"
    [controlName]="'nombres'"
    errorMessage="Este campo es requerido"
    label="Nombre(s)"
    mask=""
  ></app-input-validation>
  <div class="container-date">
    <app-input-validation
      type="text"
      errorMessage="Este campo es requerido"
      [formGroup]="frmDatosPersonales"
      [controlName]="'apellido_paterno'"
      label="Apellido Paterno"
      mask=""
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'apellido_materno'"
      errorMessage="Este campo es requerido"
      label="Apellido Materno"
      mask=""
    ></app-input-validation>
    <app-input-validation
      type="date"
      [formGroup]="frmDatosPersonales"
      [controlName]="'fecha_nacimiento'"
      errorMessage="Este campo es requerido"
      label="Fecha de Nacimiento"
      mask=""
    ></app-input-validation>

    <app-radio-validation
      [options]="radioOptions"
      [formGroup]="frmDatosPersonales"
      controlName="sexo"
      label="Género"
    >
    </app-radio-validation>

    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'rfc'"
      [errorMessage]="getErrorMessage()"
      label="RFC"
      maxlength="13"
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'curp'"
      [errorMessage]="getErrorMessage()"
      label="CURP"
      mask="AAAA000000AAAAAA00"
    ></app-input-validation>
    <p style="font-family: 'Poppins'; font-size: 0.7rem">
      <mat-slide-toggle (change)="toggleEspeciality($event)"
        >Tengo especialidad</mat-slide-toggle
      >
    </p>
  </div>
  <div class="container-float" *ngIf="haveSpeciality">
    <div class="container-title-section">
      <div class="title-section">
        <span>ESPECIALIDAD</span>
      </div>
    </div>
  </div>
  <div class="container-date" style="margin-top: 60px" *ngIf="haveSpeciality">
    <div class="custom-input">
      <label>Especialidad</label>
      <select formControlName="especialidad">
        <option value="" selected>Selecciona una especialidad</option>
        <option
          *ngFor="let especialidad of especialidades"
          [value]="especialidad.especialidad"
        >
          {{ especialidad.especialidad }}
        </option>
      </select>
      <div
        class="error-message"
        *ngIf="
          frmDatosPersonales.get('especialidad').invalid &&
          frmDatosPersonales.get('especialidad').dirty
        "
      >
        Este campo es requerido
      </div>
    </div>
    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'cedula_especialidad'"
      [errorMessage]="getErrorMessage()"
      label="Cédula de Especialidad"
      mask="0000000000000"
    ></app-input-validation>
    <div class="custom-input">
      <label>Subespecialidad</label>
      <select formControlName="subespecialidad">
        <option value="" selected>
          Selecciona una subespecialidad
        </option>
        <option
          *ngFor="let subespecialidad of subespecialidades"
          [value]="subespecialidad.subespecialidad"
        >
          {{ subespecialidad.subespecialidad }}
        </option>
      </select>
      <div
        class="error-message"
        *ngIf="
          frmDatosPersonales.get('subespecialidad').invalid &&
          frmDatosPersonales.get('subespecialidad').dirty
        "
      >
        Este campo es requerido
      </div>
    </div>

    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'cedula_subespecialidad'"
      [errorMessage]="getErrorMessage()"
      label="Cédula de Subespecialidad"
      mask="0000000000000"
    ></app-input-validation>
  </div>
  <div class="container-float">
    <div class="container-title-section">
      <div class="title-section">
        <span>DOMICILIO DEL TITULAR</span>
      </div>
    </div>
  </div>
  <div class="container-domicilio-first" style="margin-top: 60px">
    <div>
      <app-input-validation
        type="text"
        [formGroup]="frmDatosPersonales"
        [controlName]="'calle'"
        errorMessage="Este campo es requerido"
        label="Calle"
      ></app-input-validation>
    </div>
    <div class="container-number-street">
      <app-input-validation
        type="text"
        [formGroup]="frmDatosPersonales"
        [controlName]="'num_ext'"
        errorMessage="Este campo es requerido"
        label="Número exterior"
        maxlength="15"
      ></app-input-validation>
      <app-input-validation
        type="text"
        [formGroup]="frmDatosPersonales"
        [controlName]="'cp'"
        errorMessage="Este campo es requerido"
        label="Código Postal"
        mask="00000"
      ></app-input-validation>
    </div>
    <div>
      <app-input-validation
        type="text"
        [formGroup]="frmDatosPersonales"
        [controlName]="'ciudad'"
        errorMessage="Este campo es requerido"
        label="Ciudad"
      ></app-input-validation>
    </div>
  </div>
  <div class="container-domicilio-second">
    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'delegacion_municipio'"
      errorMessage="Este campo es requerido"
      label="Delegación"
    ></app-input-validation>
    <app-input-validation
      type="text"
      [formGroup]="frmDatosPersonales"
      [controlName]="'estado'"
      errorMessage="Este campo es requerido"
      label="Estado"
    ></app-input-validation>
    <div class="custom-input">
      <label>Colonia</label>
      <select formControlName="colonia">
        <option value="" disabled selected>Selecciona una colonia</option>
        <option *ngFor="let colonia of COLONIAS" [value]="colonia">
          {{ colonia }}
        </option>
      </select>
      <div
        class="error-message"
        *ngIf="
          frmDatosPersonales.get('colonia').invalid &&
          frmDatosPersonales.get('colonia').dirty
        "
      >
        Este campo es requerido
      </div>
    </div>
  </div>
  <div class="container-float">
    <div class="container-title-section">
      <div class="title-section">
        <span>OTROS</span>
      </div>
    </div>
  </div>
  <div class="container-date" style="margin-top: 60px">
    <app-radio-validation
      [options]="radioOptionsConsultorio"
      [formGroup]="frmDatosPersonales"
      controlName="tipo_consultorio"
      label="Tipo Consultorio"
    >
    </app-radio-validation>

    <app-radio-validation
      [options]="radioOptionsVivienda"
      [formGroup]="frmDatosPersonales"
      controlName="tipo_vivienda"
      label="Tipo Vivienda"
    >
    </app-radio-validation>
    <app-input-validation
      mask="separator.2"
      thousandSeparator=","
      prefix="$ "
      [formGroup]="frmDatosPersonales"
      [controlName]="'total_gasto'"
      errorMessage="Este campo es requerido"
      label="Gasto mensual(consultorio)"
    ></app-input-validation>
    <div *ngIf="f.tipo_vivienda.value != 'RENTADA'"></div>
    <app-input-validation
      mask="separator.2"
      thousandSeparator=","
      prefix="$ "
      [formGroup]="frmDatosPersonales"
      [controlName]="'pago_mensual'"
      errorMessage="Este campo es requerido"
      label="Pago mensual renta(vivienda)"
      *ngIf="f.tipo_vivienda.value === 'RENTADA'"
    ></app-input-validation>

    <app-input-validation
      mask="separator.2"
      thousandSeparator=","
      prefix="$ "
      [formGroup]="frmDatosPersonales"
      [controlName]="'ingreso_mensual_promedio'"
      errorMessage="Este campo es requerido"
      label="Ingreso mensual promedio(consultorio)"
    ></app-input-validation>

    <div class="custom-input">
      <label>Número de dependientes económicos</label>
      <select formControlName="numero_dependientes">
        <option value="" selected>Selecciona una opción</option>
        <option value="0">0</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
    </div>

    <app-input-validation
      [formGroup]="frmDatosPersonales"
      [controlName]="'antiguedad'"
      errorMessage="Este campo es requerido"
      label="Antigüedad(consultorio)"
    ></app-input-validation>

    <!-- <app-input-validation
      [formGroup]="frmDatosPersonales"
      [controlName]="'equipamiento'"
      errorMessage="Este campo es requerido"
      label="Detalle del equipamiento actual"
    ></app-input-validation> -->
  </div>
  <div class="container-float">
    <div class="container-title-section">
      <div class="title-section">
        <span>CUENTA</span>
      </div>
    </div>
  </div>
  <div class="container-date" style="margin-top: 60px">
    <app-input-validation
      type="email"
      [formGroup]="frmDatosPersonales"
      [controlName]="'correo_cliente'"
      errorMessage="Este campo es requerido"
      label="tucorreo@correo.com"
    ></app-input-validation>

    <app-input-validation
      type="password"
      [formGroup]="frmDatosPersonales"
      [controlName]="'password'"
      errorMessage="Crea una contraseña"
      label="Crea una nueva contraseña"
      isPassword="true"
      *ngIf="!is_flujo_solicitud_cotizador"
    ></app-input-validation>
  </div>
  <div *ngIf="errorMessageGuardar" class="errorMessageGuardar">
    {{ errorMessageGuardarText }}
  </div>
  <div class="container-btn-new">
    <mat-spinner diameter="30" *ngIf="loading"></mat-spinner>
    <button
      *ngIf="!loading"
      class="btn-new-format"
      (click)="enviarDatos()"
      [disabled]="!validForm"
      [ngClass]="{ disable: !validForm }"
    >
      Continuar
    </button>
  </div>
</form>
