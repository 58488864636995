<mat-sidenav-container class="menu" [hasBackdrop]="true">
  <mat-sidenav
    #filtroMenu
    position="end"
    mode="over"
    style="width: 390px"
    [autoFocus]="false"
    >
    <button mat-icon-button (click)="cerrarSidenav()">
      <mat-icon>close</mat-icon>
    </button>
    <app-filtro
      [perfil]="perfil"
      [id_usuario]="id_usuario"
      [filtro]="filtro"
      (onSelect)="
        ObtenerSubperfil($event.id_perfil, $event.id, $event.path)
      "
      (onDateChange)="
        busqueda.fecha_inicio = $event.fecha_inicial;
        busqueda.fecha_final = $event.fecha_final;
        ObtenerTramites()
      "
      (onFiltro)="Filtrar($event)"
      (onChangeCountry)="ChangeCountry($event)"
      (onChangeSemana)="ChangeSemanaCompromiso($event)"
      [busqueda]="busqueda"
    ></app-filtro
  ></mat-sidenav>
  <mat-sidenav-content >
    <app-sidenav-v2
      [shortMenu]="true"
      (buscar)="Filtrar($event)"
      (openFilters)="openFilters()"
      (openNotifications)="openNotifications()"
      [commentsUnreadLength]="comments_unread.length"
      [filtersAvailable]="true"
    >
      <div class="container">
        <div class="box">
          <div class="section section-1">
            
            <div class="container-tipo-dashboard">
              <!-- <div style="display: flex; flex-direction: row; ">
                <h2>Bienvenido,  {{ nombre_usuario }} {{ apellido_paterno }}</h2>
                <h3 style="margin-top: 17.43px; margin-left: 20px;">({{ tipo_dashboard }})</h3> 
              </div> -->
            <p>{{ date | customDate }}</p>
            

            </div>
            <div style="display: flex;align-items:center">
             
              <app-importe
                [importe]="importe"
                *ngIf="!updateStatusList"
              ></app-importe>
            </div>
          </div>
          <div class="section" *ngIf="updateStatus">
            <button mat-button class="botonGuardar" (click)="CambiarStatus()">
              <span class="colorBoton" *ngIf="!updateStatusList"
                >Actualizar status</span
              >
              <span class="colorBoton" *ngIf="updateStatusList"
                >Ver trámites</span
              >
            </button>
          </div>
          <div class="section section-2" *ngIf="!updateStatusList">
            <!-- <app-tramite-proceso
            (onSelect)="proceso_seleccionado = $event"
            [tramites]="tramites"
          ></app-tramite-proceso> -->
          </div>

          <div class="section table-change-status" *ngIf="updateStatusList">
            <app-update-status-list
              [tramites]="tramites"
              (onChange)="ObtenerTramites()"
            ></app-update-status-list>
          </div>

          <div
            class="section section-3"
            *ngIf="!tramites && !hayTramites"
            style="width: 100%; display: flex; justify-content: center"
          >
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div
            class="section section-3"
            *ngIf="loading"
            style="width: 100%; display: flex; justify-content: center"
          >
            <mat-spinner diameter="50"></mat-spinner>
          </div>
          <div class="section section-3" *ngIf="(tramites && !updateStatusList) || (hayTramites && !updateStatusList)">
            <div
              class="lista-tramites"
              [ngClass]="{ wrap: proceso_seleccionado > 0, extended: extended }"
            >
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 1 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Cotización
                </div>
                <div class="proceso">
                  <div class="count-cot"></div>
                  <div class="count-cot"></div>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.solicitud"
                    [tramite]="tramite"
                    [verCliente]="verCliente"
                    [verSolicitud]="false"
                    [verExpediente]="false"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                  ></app-tramite-card>
                </div>
              </div>
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 2 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Expediente
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.expediente.cantidad }} trámites activo
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.expediente.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.expediente"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [verExpediente]="false"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                  ></app-tramite-card>
                </div>
              </div>
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 3 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Confirmación
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.confirmacion.cantidad }} trámites activo
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.confirmacion.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.confirmacion"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                  ></app-tramite-card>
                </div>
              </div>
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 4 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Análisis de crédito
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.comite.cantidad }} trámites activo</span
                  >
                  <span>-</span>
                  <span class="importe">{{
                    proceso.comite.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.comite"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                  ></app-tramite-card>
                </div>
              </div>
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 6 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Resolución
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ tramites_resolucion }} trámites activo</span
                  >
                  <span>-</span>
                  <span class="importe">{{
                    tramites_resolucion_importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="
                      let tramite of mergeArrays(
                        tramites.aprobadas,
                        tramites.info_adicional,
                        tramites.aprobadas_con
                      )
                    "
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                    [column]="proceso_seleccionado == 0"
                    [resolucion]="true"
                  ></app-tramite-card>
                  <ng-container *ngIf="perfil === 4">
                    <app-tramite-card
                      *ngFor="let tramite of tramites.rechazadas"
                      [tramite]="tramite"
                      [verCliente]="true"
                      [column]="proceso_seleccionado == 0"
                      [perfil]="perfil"
                      [id_usuario]="id_usuario"
                      [type]="7"
                      [resolucion]="true"
                    ></app-tramite-card>
                  </ng-container>
                </div>
              </div>

              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 8 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Contrato
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ contratos_realizados_enviados }} trámites activo
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.contrato.importe  | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                  *ngFor="
                  let tramite of mergeContratos(
                    tramites.contrato,
                    tramites.contrato_enviado,
                    tramites.contrato_recibido
                  )
                "
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                    [column]="proceso_seleccionado == 0"
                    [resolucion]="false"
                  ></app-tramite-card>
                </div>
              </div>
              
              
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 9 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Contrato enviado
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.contrato_enviado.cantidad }} trámites enviados
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.contrato_enviado.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.contrato_enviado"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                    [column]="proceso_seleccionado == 0"
                  ></app-tramite-card>
                </div>
              </div>


              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 11 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  Contrato activo
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.contrato_activado.cantidad }} trámites activo
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.contrato_activado.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.contrato_activo"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                    [column]="proceso_seleccionado == 0"
                  ></app-tramite-card>
                </div>
              </div>
              <div
                class="tramites"
                *ngIf="proceso_seleccionado == 12 || proceso_seleccionado == 0"
                [ngClass]="{
                  column: proceso_seleccionado == 0,
                  row: proceso_seleccionado != 0
                }"
              >
                <div class="title" *ngIf="proceso_seleccionado == 0">
                  En colocación
                </div>
                <div class="proceso">
                  <span class="count"
                    >{{ proceso.en_colocacion.cantidad }} trámites activo
                  </span>
                  <span>-</span>
                  <span class="importe">{{
                    proceso.en_colocacion.importe | currency : "$"
                  }}</span>
                </div>
                <div class="list">
                  <app-tramite-card
                    *ngFor="let tramite of tramites.en_colocacion"
                    [tramite]="tramite"
                    [verCliente]="true"
                    [column]="proceso_seleccionado == 0"
                    [perfil]="perfil"
                    [id_usuario]="id_usuario"
                    [column]="proceso_seleccionado == 0"
                  ></app-tramite-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="comments_unread.length" class="notificaciones" [ngClass]="{'show-notifications': !show_notifications}">
          <!-- <h1>
            Notificaciones ({{ total_unread_comments }})
            <span [ngClass]="{ hide_notifications: !show_notifications }"
              ><mat-icon (click)="toggleNotifiocations()"
                >expand_less</mat-icon
              ></span
            >
          </h1> -->
          <div >
            <div
              *ngFor="let comment of comments_unread"
              class="notificacion"
              [ngClass]="{ show_notifications: show_notifications }"
            >
              <span>Folio: {{ comment.folio }}</span>
              <p>Tienes {{ comment.unread_comments }} comentarios sin leer.</p>
            </div>
          </div>
        </div>
        <app-socials-footer style="width: 100%"></app-socials-footer>
      </div>
    </app-sidenav-v2>
  </mat-sidenav-content>
</mat-sidenav-container>
