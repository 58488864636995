import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { MessageBoxComponent } from "src/app/_dialogs/message-box/message-box.component";
import { UserService } from "src/app/_services";

@Component({
  selector: "app-documentacion-personal",
  templateUrl: "./documentacion-personal.component.html",
  styleUrls: ["./documentacion-personal.component.scss"],
})
export class DocumentacionPersonalComponent implements OnInit {
  @Input() data: any;
  @Output() datosActualizados = new EventEmitter();

  data_get_document: any = {};
  perfil = 0;
  id_cliente = null;
  folio = "";
  tipo_persona = 1;
  tipo_aval = 1;
  loading = false;
  $loading = null;

  Documentos = {
    DocumentosSolicitante: [],
    DocumentosSolicitanteLegal: [],
    DocumentosAval: [],
    DocumentosAvalLegal: [],
  };
  documentos_necesarios = 0;
  faltanDocumentos = false;
  DocumentosValidar = [];
  validandoDocumentos = false;
  cancelDoc = false;
  completado = false;
  solicitud_prellenada = false;
  formData: FormData;
  documentos_estados_cuenta = [];

  allowed_type = "application/pdf";

  constructor(
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.id_cliente = localStorage.getItem("current_id_cliente");
    this.folio = localStorage.getItem("folio_solicitud");
    // this.folio = this.data.folio
    this.data_get_document =
      this.tipo_persona == 1
        ? {
            folio: this.folio.toString(),
            id_cliente: this.id_cliente,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
            empleado: false,
            nuevo_flujo: true,
          }
        : {
            folio: this.folio,
            tipo_persona: this.tipo_persona,
            tipo_aval: this.tipo_aval,
          };
    this.ObtenerStatus();
  }
  async ObtenerStatus() {
    try {
      const res = await this.userService
        .ObtenerStatusDocuments(this.data_get_document)
        .subscribe(
          (res) => {
            this.Documentos = res;
            this.documentos_necesarios =
              this.Documentos.DocumentosSolicitante.length +
              this.Documentos.DocumentosAval.length;
            this.documentos_estados_cuenta =
              this.Documentos.DocumentosSolicitante.filter((documento) =>
                [4, 17, 18].includes(documento.tipo_documento)
              );

            if (Number(this.perfil) === 4) {
              const result1 = this.Documentos.DocumentosSolicitante.filter(
                (doc: any) => {
                  return doc.status === 2;
                }
              );

              const result2 = this.Documentos.DocumentosAval.filter(
                (doc: any) => {
                  return doc.status === 2;
                }
              );
              this.cancelDoc = result1.length + result2.length > 0;
            }

            this.faltanDocumentos = false;
            this._hayDocumentosSinCargar(this.Documentos.DocumentosSolicitante);
            this._hayDocumentosSinCargar(this.Documentos.DocumentosAval);
            this.DocumentosCompletos();
          },
          (err) => {}
        );
    } catch {
      this._snackBar.open(
        "Ha ocurrido un error, por favor vuelve a intentarlo. ",
        "Ok",
        {
          duration: 10000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: ["warning-snackbar"],
        }
      );
    }
  }
  private _hayDocumentosSinCargar(documentos) {
    if (!this.faltanDocumentos) {
      for (const documento of documentos) {
        if (documento.tipo_documento != 21 && documento.status == 0) {
          this.faltanDocumentos = true;
          break;
        }
      }
    }
  }
  DocumentosCompletos() {
    if (!this.userService.alertService.isOnline()) return;
    this.completado = true;
    let status = 0;

    this.Documentos.DocumentosSolicitante.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
      this.solicitud_prellenada =
        documento.tipo_documento == 21 && documento.url.length > 0;
    });
    this.Documentos.DocumentosAval.forEach((documento) => {
      if (documento.requerido == true && documento.status != 3) {
        this.completado = false;
      }
    });

    if (this.Documentos.DocumentosSolicitanteLegal)
      this.Documentos.DocumentosSolicitanteLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
        this.solicitud_prellenada =
          documento.tipo_documento == 21 && documento.url.length > 0;
      });
    if (this.Documentos.DocumentosAvalLegal)
      this.Documentos.DocumentosAvalLegal.forEach((documento) => {
        if (documento.requerido == true && documento.status != 3) {
          this.completado = false;
        }
      });

    if (!this.solicitud_prellenada) {
      this.documentos_necesarios -= 1;
    }
  }
  SeleccionarDocumento(doc, solicitante) {
    // if (!this.userService.alertService.isOnline()) return;
    // const doc = (solicitante) ? this.Documentos.DocumentosSolicitante[idx] : this.Documentos.DocumentosAval[idx]
    if (doc.status == 3 || doc.status == 1) return;

    this.formData = new FormData();
    this.formData.append("folio", this.folio);
    this.formData.append("id_cliente", this.id_cliente.toString());
    this.formData.append("tipo_documento", doc.tipo_documento);
    this.formData.append("solicitante", solicitante);
    this.formData.append("id_usuario_mod", this.id_cliente.toString());

    const $document: any = document.querySelector("#document");
    $document.click();
  }

  SubirDocumento({ target: { files } }) {
    // if (!this.userService.alertService.isOnline()) return;
    const file = files[0];

    if (file) {
      this.formData.append("document", file);

      this.userService.SubirDocumento(this.formData).subscribe(
        (res) => {
          const $document = document.querySelector("#document");
          this._snackBar.open(
            "Se ha cargado correctamente el documento",
            "Ok",
            {
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["success-snackbar"],
            }
          );
          // this.userService.changeStatus(this.folio, 2, 1) // ya se realiza desde el servicio
          this.ObtenerStatus();
        },
        (err) => {
          this._snackBar.open(
            "Ha ocurrido un error, por favor vuelve a intentarlo. ",
            "Ok",
            {
              duration: 10000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["warning-snackbar"],
            }
          );
        }
      );
    }
  }

  resset = (event) => {
    event.target.value = null;
  };

  VerDocumento(documento, solicitante) {
    localStorage.setItem("documento", JSON.stringify(documento));

    if (documento.url) {
      const url = `#/dashboard/cliente/${this.folio}/ver_documento`;
      window.open(url, "_blank");
    }
  }
}
