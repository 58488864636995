import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { expandedSection } from "src/app/_animations/animations";
import { SolicitudService, UserService } from "src/app/_services";
import { NuevaSolicitudService } from "src/app/_services/nueva_solicitud.service";

@Component({
  selector: "app-carga-documentacion",
  templateUrl: "./carga-documentacion.component.html",
  styleUrls: ["./carga-documentacion.component.scss"],
  animations: [expandedSection],
})
export class CargaDocumentacionComponent implements OnInit {
  @Input() data: any;
  @Output() siguiente = new EventEmitter();
  @Output() anterior = new EventEmitter();
  @Output() datosActualizados = new EventEmitter();

  expandedDocumentosPersonales = false;
  expandedConyugue = false;
  expandedAval = false;
  expandedReferencias = false;
  folio = null;
  loading = false;
  constructor(private nuevaSolicitudService: NuevaSolicitudService,
    private solicitudService: SolicitudService,
    private userService: UserService

  ) {}

  ngOnInit() {
    this.folio = JSON.parse(localStorage.getItem('folio_solicitud'))
  }

  atras() {
    this.anterior.emit();
  }
  async enviarDatos() {
    this.datosActualizados.emit(this.data);
    this.loading = true
    const emailCliente = this.data.correo || this.data.email;
    const rfcCliente = this.data.rfc;
    const reqGuardadoDatos = {
      aval: this.data.aval,
      conyugue: this.data.conyugue,
      referencias: this.data?.referencias
    };
    const aval = {
      nombreCompleto: this.data?.aval?.nombreCompleto,
      apellidoPaterno: this.data?.aval?.apellidoPaterno,
      apellidoMaterno: this.data?.aval?.apellidoMaterno,
      email: this.data?.aval?.email,
      genero: this.data?.aval?.genero == "F" ? "Mujer" : 'Hombre',
      rfc: this.data?.aval?.rfc,
      fechaNacimiento: this.data?.aval?.fecha_nacimiento,
      tipoPersona: "Persona física",
      direccion:{
        calle: this.data?.aval?.calle,
        numExterior: this.data?.aval?.num_ext,
        ciudad: this.data?.aval?.ciudad,
        colonia: this.data?.aval?.colonia,
        cp: this.data?.aval?.cp,
        estado: this.data?.aval?.estado,
        municipio: this.data?.aval?.delegacion_municipio,
        direccionCompleta: `${this.data?.aval?.calle} ${this.data?.aval?.num_ext} ${this.data?.aval?.num_ext}, ${this.data?.aval?.colonia}, ${this.data?.aval?.delegacion_municipio}, ${this.data?.aval?.estado}, ${this.data?.aval?.cp}`
      },
      tel: this.data?.aval?.tel
    }
    const referencias =  this.data.referencias
    const resGuardarDatosCliente =
      await this.nuevaSolicitudService.guardarTodosDatosSolicitud(
        emailCliente,
        rfcCliente,
        reqGuardadoDatos
      );
    if (resGuardarDatosCliente) {
      const resGuardarSolicitud = await this.solicitudService.GuardarSolicitud(this.folio.toString(), {aval, referencias})
      if(resGuardarSolicitud){
        this.loading = false
        const resChageStatus = this.userService.CambiarStatusSolicitud(this.folio.toString(), 1, 1)
        if(resChageStatus){
          this.siguiente.emit();
        }
      }
    }
  }
  datosActualizadosDos(nuevosDatos: any): void {
    this.data = nuevosDatos;
    this.datosActualizados.emit(this.data);
  }
  toggleExpand(section: string) {
    switch (section) {
      case "conyugue":
        this.expandedConyugue = !this.expandedConyugue;
        if (this.expandedConyugue) {
          this.expandedDocumentosPersonales = false;
          this.expandedAval = false;
          this.expandedReferencias = false;
        }
        break;
      case "documentosPersonales":
        this.expandedDocumentosPersonales = !this.expandedDocumentosPersonales;
        if (this.expandedDocumentosPersonales) {
          this.expandedConyugue = false;
          this.expandedAval = false;
          this.expandedReferencias = false;
        }
        break;
      case "aval":
        this.expandedAval = !this.expandedAval;
        if (this.expandedAval) {
          this.expandedConyugue = false;
          this.expandedDocumentosPersonales = false;
          this.expandedReferencias = false;
        }
        break;
      case "referencias":
        this.expandedReferencias = !this.expandedReferencias;
        if (this.expandedReferencias) {
          this.expandedConyugue = false;
          this.expandedDocumentosPersonales = false;
          this.expandedAval = false;
        }
        break;
      default:
        break;
    }
  }
}
