<div class="table-documentos">
    <h3>Selecciona los campos</h3>
    <table class="table-documentos documentos-list" [cellSpacing]="0" [cellPadding]="0">
        <thead>
            <tr>
                <th>N.</th>
                <th>Campo</th>
                <th>Requerido</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngClass]="{seleccionado: InputSeleccionado(input.id)}"     *ngFor="let input of inputService.campos[inputSection]; let idx = index"             (click)="AgregarQuitar(input.id)">
                <td>{{ idx +1 }}</td>
                <td>{{ input.name }}</td>
                <td>{{ input.required ? 'Sí' : 'No'}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div style="display: flex; justify-content: flex-end; align-items: center; margin-top: 24px;">
    <mat-progress-spinner
        *ngIf="guardando"
        mode="indeterminate"
        diameter="30"
        ></mat-progress-spinner>
    <button *ngIf="!guardando" class="btn-primary-new" (click)="GuardarInputs()">Guardar</button>
</div>