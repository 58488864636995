import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-detale-cartera-anexo',
  templateUrl: './dialog-detale-cartera-anexo.component.html',
  styleUrls: ['./dialog-detale-cartera-anexo.component.scss']
})
export class DialogDetaleCarteraAnexoComponent implements OnInit {
  rentas: any = []
  cliente = ""
  anexo = ""
  constructor(
    private dialogRef: MatDialogRef<DialogDetaleCarteraAnexoComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    if (this.data.resumen) {
    } else {
      this.rentas = this.data.desglose
      this.cliente = this.data.cliente
      this.anexo = this.data.folio
    }
  }

}
