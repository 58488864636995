import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cartera-filter',
  templateUrl: './cartera-filter.component.html',
  styleUrls: ['./cartera-filter.component.scss']
})
export class CarteraFilterComponent implements OnInit {
  @Output() onFilter = new EventEmitter()
  @Input() setFilter: any = {}

  filtros = {
    fechaInicio: '',
    fechaFin: '',
    restructura: ''
  }

  constructor() { }

  ngOnInit(): void {
    console.log(this.filtros);
    
    this.filtros.fechaInicio = this.setFilter.fechaInicio ? this.setFilter.fechaInicio.split('T')[0] : ''
    this.filtros.fechaFin = this.setFilter.fechaFin ? this.setFilter.fechaFin.split('T')[0] : ''
  }

  filtrarFechas() {
    console.log(this.filtros);
    if (!this.filtros.fechaInicio || !this.filtros.fechaFin || (this.filtros.fechaInicio > this.filtros.fechaFin)) return
    this.filtros.fechaInicio
    this.filtros.fechaFin

    this.sendFilters()
  }

  sendFilters() {
    this.onFilter.emit({
      fechaInicio: this.filtros.fechaInicio + 'T00:00:00.000Z',
      fechaFin: this.filtros.fechaFin + 'T23:59:59.000Z',
      restructura: this.filtros.restructura
    })
  }

}
