import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { customPatterns, emailPattern } from 'src/app/_models/maskPattern';
import { AuthenticationService } from 'src/app/_services';
import { VendorsService } from 'src/app/_services/vendors.service';

@Component({
  selector: 'app-vendedor-edit',
  templateUrl: './vendedor-edit.component.html',
  styleUrls: ['./vendedor-edit.component.scss']
})
export class VendedorEditComponent implements OnInit {
  customPattern = customPatterns;
  emailPatter = emailPattern;
  formData: FormData;
  is_loading = false
  Vendor: any = {}
  Vendedor: any = {
    id: 0,
    nombres:'',
    apellido_parterno: '',
    apellido_materno: '',
    email: '',
    phone: '',
    status: false
  }

  image_preview: any = 'https://static.api.leaseforu.com/users/images/no-image.png'
  tipo_evento = 'Nuevo'

  error = false
  email_error = false

  constructor(
    private dialog: MatDialog,
    private vendorService: VendorsService,
    private router: Router,
    private authenticationService: AuthenticationService,
   
  ) { }

  ngOnInit(): void {
    const _vendedor = localStorage.getItem('vendedor')
    this.Vendor = JSON.parse(localStorage.getItem('vendor'))
    if (_vendedor) {
      this.tipo_evento = 'Editar'
      this.Vendedor = JSON.parse(_vendedor)
      console.log(this.Vendedor);
      this.image_preview = this.Vendedor.url_image
    }
  }

  async GuardarVendedor() {
    if (
      !this.Vendedor.nombres ||
      !this.Vendedor.apellido_parterno
    ) {
      this.error = true
      return
    }

    if (
        !this.Vendedor.email ||
        !this.Vendedor.email.match(emailPattern)
      ) {
      this.email_error = true
      return
    }

    this.is_loading = true

    console.log("Vendedor:::::",this.Vendedor);
    console.log("ID Vendedor:::::",this.Vendedor.id);
    if (this.Vendedor.id == 0) {
      try {
        this.is_loading = true; // Mostrar indicador de carga
        await this.vendorService.CrearVendedor({
          "correo": this.Vendedor.email,
          "phone": this.Vendedor.phone,
          "nombre": this.Vendedor.nombres,
          "a_paterno": this.Vendedor.apellido_parterno,
          "a_materno": this.Vendedor.apellido_materno,
          "factor": 0,
          "descuento_ciego": false,
          "tipo_perfil": 7,
          "id_perfil_padre": this.Vendor.user_id
        }).toPromise();
    
        this.ShowDialog("success", "Se ha creado correctamente");
        this.router.navigate(['/vendors/editar']);
      } catch (error) {
        console.error("Error al crear el vendedor:", error);
        this.ShowDialog("info", `${error.error.message}. Por favor, intente de nuevo.`);
      } finally {
        this.is_loading = false; // Ocultar indicador de carga
      }
      return;
    }
    

    this.formData = new FormData();
    const producto:any = document.querySelector("input[name='vendedor_image']")
    const file = producto.files[0]

    if (file) {
      console.log(file);
      this.formData.append("image_vendedor", file);
    }
    for (const key of Object.keys(this.Vendedor)) {
      if(this.Vendedor[key]) {
        this.formData.append(key, this.Vendedor[key])
      }
    }
    console.log(this.formData);
    try {
      if (this.Vendedor.id > 0) {
        await this.vendorService.ActualizarVendedor(this.formData).toPromise()
        this.ShowDialog("success", "Se ha actualizado correctamente")
        this.is_loading = false
      } else {
        await this.vendorService.CrearVendedor(this.formData).toPromise()
        this.ShowDialog("success", "Se ha creado correctamente")
        this.is_loading = false
      }
    } catch(e) {
      console.error(e)
      this.ShowDialog("error", "Hubo un error al guardar el producto")
      this.is_loading = false
    }
  }

  ImagePreview() {
    const img_vendedor:any = document.querySelector("input[name='vendedor_image']")
    const file = img_vendedor.files[0]

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image_preview = reader.result
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  ShowDialog(type, text, text2 = '') {
    this.dialog.open(MessageBoxComponent, {
      width: "500px",
      data: {
        text,
        text2,
        type,
        icon: type,
      },
    });
  }

  ResetPassword() {
    this.is_loading = true
    this.vendorService.ResetPassword(this.Vendedor.email).subscribe(
      res => {
        this.is_loading = false
        this.ShowDialog("success", "Se ha cambiado la contraseña con exito")
      },
      err => {
        this.is_loading = false
        this.ShowDialog("error", "Ocurrió un error al cambiar la contraseña")
      }
    )
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
