import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputsService } from 'src/app/_services/inputs.service';

@Component({
  selector: 'app-selector-inputs-vendor',
  templateUrl: './selector-inputs-vendor.component.html',
  styleUrls: ['./selector-inputs-vendor.component.scss']
})
export class SelectorInputsVendorComponent implements OnInit {
  @Input() inputSeleccionados: any[] = []
  @Input() inputSection: string = '';
  @Output() actualizar = new EventEmitter
  @Output() guardar = new EventEmitter
  @Input() guardando = false
  constructor(
    public inputService: InputsService
  ) { }

  ngOnInit(): void {
    console.log()
  }


  InputSeleccionado(id) {
    return this.inputSeleccionados.includes(id);
    }

  AgregarQuitar(id) {
    const idx = this.inputSeleccionados.indexOf(id)
    if (idx === -1) {
      this.inputSeleccionados.push(id)
    } else {
      this.inputSeleccionados.splice(idx,1)
    }

    this.actualizar.emit(this.inputSeleccionados)
  }

  GuardarInputs() {
    this.guardar.emit()
  }

}
