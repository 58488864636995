import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  @Input() periodo: any = {}
  @Input() confirmada: Boolean = false
  @Input() country: any = "MX"
  show_details = false

  constructor() { }

  ngOnInit(): void {
    console.log("COUNTRY", this.country)
  }

}
