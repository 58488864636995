const cur_date = new Date().toISOString().split("T")[0];

export const MESES = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const COMPORTAMIENTO_PAGO = [
  { id: 1, nombre: "Bueno", puntuacion: 10 },
  { id: 2, nombre: "Regular", puntuacion: 5 },
  { id: 3, nombre: "Malo", puntuacion: 0 },
];

export const COMPORTAMIENTO_PAGO_CREDITO = [
  { id: 1, nombre: "Bueno", puntuacion: 4 },
  { id: 2, nombre: "Regular", puntuacion: 2 },
  { id: 3, nombre: "Malo", puntuacion: 0 },
];

export const TIEMPO_ENTREGA = [
  { id: 0, tiempo: "Inmediato" },
  { id: 1, tiempo: "10 a 30 días" },
  { id: 2, tiempo: "31 a 60 días" },
  { id: 3, tiempo: "61 a 90 días" },
];

export const AnalisisPF = {
  tipo_comite: "",
  fecha_creacion: cur_date,
  inicio_operaciones: "",
  cliente_l4u: false,
  id_comportamiento_pago: 3,
  id_resolucion_comite: 0,
  resolucion_comite: "",
  comentarios_resolucion_comite: "",
  folio: "",
  email: "",
  tipo_persona: 1,
  solicitante: "",
  razon_social: "",
  rfc: "",
  id_cliente: <number>0,

  numero_comite: <number>0,
  fecha_comite: cur_date,
  numero_cotizacion: "",
  fecha_ingreso: "",

  tel_casa: "",
  tel_cel: "",
  ultima_consulta_buro: "",
  // numero_comite:<number> 0,
  // tipo_comite: '',
  // fecha_creacion: cur_date,
  // inicio_operaciones: '',
  // cliente_l4u: false,
  // id_comportamiento_pago: 3,
  nombre_escuela_egresado: "",
  residencia_escuela: "",
  especialidad: "",
  actividad_economica: "",
  puntuacion_total: 0,
  entrevista_general: {
    domicilio: "",
    domicilio_propio: false,
    domicilio_antiguedad: "",
    mmr: "0",
    dependientes: "",
    emd: "0",
    gastos_buro: 0,
    consejo_especialidad: "",
  },
  entrevista_economica: {
    sector_publico: false,
    hospital_clinica: "",
    tipo_contrato: "",
    puesto: "",
    puesto_antiguedad: "",
    ingreso_neto_mensual: "",
    puesto_domicilio: "",
  },
  entrevista_consultorio: {
    propio: false,
    monto_renta: 0,
    telefono: "",
    domicilio: "",
    pacientes_por_mes: 0,
    precio_consulta: 0,
    ingresos_aprox_pacientes: 0,
    estudios_por_mes: 0,
    precio_estudio: 0,
    ingresos_aprox_estudios: 0,
    cirugias_por_mes: 0,
    precio_cirugia: 0,
    ingresos_aprox_cirugias: 0,
    ingresos_efectivo: 0,
    ingresos_aprox_mensuales: 0,
    tipo_equipo: "NUEVO",
    ingresos_extras_equipo: 0,
    estudios_por_mes_equipo: 0,
    precio_estudio_equipo: 0,
    ingresos_aprox_estudios_equipo: 0,
    tipo_estudios: "",
    antiguedad: "",
  },
  participantes: [
    {
      nombre: "",
      puesto: "",
      iniciales: "",
      asistencia: false,
    },
    {
      nombre: "",
      puesto: "",
      iniciales: "",
      asistencia: false,
    },
    {
      nombre: "",
      puesto: "",
      iniciales: "",
      asistencia: false,
    },
    {
      nombre: "",
      puesto: "",
      iniciales: "",
      asistencia: false,
    },
  ],
  aval: {
    aval_nombre: "",
    aval_parentezco: "",
    aval_ocupacion: "",
    aval_domicilio: "",
    aval_telefono: "",
    tiempo_conocerlo: "",
    lo_recomienda: false,
  },
  referencias: [
    {
      nombre: "",
      parentezco: "",
      ocupacion: "",
      domicilio: "",
      telefono: "",
      tiempo_conocerlo: "",
      lo_recomienda1: false,
    },
    {
      nombre: "",
      parentezco: "",
      ocupacion: "",
      domicilio: "",
      telefono: "",
      tiempo_conocerlo: "",
      lo_recomienda2: false,
    },
    {
      nombre: "",
      parentezco: "",
      ocupacion: "",
      domicilio: "",
      telefono: "",
      tiempo_conocerlo: "",
      lo_recomienda3: false,
    },
  ],
  capacidad_pago: {
    ingresos_nomina: 0,
    ingresos_nominaCopy: "",
    ingresos_efectivo: 0,
    ingresos_efectivoCopy: "",
    ingresos_extra: 0,
    ingresos_extraCopy: "",
    factor_renta: 0,
    maximo_factor: 60,
    comprobantes_ingresos: 0,
    comprobantes_gastos: 0,
    comprobantes_factor: 0,
    efectivo_ingresos: 0,
    efectivo_gastos: 0,
    efectivo_factor: 0,
    extra_nuevo_equipo_ingresos: 0,
    extra_nuevo_equipo_gastos: 0,
    extra_nuevo_equipo_factor: 0,
    puntuacion_capacidad_pago: 0,


    comprobantes_ingresosCopy: "",
    comprobantes_gastosCopy: "",
    efectivo_ingresosCopy: "",
    efectivo_gastosCopy: "",
    extra_nuevo_equipo_ingresosCopy: "",
    extra_nuevo_equipo_gastosCopy: "",
    comentarios_capacidad_pago: "",
    renta_adicional_disponible:0

  },
  cotizacion: {
    cotizacion: "",
    activo_arrendar: "",
    fecha_cotizacion: "",
    tiempo_entrega: 0,
    tipo_vendor: "Interno",
    vendor: "",
    comercial: "",
    marca: "",
    garantia: "",
    valor_residual: "",
    valor_residualCopy: "",
    mar_total: 0,
    mar_totalCopy: "",
    monto_arrendar: 0,
    monto_arrendarCopy: "",
    factor_porcentaje: 0,
    plazo: 0,
    mensualidad: 0,
    mensualidadCopy: "",
    rentas_anticipadas: "0",
    rentas_anticipadasCopy: "0",
  },

  caracteristica_activo: {
    vida_util: "",
    vida_util_pts: <number>0,
    mercado_secundario: "",
    mercado_secundario_pts: <number>0,
    estimado_de_colocacion: "",
    estimado_de_colocacion_pts: <number>0,
    ubicacion: "",
    ubicacion_pts: <number>0,
    tipo_financiamient: "ARRENDAMIENTO PURO",
    tipo_financiamiento_pts: <number>7,
    tipo_activo_arrendar: "",
    descripcion_activo: "",
    domicilio_activo: "",
  },

  promedio_depositos: 0,
  promedio_gastos: 0,
  promedio_saldo_final: 0,
  promedio_depositosCopy: "",
  promedio_gastosCopy: "",
  promedio_saldo_finalCopy: "",

  caracter: {
    comentarios_caracter: "",

    puntos_credito_abierto: 0,
    puntos_credito_cerradas: 0,

    puntuacion_comportamiento_pago: 0,
    puntuacion_caracter: 0,
  },
  colateral: {
    pago_inicial: 0,
    pago_inicialCopy: "",
    factor: 0,
    factorCopy: "",
    comentarios_colateral: "",
    puntuacion_colateral: 0,

  },

  perfil_solicitante: {
    bc_score: 0,
    bc_score_descrip: ">= 630",
    bc_score_punt: 0,
    icc: 0,
    icc_descrip: ">= 6",
    icc_punt: 0,
    arraigo_domicilio: 0,
    arraigo_domicilio_descrip: "> 2 AÑOS",
    arraigo_domicilio_punt: 0,
    arraigo_empleo: 0,
    arraigo_empleo_descrip: "> 2 AÑOS",
    arraigo_empleo_punt: 0,
    consejo_especialidad: "",
    consejo_especialidad_descrip: "REGISTRO",
    consejo_especialidad_punt: 0,
    total: 0,
  },

  perfil_aval: {
    nombre: "",
    bc_score: 0,
    bc_score_descrip: ">= 630",
    bc_score_punt: 0,
    icc: 0,
    icc_descrip: ">= 6",
    icc_punt: 0,
    documentacion: "",
    documentacion_descrip: "ok",
    documentacion_punt: 0,
    total: 0,
  },

  historial_credito: {
    activas: [
      {
        tipo_credito: "",
        monto: 0,
        mop_max: 0,
        saldo_insoluto: 0,
        status: true,
        id_comportamiento_pago: 0,
        comp_pago: "",
        puntuacion: 0,
      },
    ],
    activasCopy: [],
    cerradas: [
      {
        tipo_credito: "",
        monto: 0,
        mop_max: 0,
        saldo_insoluto: 0,
        status: false,
        id_comportamiento_pago: 0,
        comp_pago: "",
        puntuacion: 0,
      },
    ],
    cerradasCopy: [],
  },
  historial_movimientos: [{ mes: "", depositos: 0, gastos: 0, saldo_final: 0 }],
  historial_movimientos_copy: [],
  minuta: {
    numero_comite: 0,
    fecha_comite: "",
    hora_comite: "",
    ubicacion:
      "Carr. México- Toluca #5420 El Yaqui, Cuajimalpa. CDMX. C.P 05320",
    puntos_principales_revisados: "",
    comentarios_adicionales: "",
  },
};

