import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService, BuroService, UserService } from '../../../../_services';
import { SolicitudService } from 'src/app/_services/solicitud.service';

import { customPatterns, emailPattern } from '../../../../_models/maskPattern'
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxComponent } from 'src/app/_dialogs/message-box/message-box.component';
import { InputsService } from 'src/app/_services/inputs.service';
/* tslint:disable */

@Component({
	selector: 'app-solicitud-a-p1',
	templateUrl: './solicitudAvalp1.component.html',
	styleUrls: ['./../../solicitudAval.component.scss']
})

export class SolicitudAvalp1Component implements OnInit {

	@Input() tipoPersona: number;
	@Input() aval: any
	@Input() tipoAval: number
	@Output() parte = new EventEmitter<number>();

	email: String = null;
	DynamoCliente: any ={};

	customPattern = customPatterns
	dgFForm: FormGroup;
    loading = false;
	submitted = false;
	minDate:any;
	returnUrl: string;
	datosAval:any = {}
	folio = null

	avalDynamoCliente = {
		apellido_materno: null,
		apellido_paterno: null,
		nombreCompleto: null,
		ocupacion: null,
		telefono: null,
		rfc: null,
		fecha_nacimiento:null
	  };

	direccion = {
		direccion: '',
		direccionGeografica: '',
		calle: '',
		numExterior: '',
		numInterior: '',
		colonia: '',
		ciudad: '',
		municipio: '',
		estado: '',
		codigoPostal: '',
	}

	direccionFiscal = {
		direccion: '',
		direccionGeografica: '',
		calle: '',
		numExterior: '',
		numInterior: '',
		colonia: '',
		ciudad: '',
		municipio: '',
		estado: '',
		codigoPostal: '',
	}

	coloniasDisponibles = []

	country = ''
	datosGeneralesPF = []
	inputsdisponibles:any;
	maskTelCel = ''

    constructor(
        private formBuilder: FormBuilder,
        // private route: ActivatedRoute,
        private router: Router,
        // private authenticationService: AuthenticationService,
        private alertService: AlertService,
		private solicitudService: SolicitudService,
		private userService:UserService,
		private dialog:MatDialog,
		private buroService: BuroService,
		private inputService: InputsService,
    ) {
        // redirect to home if already logged in

	}
    async ngOnInit() {
		const client_details = JSON.parse(localStorage.getItem('client_details'));
		this.email = JSON.parse(localStorage.getItem('client_details')).email;

		this.folio = client_details.folio

		//this.getClienteDynamoDB();
		console.log("Aval pt1: ", this.tipoAval);
		this.minDate = new Date();
		console.log(' :', );
		this.minDate.setFullYear( this.minDate.getFullYear() - 18 );

        this.dgFForm = this.formBuilder.group({
			aval_omitir: [false],
            aval_email: ['', [Validators.required, Validators.pattern( emailPattern )]],
            aval_nombre_rs: ['', [Validators.required]],
            aval_apellido_paterno: ['', ],
            aval_apellido_materno: ['', ],
			aval_fecha_nac_constitucion: ['',[Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
			aval_rfc: ['', ],
			aval_domicilio: [''],
			avalF_estado_civil: ["SOLTERO"],
			avalF_regimen: [''],
			avalF_nombre_conyugue: [''],
			avalF_domicilio: ['PROPIO'],
			avalF_valor: [''],
			aval_parentesco: [''],
			aval_tiempo_conocerlo: [''],
			aval_telefono: [''],
			aval_ocupacion: [''],
			aval_genero: ['Hombre'],
			aval_calle: ['', Validators.required],
			aval_numExterior: ['', Validators.required],
			aval_numInterior: ['', Validators.required],
			aval_colonia: ['', ],
			aval_ciudad: ['',],
			aval_municipio: ['', ],
			aval_estado: ['', ],
			aval_codigoPostal: ['', ],
			aval_dni_ruc: [''],
			aval_urbanizacion: [''],
			aval_distrito: [''],
			aval_provincia: [''],
			aval_departamento: [''],
			// aval_calle_fiscal: ['', Validators.required],
			// aval_numExterior_fiscal: ['', Validators.required],
			// aval_numInterior_fiscal: ['', Validators.required],
			// aval_colonia_fiscal: ['', Validators.required],
			// aval_ciudad_fiscal: ['', Validators.required],
			// aval_municipio_fiscal: ['', Validators.required],
			// aval_estado_fiscal: ['', Validators.required],
			// aval_codigoPostal_fiscal: ['', Validators.required],
		});
		const solicitudDynamo = await this.solicitudService.ObtenerSolicitud(this.folio);
		this.f.aval_omitir.setValue(solicitudDynamo.aval?.omitir) 

		this.avalDynamoCliente= solicitudDynamo?.aval;

		const solicitud = this.solicitudService.getReferencia()
		const tipo_persona = this.tipoPersona ?? 1
		const parte = 1

		const guardado = { ...solicitud, tipo_persona, parte}
		this.solicitudService.geteAval( {guardado} ).subscribe( res => {
			console.log("GET: ", res);
			this.setData( res.body )
		})
		this.inputsdisponibles = await this.inputService.ObtenerInputsByTramite({folio: this.folio})
		this.country = this.inputsdisponibles.data.country
		this.setMaskCel()

    }
	isCampoDisponible(id: number, section: string): boolean {
		return this.inputsdisponibles?.data?.[section]?.includes(id);
	}

    // convenience getter for easy access to form fields
	get f() { return this.dgFForm.controls; }	
	combineAddress() {
		const calle = this.f.aval_calle.value;
		const numExterior = this.f.aval_numExterior.value;
		const numInterior = this.f.aval_numInterior.value ? ` Int. ${this.f.aval_numInterior.value}` : '';
		const colonia = this.f.aval_colonia.value;
		const ciudad = this.f.aval_ciudad.value;
		const municipio = this.f.aval_municipio.value;
		const estado = this.f.aval_estado.value;
		const codigoPostal = this.f.aval_codigoPostal.value;
	
		return `${calle} ${numExterior} ${numInterior}, ${colonia}, ${ciudad}, ${municipio}, ${estado}, C.P. ${codigoPostal}`;
	}
	setMaskCel(): void {
		this.maskTelCel = this.country === 'MX' ? '00 0000 0000' : '000 000 000';
	}

    async onSubmit(button: number) {
		//this.parte.emit(2);
		this.loading = true;
		var data: any;
		this.submitted = true;

		// if( this.datosAval.aval_nombre_rs && button === 2) return this.parte.emit(2)
		// stop here if form is invalid
		
		if (this.dgFForm.invalid && !this.f.aval_omitir.value) {
			this.loading = false;
			const aval = {
				omitir: this.f.aval_omitir.value,
			}
			this.mostrarErroresCampos();


			//await this.solicitudService.GuardarSolicitud(this.folio, {aval})
			return;
		}
		this.f.avalF_valor.setValue( Number(this.f.avalF_valor.value) )
		this.f.aval_domicilio.setValue(this.combineAddress());

		data = this.dgFForm.value;

		this.loading = true;
		console.log('before calling api');
		(await this.solicitudService.saveAval(data, 1, this.tipoPersona, 1)).subscribe(
			async (res: any) => {
				console.log(res);
				// if (data.status <= 300) {
					const client_details = JSON.parse(localStorage.getItem('client_details'))
					client_details.tipo_aval = this.tipoAval
					localStorage.setItem('client_details', JSON.stringify(client_details))
					
					this.loading = false;
					// const dialogref = this.dialog.open(MessageBoxComponent, {
					// 	width: "500px",
					// 	data: {
					// 		text: "Éxito",
					// 		text3: "tu información se ha guardado",
					// 		icon: "success",
					// 		type: "success"
					// 	}
					// })
					// dialogref.afterClosed().subscribe( _=> {
					// 	this.userService.goToDashboard()
					// })
					//this.alertService.success()
					if ( button === 1 ) { // go to dashboard
						// this.alertService.success('Se guardó correctamente.')
						
						const dialogref = this.dialog.open(MessageBoxComponent, {
							width: "500px",
							data: {
								text: "Éxito",
								text3: "tu información se ha guardado",
								icon: "success",
								type: "success"
							}
						})
						dialogref.afterClosed().subscribe( _=> {
							this.userService.goToDashboard()
						})
					} else { // next form
						// this.parte.emit(2);
						this.userService.goToDashboard()
					}
				// }
				// this.router.navigateByUrl('/login', { replaceUrl: true } );
			},
			(err: any) => {
				this.loading = false;
				// this.alertService.error(err.error.message);
				console.log('Error 2 :', err)
				this.dialog.open(MessageBoxComponent, {
					width: "500px",
					data: {
						text: "Error",
						text3: "tu información no se ha guardado",
						icon: "error",
						type: "error"
					}
				})
				//let er = JSON.parse(err);
			}
		);				

		const aval = {
			omitir: this.f.aval_omitir.value,
			tipoPersona: this.tipoAval == 1 ? "Persona física" : "Persona moral",
			nombreCompleto: this.f.aval_nombre_rs.value,
			apellidoPaterno: this.f.aval_apellido_paterno.value,
			apellidoMaterno: this.f.aval_apellido_materno.value,
			nacionalidad: "1595863462706x437395425278820350",
			fechaNacimiento: this.f.aval_fecha_nac_constitucion.value,
			genero: this.f.aval_genero.value,
			rfc: this.f.aval_rfc.value,
			email: this.f.aval_email.value,
			dni_ruc: this.f.aval_dni_ruc,
			telefono: this.f.aval_telefono.value,
			direccion: {
				direccionCompleta: `${this.f.aval_calle.value} ${this.f.aval_numExterior.value} ${this.f.aval_numInterior.value}, ${this.f.aval_colonia.value}, ${this.f.aval_municipio.value}, ${this.f.aval_estado.value}, C.P.: ${this.f.aval_codigoPostal.value}`.trim(),
				estado: this.f.aval_estado.value,
				municipio: this.f.aval_municipio.value,
				ciudad: this.f.aval_ciudad.value,
				colonia: this.f.aval_colonia.value,
				calle: this.f.aval_calle.value,
				numeroExterior: this.f.aval_numExterior.value,
				numeroInterior: this.f.aval_numInterior.value,
				cp: this.f.aval_codigoPostal.value,
				urbanizacion: this.f.aval_urbanizacion.value,
				distrito: this.f.aval_distrito.value,
				provincia: this.f.aval_provincia.value,
				departamento: this.f.aval_departamento.value,
			},
			direccionFiscal: {
				direccionCompleta: `${this.f.aval_calle.value} ${this.f.aval_numExterior.value} ${this.f.aval_numInterior.value}, ${this.f.aval_colonia.value}, ${this.f.aval_municipio.value}, ${this.f.aval_estado.value}, C.P.: ${this.f.aval_codigoPostal.value}`.trim(),
				estado: this.f.aval_estado.value,
				municipio: this.f.aval_municipio.value,
				ciudad: this.f.aval_ciudad.value,
				colonia: this.f.aval_colonia.value,
				calle: this.f.aval_calle.value,
				numeroExterior: this.f.aval_numExterior.value,
				numeroInterior: this.f.aval_numInterior.value,
				cp: this.f.aval_codigoPostal.value,
				urbanizacion: this.f.aval_urbanizacion.value,
				distrito: this.f.aval_distrito.value,
				provincia: this.f.aval_provincia.value,
				departamento: this.f.aval_departamento.value,
			}
		}

		await this.solicitudService.GuardarSolicitud(this.folio, {aval})
	}
	mostrarErroresCampos() {
		const camposInvalidos = [];
	  
		Object.keys(this.dgFForm.controls).forEach((campo) => {
		  const control = this.dgFForm.get(campo);
		  if (control?.invalid) {
			camposInvalidos.push({
			  campo,
			  errores: control.errors
			});
		  }
		});
	  
		console.log('Campos inválidos:', camposInvalidos);
	  }

	setData( res ){
		console.log("AvalDynamoCliente:", this.avalDynamoCliente);

		console.log("CHECK AVAL :",this.f.aval_omitir.value)

		if(!this.f.aval_omitir.value){

			this.BuscarDireccion()
			this.f.aval_nombre_rs.setValue( res.aval_nombre_rs ||this.avalDynamoCliente?.nombreCompleto)
			this.f.aval_fecha_nac_constitucion.setValue(this.avalDynamoCliente?.fecha_nacimiento ?? res.aval_fecha_nac_constitucion )
			this.f.aval_rfc.setValue( this.avalDynamoCliente?.rfc ?? res.aval_rfc ?? "")
			this.f.aval_domicilio.setValue( res.aval_domicilio )
			this.f.avalF_estado_civil.setValue( res.avalF_estado_civil ? res.avalF_estado_civil : 'SOLTERO' )
			this.f.avalF_regimen.setValue( res.avalF_regimen )
			this.f.avalF_nombre_conyugue.setValue( res.avalF_nombre_conyugue )
			this.f.avalF_domicilio.setValue( res.avalF_domicilio )
			this.f.avalF_valor.setValue( res.avalF_valor )
			this.f.aval_parentesco.setValue(res.aval_parentesco || '')
			this.f.aval_tiempo_conocerlo.setValue(res.aval_tiempo_conocerlo || '')
			this.f.aval_telefono.setValue(this.avalDynamoCliente?.telefono ?? res.aval_telefono ?? '')
			this.f.aval_ocupacion.setValue(this.avalDynamoCliente?.ocupacion ?? res.aval_ocupacion ?? '')
			this.f.aval_email.setValue(this.aval ? this.aval.email : res.aval_email || '')
			this.f.aval_apellido_paterno.setValue(this.aval?.apellidoPaterno || res?.aval_apellido_paterno || this.avalDynamoCliente?.apellido_paterno || '')
			this.f.aval_apellido_materno.setValue(this.aval?.apellidoMaterno || res?.aval_apellido_materno || this.avalDynamoCliente?.apellido_materno || '')

			this.f.aval_calle.setValue(this.aval && this.aval.direccion ? this.aval.direccion.calle : '')
			this.f.aval_numExterior.setValue( this.aval && this.aval.direccion ? this.aval.direccion.numeroExterior : '')
			this.f.aval_numInterior.setValue( this.aval && this.aval.direccion ? this.aval.direccion.numeroInterior : '')
			this.f.aval_colonia.setValue( this.aval && this.aval.direccion ? this.aval.direccion.colonia : '')
			this.f.aval_ciudad.setValue( this.aval && this.aval.direccion ? this.aval.direccion.ciudad : '')
			this.f.aval_municipio.setValue( this.aval && this.aval.direccion ? this.aval.direccion.municipio : '')
			this.f.aval_estado.setValue( this.aval && this.aval.direccion ? this.aval.direccion.estado : '')
			this.f.aval_codigoPostal.setValue( this.aval && this.aval.direccion ? this.aval.direccion.cp : '')

			this.f.aval_urbanizacion.setValue( this.aval && this.aval.direccion ? this.aval.direccion.urbanizacion : '')
			this.f.aval_distrito.setValue( this.aval && this.aval.direccion ? this.aval.direccion.distrito : '')
			this.f.aval_provincia.setValue( this.aval && this.aval.direccion ? this.aval.direccion.provincia : '')
			this.f.aval_departamento.setValue( this.aval && this.aval.direccion ? this.aval.direccion.departamento : '')
			this.f.aval_dni_ruc.setValue( this.aval && this.aval.dni_ruc ? this.aval.dni_ruc : '')
		}
		
	}

	async BuscarDireccion() {
		const cp = this.f.aval_codigoPostal.value
		console.log(this.f.aval_codigoPostal.value);

		if (cp.length == 5) {
			console.log(`Buscando info: ${cp}`);
			const res: any = await this.buroService.buscarEstado(cp)
			this.guardarColonias(res)
			console.log(res);
			if (res[0].error == false) {
				const { asentamiento, municipio, estado, ciudad } = res[0].response

				console.log({municipio, estado, ciudad});
				this.f.aval_ciudad.setValue(ciudad)
				this.f.aval_municipio.setValue(municipio)
				this.f.aval_estado.setValue(estado)

				console.log(this.buroService.obtenerCodigoEstado(estado))
			}
			
		}

	}

	guardarColonias(direcciones) {
		this.coloniasDisponibles = []
		direcciones.forEach(element => {
			const { response: {asentamiento} } = element
			this.coloniasDisponibles.push( asentamiento )
			
		});
	}

	setColonia(colonia) {
		this.f.aval_colonia.setValue( colonia )
	}

	changePart(part: number){
		console.log(part);
		this.parte.emit(part);
	}
}
