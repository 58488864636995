<div class="main">
    <h2>Detalle de cartera</h2>
    <div *ngIf="!data.resumen" class="info">
        <span>Cliente: </span><span>{{cliente}}</span>
    </div>
    <div *ngIf="!data.resumen" class="info">
        <span>Anexo: </span><span>{{anexo}}</span>
    </div>


    <div *ngIf="data.resumen" class="info">
        <span>Periodo: </span><span>{{data.periodo | date:'MMM yy'}}</span>
    </div>
    <div *ngIf="data.resumen" class="info">
        <span>Saldo inicial: </span><span>{{data.monto | currency:'MXN'}}</span>
    </div>
    <div *ngIf="data.resumen" class="info">
        <span>Capital: </span><span>{{data.capital | currency:'MXN'}}</span>
    </div>
    <div *ngIf="data.resumen" class="info">
        <span>Interés: </span><span>{{data.interes | currency:'MXN'}}</span>
    </div>
    <div *ngIf="data.resumen" class="info">
        <span>Residual: </span><span>{{data.residual | currency:'MXN'}}</span>
    </div>
    <div *ngIf="data.resumen" class="info">
        <span>Insoluto: </span><span>{{data.insoluto | currency:'MXN'}}</span>
    </div>
    
    <div *ngIf="data.resumen" class="table-rentas">
        <table class="table-rentas" *ngIf="data.anexo.length">
            <thead>
                <tr>
                    <th style="min-width: 180px;">Anexo</th>
                    <th>Renta</th>
                    <th>Saldo inicial</th>
                    <th>Pago</th>
                    <th>Capital</th>
                    <th>Intereses</th>
                    <th>Insoluto</th>
                    <th>Residual</th>
                    <th>Seguro</th>
                    <th>Mensualidad total</th>
                    <th>Monto pago</th>
                    <th>Pagado</th>
                    <th>Fecha pago</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let renta of data.anexo">
                    <td>{{renta.folio}}</td>
                    <td>{{renta.renta}}</td>
                    <td>{{renta.monto | currency:'MXN'}}</td>
                    <td>{{renta.pago | currency:'MXN'}}</td>
                    <td>{{renta.capital | currency:'MXN'}}</td>
                    <td>{{renta.interes | currency:'MXN'}}</td>
                    <td>{{renta.insoluto | currency:'MXN'}}</td>
                    <td>{{renta.residual | currency:'MXN'}}</td>
                    <td>{{renta.seguroSinIva | currency:'MXN'}}</td>
                    <td>{{renta.seguroSinIva + renta.pago | currency:'MXN'}}</td>
                    <td>{{renta.montoPago | currency:'MXN'}}</td>
                    <td>{{(renta.seguroSinIva + renta.pago) - renta.montoPago < 1 ? 'Sí' : 'No'}}</td>
                    <td>{{renta.fechaPago | date:'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="!data.resumen" class="table-rentas">
        <table class="table-rentas" *ngIf="rentas.length">
            <thead>
                <tr>
                    <th>Renta</th>
                    <th>Fecha</th>
                    <th>Saldo inicial</th>
                    <th>Renta</th>
                    <th>Capital</th>
                    <th>Intereses</th>
                    <th>Insoluto</th>
                    <th>Residual</th>
                    <th>Seguro</th>
                    <th>Renta total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let renta of rentas">
                    <td>{{renta.renta}}</td>
                    <td>{{renta.fecha | date:'dd/MM/yyyy'}}</td>
                    <td>{{renta.monto | currency:'MXN'}}</td>
                    <td>{{renta.pago | currency:'MXN'}}</td>
                    <td>{{renta.capital | currency:'MXN'}}</td>
                    <td>{{renta.interes | currency:'MXN'}}</td>
                    <td>{{renta.insoluto | currency:'MXN'}}</td>
                    <td>{{renta.residual | currency:'MXN'}}</td>
                    <td>{{renta.seguroSinIva | currency:'MXN'}}</td>
                    <td>{{renta.pago + renta.seguroSinIva | currency:'MXN'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>