
<div class="progress">
	<div class="progress-left active" (click)="changePart(1)"></div>
	<div class="progress-mid active" (click)="changePart(2)"></div>
	<div class="progress-right" (click)="changePart(3)"></div>
</div>

<form *ngIf="tipoPersona == 1" [formGroup]="dgFForm" >
	<div class="datos_generales_1" *ngIf="estado_civil == 'CASADO'">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">1</span>
			<p><b>Datos del conyuge<br>(Llenar solo si aplica)</b></p>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Primer nombre</mat-label>
			<input  matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="r_nombres" 
				[value]="datosGen.r_nombres"
				[ngClass]="{ 'is-invalid': submitted && f.r_nombres.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.r_nombres.errors" class="invalid-feedback">
				<div *ngIf="f.r_nombres.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Segundo nombre</mat-label>
			<input matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="r_segundo_nombre" 
				[value]="datosGen.r_segundo_nombre"
				[ngClass]="{ 'is-invalid': submitted && f.r_segundo_nombre.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.r_segundo_nombre.errors" class="invalid-feedback">
				<div *ngIf="f.r_segundo_nombre.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Apellido paterno</mat-label>
			<input matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="r_apellido_paterno" 
				[value]="datosGen.r_apellido_paterno"
				[ngClass]="{ 'is-invalid': submitted && f.r_apellido_paterno.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.r_apellido_paterno.errors" class="invalid-feedback">
				<div *ngIf="f.r_apellido_paterno.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
		<div>
			<mat-form-field appearance="outline">
			<mat-label>Apellido materno</mat-label>
			<input  matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="r_apellido_materno" 
				[value]="datosGen.r_apellido_materno"
				[ngClass]="{ 'is-invalid': submitted && f.r_apellido_materno.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.r_apellido_materno.errors" class="invalid-feedback">
				<div *ngIf="f.r_apellido_materno.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div class="input size-2" *ngIf="false">
			<mat-label>Ocupación</mat-label>
			<input  
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="r_ocupacion" 
				[value]="datosGen.r_ocupacion"
				[ngClass]="{ 'is-invalid': submitted && f.r_ocupacion.errors }"
			>
			<mat-error *ngIf="submitted && f.r_ocupacion.errors" class="invalid-feedback">
				<div *ngIf="f.r_ocupacion.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Ingresos mensuales</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "
				formControlName="ingreso_mensual" 
				[value]="datosGen.ingreso_mensual"
				[ngClass]="{ 'is-invalid': submitted && f.ingreso_mensual.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.ingreso_mensual.errors" class="invalid-feedback">
				<div *ngIf="f.ingreso_mensual.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>

	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">{{ estado_civil === 'SOLTERO' ? 1 : 2}}</span>
			<p><b>Datos del domicilio<br>del solicitante</b></p>
		</div>

		<div class="input size-2" *ngIf="isCampoDisponible(10, 'campos_datosgenerales_pf')">
			<mat-label>Tipo de Vivienda</mat-label>
			<mat-radio-group 
				formControlName="tipo_vivienda" 
				[value]="datosGen.tipo_vivienda"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_vivienda.errors }">
				<mat-radio-button style="margin-right: 0!important;" labelPosition="before" value="PROPIA">Propia</mat-radio-button>
				<!-- <mat-radio-button style="margin-right: 0!important;" labelPosition="before" value="HIPOTECADA">Hipotecada</mat-radio-button> -->
				<mat-radio-button style="margin-right: 0!important;" labelPosition="before" value="RENTADA">Rentada</mat-radio-button>
				<!-- <mat-radio-button style="margin-right: 0!important;" labelPosition="before" value="FAMILIAR">Familiar</mat-radio-button> -->
			</mat-radio-group>
			<!-- <input  
				formControlName="tipo_vivienda" 
				[value]="datosGen.tipo_vivienda"
				[ngClass]="{ 'is-invalid': submitted && f.tipo_vivienda.errors }"
			> -->
			<mat-error *ngIf="submitted && f.tipo_vivienda.errors" class="invalid-feedback">
				<div *ngIf="f.tipo_vivienda.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div>
			<mat-form-field appearance="outline">
			<mat-label>Valor aproximado</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "
				formControlName="valor_aprox" 
				[value]="datosGen.valor_aprox"
				[ngClass]="{ 'is-invalid': submitted && f.valor_aprox.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.valor_aprox.errors" class="invalid-feedback">
				<div *ngIf="f.valor_aprox.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<div *ngIf="f.tipo_vivienda.value == 'RENTADA' && isCampoDisponible(137, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Pago Mensual</mat-label>
			<input matInput
				mask="separator.2"
				thousandSeparator=","
				prefix="$ "
				[readonly]="f.tipo_vivienda.value == 'PROPIA' || f.tipo_vivienda.value == 'FAMILIAR'"
				placeholder="Solo para Renta o Hipotecada"
				formControlName="pago_mensual" 
				[value]="datosGen.pago_mensual"
				[ngClass]="{ 'is-invalid': submitted && f.pago_mensual.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.pago_mensual.errors" class="invalid-feedback">
				<div *ngIf="f.pago_mensual.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<!-- <div>
			<mat-form-field appearance="outline">
			<mat-label>Tiempo de Residencia</mat-label>
			<input  matInput
			[specialCharacters]="['space']"
        	[patterns]="customPattern"
			mask="E{30}"
				formControlName="tiempo_residencia" 
				[value]="datosGen.tiempo_residencia"
				[ngClass]="{ 'is-invalid': submitted && f.tiempo_residencia.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && f.tiempo_residencia.errors" class="invalid-feedback">
				<div *ngIf="f.tiempo_residencia.errors.required">Campo obligatorio</div>
			</mat-error>
		</div> -->

		<!-- <div class="input">
			<mat-label>Domicilio</mat-label>
			<textarea  
				style="height: 40px; resize: none;"
				formControlName="domicilio" 
				[value]="datosGen.domicilio"
				[ngClass]="{ 'is-invalid': submitted && f.domicilio.errors }"
			></textarea>
			<mat-error *ngIf="submitted && f.domicilio.errors" class="invalid-feedback">
				<div *ngIf="f.domicilio.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>  -->
	</div>
	

	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<p><b>Domicilio<br>del solicitante</b></p>
		</div>
		<mat-error *ngIf="submitted && direccionInvalida">Domicilio obligatorio</mat-error>
		<div *ngIf="isCampoDisponible(11, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Calle</mat-label>
			<input required matInput name="calle" (input)="setDireccion('direccion', $event.target.name, $event.target.value)"
			>
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(12, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Número exterior</mat-label>
			<input required matInput name="numExterior" maxlength="10" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(13, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Número interior</mat-label>
			<input matInput name="numInterior" maxlength="10" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(19, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Urbanizacion</mat-label>
			<input required matInput name="urbanizacion" (input)="setDireccion('direccionPeru', $event.target.name, $event.target.value)"
			>
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(20, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Distrito</mat-label>
			<input required matInput name="distrito" (input)="setDireccion('direccionPeru', $event.target.name, $event.target.value)"
			>
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(21, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Provincia</mat-label>
			<input required matInput name="provincia" (input)="setDireccion('direccionPeru', $event.target.name, $event.target.value)"
			>
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(22, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Departamento</mat-label>
			<input  matInput name="departamento" (input)="setDireccion('direccionPeru', $event.target.name, $event.target.value)"
			>
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(14, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Código Postal</mat-label>
			<input required matInput name="codigoPostal" maxlength="5" (input)="setDireccion('direccion', $event.target.name, $event.target.value); BuscarDireccion($event.target.value, 'Direccion')">
			</mat-form-field>
		</div>
			<div *ngIf="isCampoDisponible(15, 'campos_datosgenerales_pf')">
				<mat-label *ngIf="coloniasDisponibles.length">Colonia</mat-label>
				<app-select-colonias *ngIf="coloniasDisponibles.length" [colonia]="direccion.colonia" [colonias]="coloniasDisponibles" (onSelected)="setDireccion('direccion', 'colonia', $event)"></app-select-colonias>
				<mat-form-field appearance="outline" *ngIf="!coloniasDisponibles.length">
					<mat-label>Colonia</mat-label>
					<input required matInput name="colonia" id="coloniaDireccion" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(16, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Municipio</mat-label>
				<input required matInput name="municipio" id="municipioDireccion" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(17, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Ciudad</mat-label>
				<input required matInput name="ciudad" id="ciudadDireccion" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(18, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Estado</mat-label>
				<input required matInput name="estado" id="estadoDireccion" (input)="setDireccion('direccion', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
		
	</div>

	<div class="datos_generales_1" *ngIf="country == 'MX'">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<p><b>Domicilio fiscal<br>del solicitante</b></p>
			</div>
			
		<mat-slide-toggle labelPosition="before" (change)="CambiarDireccion($event.checked)">Igual a la del domicilio </mat-slide-toggle>
		<mat-error *ngIf="submitted && direccionFiscalInvalida">Domicilio fiscal obligatorio</mat-error>
		<div style="height: 15px;"></div>
		<div *ngIf="showDireccionFiscal">
		<div *ngIf="isCampoDisponible(27, 'campos_datosgenerales_pf')" >
			<mat-form-field appearance="outline">
			<mat-label>Calle</mat-label>
			<input matInput name="calle" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(28, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Número exterior</mat-label>
			<input matInput name="numExterior" maxlength="10" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(29, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Número interior</mat-label>
			<input matInput name="numInterior" maxlength="200" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
			</mat-form-field>
		</div>
		<div *ngIf="isCampoDisponible(30, 'campos_datosgenerales_pf')">
			<mat-form-field appearance="outline">
			<mat-label>Código Postal</mat-label>
			<input matInput name="codigoPostal" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value); BuscarDireccion($event.target.value, 'DireccionFiscal')">
			</mat-form-field>
		</div>
		
			<div *ngIf="isCampoDisponible(31, 'campos_datosgenerales_pf')">
				<mat-label *ngIf="coloniasDisponiblesFiscal.length">Colonia</mat-label>
				<app-select-colonias *ngIf="coloniasDisponiblesFiscal.length" [colonia]="direccionFiscal.colonia" [colonias]="coloniasDisponiblesFiscal" (onSelected)="setDireccion('direccionFiscal', 'colonia', $event)"></app-select-colonias>
				<mat-form-field appearance="outline" *ngIf="!coloniasDisponiblesFiscal.length">
					<mat-label>Colonia</mat-label>
					<input matInput name="colonia" id="coloniaDireccionFiscal" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(32, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Municipio</mat-label>
				<input matInput name="municipio" id="municipioDireccionFiscal" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(33, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Ciudad</mat-label>
				<input matInput name="ciudad" id="ciudadDireccionFiscal" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
			<div *ngIf="isCampoDisponible(34, 'campos_datosgenerales_pf')">
				<mat-form-field appearance="outline">
				<mat-label>Estado</mat-label>
				<input matInput name="estado" id="estadoDireccionFiscal" (input)="setDireccion('direccionFiscal', $event.target.name, $event.target.value)">
				</mat-form-field>
			</div>
		</div>
	</div>

</form>

<!-- 
	------------------------------------------------------------------------------------------------------- 
	--------------------------------------- PERSONA MORAL -------------------------------------------------
	-------------------------------------------------------------------------------------------------------
-->

<form *ngIf="tipoPersona == 2" [formGroup]="dgMForm" class="con_direccion">
	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">1</span>
			<p><b>Representante legal</b></p>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="rl_nombres" 
				[value]="datosGen.rl_nombres"
				[ngClass]="{ 'is-invalid': submitted && m.rl_nombres.errors }"
			>
			</mat-form-field>
			<mat-error *ngIf="submitted && m.rl_nombres.errors" class="invalid-feedback">
				<div *ngIf="m.rl_nombres.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Apellidos</mat-label>
			<input matInput
				[patterns]="customPattern"
				mask="S{100}"
				formControlName="rl_apellidos" 
				[value]="datosGen.rl_apellidos"
				[ngClass]="{ 'is-invalid': submitted && m.rl_apellidos.errors }"
			></mat-form-field>

			<mat-error *ngIf="submitted && m.rl_apellidos.errors" class="invalid-feedback">
				<div *ngIf="m.rl_apellidos.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>RFC</mat-label>
			<input matInput
				mask="SSSS000000 AAA"
				formControlName="rl_rfc" 
				[value]="datosGen.rl_rfc"
				[ngClass]="{ 'is-invalid': submitted && !m.rl_rfc.valid }"
			></mat-form-field>

			<mat-error *ngIf="submitted && !m.rl_rfc.valid" class="invalid-feedback">
				<div *ngIf="!m.rl_rfc.valid">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>


	<div class="datos_generales_1">
		<div style="display: flex; justify-content: flex-start; align-items: center;">
			<span class="seccion">2</span>
			<p><b>Contacto de la empresa</b></p>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Nombres</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="ce_nombres" 
				[value]="datosGen.ce_nombres"
				[ngClass]="{ 'is-invalid': submitted && m.ce_nombres.errors }"
			>
		</mat-form-field>
			<mat-error *ngIf="submitted && m.ce_nombres.errors" class="invalid-feedback">
				<div *ngIf="m.ce_nombres.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Apellidos</mat-label>
			<input  matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="ce_apellidos" 
				[value]="datosGen.ce_apellidos"
				[ngClass]="{ 'is-invalid': submitted && m.ce_apellidos.errors }"
			></mat-form-field>

			<mat-error *ngIf="submitted && m.ce_apellidos.errors" class="invalid-feedback">
				<div *ngIf="m.ce_apellidos.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Correo Electrónico</mat-label>
			<input matInput
				formControlName="ce_correo" 
				[value]="datosGen.ce_correo"
				[ngClass]="{ 'is-invalid': submitted && !m.ce_correo.valid }"
			></mat-form-field>

			<mat-error *ngIf="submitted && !m.ce_correo.valid" class="invalid-feedback">
				<div *ngIf="!m.ce_correo.valid">Campo obligatorio</div>
			</mat-error>
		</div>

		<div>
			<mat-form-field appearance="outline">
			<mat-label>Ocupación</mat-label>
			<input matInput
			[patterns]="customPattern"
			mask="S{100}"
				formControlName="ce_puesto" 
				[value]="datosGen.ce_puesto"
				[ngClass]="{ 'is-invalid': submitted && m.ce_puesto.errors }"
			></mat-form-field>

			<mat-error *ngIf="submitted && m.ce_puesto.errors" class="invalid-feedback">
				<div *ngIf="m.ce_puesto.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
	</div>


		<div style="width: 100%;">
			<hr style="margin: 20px auto 30px auto;">
			<mat-form-field appearance="outline">
			<mat-label>Dirección de la empresa</mat-label>
			<textarea  matInput
				formControlName="domicilio_empresa" 
				[value]="datosGen.domicilio_empresa"
				[ngClass]="{ 'is-invalid': submitted && m.domicilio_empresa.errors }"
			></textarea>
		</mat-form-field>

			<mat-error *ngIf="submitted && m.domicilio_empresa.errors" class="invalid-feedback">
				<div *ngIf="m.domicilio_empresa.errors.required">Campo obligatorio</div>
			</mat-error>
		</div>
</form>

<div class="row-end">
	<!-- <button mat-button (click)="back(1)" matStepperPrevious>
		<svg style="height: 20px; width:auto; transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.bca{fill:#3fa4d7;}</style></defs><path class="bca" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
		Regresar
	</button> -->
	<div></div>
	<mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
	<!-- <button *ngIf="!loading" class="save-button" mat-button (click)="onSubmit(1)" style="height: 60px!important; width: 60px!important;">
		<svg style="height: 30px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48.14" height="48.14" viewBox="0 0 48.14 48.14"><defs><style>.ab{fill:#fff;}</style></defs><path class="ab" d="M8.439,4A3.377,3.377,0,0,0,5,7.439V48.7A3.377,3.377,0,0,0,8.439,52.14H49.7A3.377,3.377,0,0,0,53.14,48.7V14.544a1.039,1.039,0,0,0-.345-.8l-9.4-9.4A1.039,1.039,0,0,0,42.6,4Zm5.731,2.292H41.678V20.047a2.3,2.3,0,0,1-2.292,2.292H16.462a2.3,2.3,0,0,1-2.292-2.292ZM32.508,9.731V18.9h4.585V9.731ZM16.462,30.362H41.678a2.3,2.3,0,0,1,2.292,2.292V49.847h-29.8V32.654A2.3,2.3,0,0,1,16.462,30.362ZM8.439,46.409h2.292V48.7H8.439Zm38.97,0H49.7V48.7H47.409Z" transform="translate(-5 -4)"/></svg>
	</button> -->
	<button *ngIf="!loading" mat-button (click)="onSubmit(2)" >
		Siguiente
		<svg style="height: 20px; width:auto;" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><defs><style>.abc{fill:#707070;}</style></defs><path class="abc" d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM22,38V18.7L13.448,27.25l-3.394-3.394L24,9.908,37.948,23.856,34.554,27.25,26,18.7V38Z" transform="translate(48) rotate(90)"/></svg>
	</button>
</div>
