import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService, UserService, AuthenticationService, SolicitudService } from '../../_services';
import { LIST_STATUS } from '../../_helpers/status';
import { MatDialog } from '@angular/material/dialog';
import { FinProcesoMessageComponent } from 'src/app/_dialogs/fin-proceso-message/fin-proceso-message.component';



@Component({
  selector: 'app-status-client',
  templateUrl: './status-client.component.html',
  styleUrls: ['./status-client.component.scss']
})
export class StatusClientComponent implements OnInit {
  @Input() public procesos;
  @Input() public show_messages;
  @Output() public hide_messages = new EventEmitter();

  STATUS = ['A realizar', 'En proceso', 'Actualizar', 'Completado'];
  CLASS_STATUS = LIST_STATUS;
  proceso_activo = null;
  loading = false;
  perfil = null;
  folio = 0

  editSolicitudAllowed = [1,2,3,7,10,25]
  cargaExpedienteAllowed = [1,2,3,4,7,10,15,14,17,8,25,26]
  editSolicitud = false
  cargaExpediente = false


  StatusComite:any = {
    entrevista: 0,
    analisis: 0,
    comentarios: 0,
    //caratula: 0,
    minuta: 0,
    cartas: 0,
    consultaBuro: 0,
  }
  StatusCliente = null
  ComiteStatus = false
  diasUltimaConsultaBuro = null;
  tipoPersona;

  client_details:any = null

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private solicitudService: SolicitudService,
    private dialog:MatDialog
  ) {
    // redirect to home if already logged in
    if (!this.authenticationService.currentUserValue) {
      this.router.navigate(['/login']);
    }
    this.client_details = JSON.parse(localStorage.getItem('client_details'))
    
  }

  async ngOnInit() {
    localStorage.removeItem('actualizar_informacion')
    localStorage.removeItem('comentarios')
    localStorage.removeItem('cartas')

    
    const status = await this.userService.getStatusProceso(this.client_details.id_cliente, this.client_details.folio )
    this.client_details.procesos = status.estauts.map( proc => ({...proc, status: proc.estatus}))
    this.client_details.tipo_persona = status.tipo_persona
    this.procesos = this.client_details.procesos

    localStorage.setItem("client_details", JSON.stringify(this.client_details))
    this.tipoPersona = this.client_details.tipo_persona;
    console.log("TIPO PERSONA", this.tipoPersona)

    let id_cliente = JSON.parse(localStorage.getItem('currentUser'));
    this.perfil = Number(id_cliente.perfil)
    if( id_cliente.perfil == 4 ) {
      
      this.procesos = this.client_details.procesos
      console.log("Procesos comite: ",this.procesos );
    }
    if ( this.procesos ) {
      this.procesos.sort( (a, b) => a.id_proceso - b.id_proceso );
      console.log(this.procesos)

      this.proceso_activo = this.procesos[0].status < 3 ? this.procesos[0]
      : this.procesos[0].status == 3 && this.procesos[1].status < 3 ? this.procesos[1]
      : this.procesos[1].status == 3 && this.procesos[2].status < 3 ? this.procesos[2]
      : this.procesos[2].status == 3 && this.procesos[3].status < 3 ? this.procesos[3]
      : this.procesos[3].status == 3 ? this.procesos[4] : null
    }
console.log("Proceso activo: ", this.proceso_activo, );
    
    this.userService.solicitud = this.proceso_activo.id_proceso == 1

    const { folio, tipo_persona } = JSON.parse(localStorage.getItem('client_details'))
    this.folio = folio
    this.perfil = id_cliente.perfil;

    this.userService.folio = this.folio

    this.editSolicitud = this.editSolicitudAllowed.includes( Number(this.perfil) )
    this.cargaExpediente = this.cargaExpedienteAllowed.includes( Number(this.perfil) )
    console.log(this.editSolicitud);
    
    localStorage.setItem("tipoPersona", tipo_persona)
    if( this.perfil == 4 && this.proceso_activo.id_proceso >= 4){
      this.userService.ObtenerStatusComite(folio)
      .subscribe( res => {
        console.log(res);
        // res.fecha_modificacion.String = '2020-07-20 15:43:36.862408';
        if (res.fecha_modificacion.String !== '') {
          const fechaModificacionAux = new Date(res.fecha_modificacion.String);
          const fechaActual = new Date();
          this.diasUltimaConsultaBuro = Math.round((fechaActual.getTime() - fechaModificacionAux.getTime())/ (1000*60*60*24));
          this.validarMostrarBuro();
        }
        this.StatusComite = {...this.StatusComite, ...res}
        this.ComiteStatus = true
        localStorage.setItem('id_solicitud', res.id_solicitud)
      })
    }
    if( this.editSolicitud ){
      // const { quatation: {Code: folio}, id: id_cliente} = JSON.parse(localStorage.getItem('currentUser'))
      const { folio, id_cliente, tipo_persona} = this.client_details
      // const tipo_persona = Number(localStorage.getItem('tipoPersona'))
      const data = { id_cliente, folio, proceso: 1, perfil: tipo_persona}
      console.log("Data: ", data);
      
      this.userService.ObtenerEstatusSolicitud(data)
        .subscribe(({status_datos:datos_generales, status_referencias:referencias, status_aval:aval}) => {
          console.log("Solicitud: ", datos_generales, referencias, aval);
          
          this.StatusCliente = {
            datos_generales, referencias, aval
          }
          localStorage.setItem("steps", JSON.stringify(this.StatusCliente))
          
        })
    }

    if( this.proceso_activo.id_proceso == 4 && this.proceso_activo.status == 3 && this.perfil == 1 ){
      this.dialog.open(FinProcesoMessageComponent, {
        width: "350px",
        height: "380px",
        data: {
          folio: this.folio,
          perfil: this.perfil
        }
      })
    }
  }

  validarMostrarBuro() {
    console.log("Validar", this.diasUltimaConsultaBuro);
    
    if (this.diasUltimaConsultaBuro > 90) {
      this.StatusComite.consultaBuro = 0;
    }else{
      this.StatusComite.consultaBuro = 1;
    }
  }

  AbrirMensajes() {
    console.log('Abriendo');
    this.show_messages = true
  }

  CerrarMensajes() {
    console.log('Cerrando');
    this.show_messages = false
    this.hide_messages.emit(true)
  }

  Navigate(r){
    if( !this.userService.alertService.isOnline() ) return
    this.router.navigate(r)
  }
}
