import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class VendorsService {

  constructor(private http: HttpClient, private router: Router) { }

  ObtenerVendors({id_usuario, perfil, country = ''}) {
    const queryParams = perfil == 3 ? `?id_usuario=${id_usuario}&perfil=${perfil}&country=${country}` : `?country=${country}`
    return this.http.get<any>(`${environment.config.apiUrl}vendor${queryParams}`);
  }

  ObtenerVendorPorId(id_vendor) {
    return this.http.post<any>(`${environment.config.apiUrl}vendor/id`, {id_vendor});
  }

  GuardarVendor(vendor) {
    return this.http.post<any>(`${environment.config.apiUrl}vendor`, vendor);
  }

  ObtenerVendedores(id_vendor) {
    return this.http.post<any>(`${environment.config.apiUrl}vendor/vendedores`, {id_vendor});
  }

	CrearVendedor( vendedor ) {
    return this.http.post<any>(`${environment.config.apiUrl}createUser`, vendedor);
  }

	ActualizarVendedor( formData ) {
    return this.http.post<any>(`${environment.config.apiUrl}vendor/vendedor`, formData);
  }

  ResetPassword( email ) {
    return this.http.post<any>(`${environment.config.apiUrl}reset_password`, {email});
  }
  ActivarVendor(user_id){ 
  return this.http.post<any>(`${environment.config.apiUrl}vendor/activate`, {user_id});
  }
  DesactivarVendor(user_id){
    return this.http.post<any>(`${environment.config.apiUrl}vendor/deactivate`,{user_id});
  }
  EliminarVendor(user_id){
    return this.http.post<any>(`${environment.config.apiUrl}vendor/delete`,{user_id});
  }
  //Servicio para Vendedores
  ActivarVendedor(id){ 
    return this.http.post<any>(`${environment.config.apiUrl}vendedor/activate`, {id});
    }
    DesactivarVendedor(id){
      return this.http.post<any>(`${environment.config.apiUrl}vendedor/desactivate`,{id});
    }
    EliminarVendedor(id){
      return this.http.post<any>(`${environment.config.apiUrl}vendedor/delete`,{id});
    }
}
