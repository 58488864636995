<form [formGroup]="frmRef">
  <div class="container-form" >
    <div class="container-title-ref">
      <div class="num-ref">Referencia </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <app-input-validation
        type="text"
        [formGroup]="frmRef"
        [controlName]="'rp_nombre1'"
        [errorMessage]=""
        label="Nombre completo"
        mask=""
      ></app-input-validation>
    </div>
    <div class="container-inputs">
      <div class="column">
        <app-input-validation
          type="text"
          [errorMessage]=""
          [formGroup]="frmRef"
          [controlName]="'rp_parentesco1'"
          label="Parentesco"
          mask=""
        ></app-input-validation>
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'rp_direccion1'"
          [errorMessage]=""
          label="Dirección"
          mask=""
        ></app-input-validation>
      </div>
      <div class="column" style="margin-left: 15px">
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'ocupacion1'"
          [errorMessage]=""
          label="Ocupación"
          mask=""
        ></app-input-validation>
        <app-input-validation
          type="text"
          [formGroup]="frmRef"
          [controlName]="'rp_telefono1'"
          [errorMessage]="getErrorMessage()"
          label="Teléfono"
          mask="00-0000-0000"
        ></app-input-validation>
      </div>
    </div>
  </div>
</form>
